<template>
  <div class="menu-filters">
    <div class="menu-header flex-space-between lll-hr-grey">
      <h1 class="h-section-title mb-3">
        {{ content.sectionTitle }}
      </h1>
      <button class="btn-link" @click="clearFilters">
        <v-icon>clear</v-icon>
        {{ content.clearButton }}
      </button>
    </div>

    <!-- <amb-input-label input-id="filter-publish-status" :label-text="content.publishStatus.label" />
    <v-select
      id="filter-publish-status"
      v-model="filterPublishStatus"
      item-text="label"
      item-value="id"
      :items="publishStatuses"
      chips
      deletable-chips
      solo
      flat
      hide-details
      hide-selected
      :placeholder="content.publishStatus.placeholder"
      menu-props="offset-y"
      class="lll-input"
      @input="filterResults"
    /> -->

    <v-expansion-panel class="lll-hr-grey">
      <v-expansion-panel-content>
        <v-icon slot="actions" color="primary" medium>
          $vuetify.icons.expand
        </v-icon>
        <div slot="header" class="menu-title flex-space-between">
          <div>{{ content.sectionHeadings.ambassadorDetails }}</div>
          <v-chip small color="primary" text-color="white">
            {{ filterCounts.ambassador_details }}
          </v-chip>
        </div>

        <amb-input-label
          input-id="filter-ambassador-type"
          :label-text="content.ambassadorType.label"
        />
        <v-autocomplete
          id="filter-ambassador-type"
          v-model="filterAmbassadorType"
          browser-autocomplete="off"
          :items="ambassadorTypes"
          item-text="label"
          item-value="id"
          return-object
          :no-data-text="noDataText.default"
          multiple
          chips
          deletable-chips
          solo
          flat
          hide-details
          hide-selected
          :placeholder="content.ambassadorType.placeholder"
          class="lll-input"
          @input="filterResults"
        />

        <amb-input-label
          input-id="filter-main-discipline"
          :label-text="content.mainDiscipline.label"
        />
        <v-autocomplete
          id="filter-main-discipline"
          v-model="filterMainDiscipline"
          browser-autocomplete="off"
          :items="disciplines"
          item-text="label"
          item-value="id"
          return-object
          :no-data-text="noDataText.default"
          multiple
          chips
          deletable-chips
          solo
          flat
          hide-details
          hide-selected
          :placeholder="content.mainDiscipline.placeholder"
          class="lll-input"
          @input="filterResults"
        />

        <amb-input-label
          input-id="filter-sub-discipline"
          :label-text="content.subDiscipline.label"
        />
        <v-autocomplete
          id="filter-sub-discipline"
          v-model="filterSubDiscipline"
          browser-autocomplete="off"
          :items="subDisciplinesFiltered"
          item-text="label"
          item-value="id"
          return-object
          :no-data-text="noDataText.default"
          multiple
          chips
          deletable-chips
          solo
          flat
          hide-details
          hide-selected
          :placeholder="content.subDiscipline.placeholder"
          class="lll-input"
          @input="filterResults"
        />

        <amb-input-label
          input-id="filter-ambassador-status"
          :label-text="content.ambassadorStatus.label"
        />
        <v-autocomplete
          id="filter-ambassador-status"
          v-model="filterAmbassadorStatus"
          browser-autocomplete="off"
          :items="ambassadorStatuses"
          item-text="label"
          item-value="id"
          return-object
          :no-data-text="noDataText.default"
          multiple
          chips
          deletable-chips
          solo
          flat
          hide-details
          hide-selected
          :placeholder="content.ambassadorStatus.placeholder"
          class="lll-input"
          @input="filterResults"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel class="lll-hr-grey">
      <v-expansion-panel-content>
        <v-icon slot="actions" color="primary" medium>
          $vuetify.icons.expand
        </v-icon>
        <div slot="header" class="menu-title flex-space-between">
          <div>{{ content.sectionHeadings.location }}</div>
          <v-chip small color="primary" text-color="white">
            {{ filterCounts.location }}
          </v-chip>
        </div>

        <amb-input-label input-id="filter-country" :label-text="content.country.label" />
        <v-autocomplete
          id="filter-country"
          v-model="filterCountry"
          browser-autocomplete="off"
          :items="countries"
          item-text="label"
          item-value="id"
          :no-data-text="noDataText.default"
          chips
          deletable-chips
          solo
          flat
          hide-details
          :placeholder="content.country.placeholder"
          menu-props="offset-y"
          class="lll-input"
          @input="filterResults"
        />

        <amb-input-label input-id="filter-state" :label-text="content.state.label" />
        <div class="hint-text pb-1">{{ content.state.hint }}</div>
        <v-text-field
          id="filter-state"
          v-model="filterState"
          browser-autocomplete="off"
          solo
          flat
          hide-details
          :placeholder="content.state.placeholder"
          class="lll-input"
          @change="filterResults"
        />

        <amb-input-label input-id="filter-city" :label-text="content.city.label" />
        <v-text-field
          id="filter-city"
          v-model="filterCity"
          browser-autocomplete="off"
          solo
          flat
          hide-details
          :placeholder="content.city.placeholder"
          class="lll-input"
          @change="filterResults"
        />

        <amb-input-label input-id="filter-store" :label-text="content.store.label" />
        <v-autocomplete
          id="filter-store"
          v-model="filterStore"
          browser-autocomplete="off"
          item-text="name"
          item-value="id"
          :items="storesFiltered"
          return-object
          :no-data-text="noDataText.default"
          multiple
          chips
          deletable-chips
          solo
          flat
          hide-details
          hide-selected
          :placeholder="content.store.placeholder"
          class="lll-input"
          @input="filterResults"
        />
      </v-expansion-panel-content>
    </v-expansion-panel>

    <v-expansion-panel class="lll-hr-grey">
      <v-expansion-panel-content>
        <v-icon slot="actions" color="primary" medium>
          $vuetify.icons.expand
        </v-icon>
        <div slot="header" class="menu-title flex-space-between">
          <div>{{ content.sectionHeadings.personalDetails }}</div>
          <v-chip small color="primary" text-color="white">
            {{ filterCounts.personal_details }}
          </v-chip>
        </div>

        <amb-input-label input-id="filter-age" :label-text="content.age.label" />
        <v-select
          id="filter-age"
          v-model="filterAge"
          item-text="label"
          item-value="value"
          :items="ageRanges"
          multiple
          chips
          deletable-chips
          solo
          flat
          hide-details
          hide-selected
          :placeholder="content.age.placeholder"
          menu-props="offset-y"
          class="lll-input"
          @input="filterResults"
        />

        <amb-input-label :label-text="content.gender.label" />
        <div class="grid-2-col-responsive grid-compact">
          <v-checkbox
            v-for="(gender, idx) in genders"
            :key="gender.id"
            v-model="filterGender"
            :label="genders[idx].label"
            :value="genders[idx].id"
            color="primary"
            hide-details
            class="lll-checkbox"
            @change="filterResults"
          />
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import content from '@/content/staticContentForUI';
import AMBInputLabel from '@/components/AMBInputLabel';

export default {
  name: 'AmbassadorsListFilters',

  components: {
    'amb-input-label': AMBInputLabel,
  },

  data() {
    return {
      content: content.ambassadorsListFilters,
      inputSelectionItems: content.inputSelectionItems,
      noDataText: content.noDataText,
      selectedMainDisciplines: [],
    };
  },

  computed: {
    ...mapGetters([
      'ambassadorCountryIdSet',
      'ambassadorStatuses',
      'ambassadorTypes',
      'disciplines',
      'filterCounts',
      'filters',
      'storesList',
      'subDisciplines',
      'userClaims',
    ]),

    ageRanges() {
      return this.inputSelectionItems.ageRanges;
    },

    countries() {
      // filter selection list, so it only includes countries related to ambassador records
      const filterFn = item => {
        return this.ambassadorCountryIdSet.has(item.id);
      };

      return this.inputSelectionItems.countries.filter(filterFn);
    },

    genders() {
      return this.inputSelectionItems.genders;
    },

    publishStatuses() {
      return this.inputSelectionItems.publishStatuses;
    },

    /**
     * User can select 1 or more Main Disciplines. If none selected, return full list of
     * Sub Disciplines. Otherwise filter Sub Disciplines, so only those releated to the
     * selected Main Discipline are shown in the drop-down menu.
     */
    subDisciplinesFiltered() {
      const { subDisciplines } = this;
      const selectedMainDisciplinesIdSet = this.getSelectedMainDisciplinesIdSet();

      if (selectedMainDisciplinesIdSet.size === 0) {
        return subDisciplines;
      }

      return subDisciplines.filter(item =>
        selectedMainDisciplinesIdSet.has(item.parent_discipline.id)
      );
    },

    storesFiltered() {
      const readClaims = this.userClaims.ambassador_read;

      if (readClaims === '*') {
        return this.storesList;
      }

      return this.storesList.filter(item => {
        if (readClaims.has(item.id)) {
          return true;
        }
        return false;
      });
    },

    filterPublishStatus: {
      get() {
        return this.filters.publish_status;
      },
      set(value) {
        this.$store.commit('updateFilter', { filterName: 'publish_status', filterValue: value });
      },
    },

    filterAmbassadorType: {
      get() {
        return this.filters.ambassador_type;
      },
      set(value) {
        this.$store.commit('updateFilter', { filterName: 'ambassador_type', filterValue: value });
      },
    },

    filterMainDiscipline: {
      get() {
        return this.filters.main_discipline;
      },
      set(value) {
        this.$store.commit('updateFilter', { filterName: 'main_discipline', filterValue: value });
        /**
         * Set this value so ID Set can be created for selected Main Discipline.
         * This allows selection items for sub-disciplines to be filtered by the
         * main discipline selected from 'filterMainDiscipline'.
         */
        this.selectedMainDisciplines = value;
      },
    },

    filterSubDiscipline: {
      get() {
        return this.filters.sub_discipline;
      },
      set(value) {
        this.$store.commit('updateFilter', { filterName: 'sub_discipline', filterValue: value });
      },
    },

    filterAmbassadorStatus: {
      get() {
        return this.filters.ambassador_status;
      },
      set(value) {
        this.$store.commit('updateFilter', { filterName: 'ambassador_status', filterValue: value });
      },
    },

    filterCountry: {
      get() {
        return this.filters.country;
      },
      set(value) {
        this.$store.commit('updateFilter', { filterName: 'country', filterValue: value });
      },
    },

    filterState: {
      get() {
        return this.filters.state;
      },
      set(value) {
        this.$store.commit('updateFilter', { filterName: 'state', filterValue: value });
      },
    },

    filterCity: {
      get() {
        return this.filters.city;
      },
      set(value) {
        this.$store.commit('updateFilter', { filterName: 'city', filterValue: value });
      },
    },

    filterStore: {
      get() {
        return this.filters.store;
      },
      set(value) {
        this.$store.commit('updateFilter', { filterName: 'store', filterValue: value });
      },
    },

    filterAge: {
      get() {
        return this.filters.age;
      },
      set(value) {
        this.$store.commit('updateFilter', { filterName: 'age', filterValue: value });
      },
    },

    filterGender: {
      get() {
        return this.filters.gender;
      },
      set(value) {
        this.$store.commit('updateFilter', { filterName: 'gender', filterValue: value });
      },
    },
  },

  methods: {
    getSelectedMainDisciplinesIdSet() {
      const selectedMainDisciplinesIdSet = new Set();

      this.selectedMainDisciplines.forEach(item => {
        selectedMainDisciplinesIdSet.add(item.id);
      });

      return selectedMainDisciplinesIdSet;
    },

    clearFilters() {
      this.$store.commit('resetFilters');
      this.$store.commit('resetFilterCounts');
      this.filterResults();
    },

    filterResults() {
      this.$emit('filterListing');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/forms-inputs.scss';

.menu-title {
  color: $lll-color-charcoal;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: $lll-font-weight-medium;
  letter-spacing: 0.05rem;
  align-items: baseline;
}

/* --------------------------------
 * OVERRIDES FOR VUETIFY COMPONENTS
 * -------------------------------- */

.menu-filters {
  ::v-deep .v-expansion-panel__header {
    padding: 0 10px 0 0;
  }
  ::v-deep .v-expansion-panel__body {
    padding-bottom: 20px;
  }
  .lll-input {
    margin-bottom: 15px;
    ::v-deep .v-chip {
      .v-chip__content {
        white-space: normal;
        height: unset;
        padding: 4px 4px 4px 12px;
      }
    }
  }
}

.theme--light {
  &.v-expansion-panel {
    .v-expansion-panel__container {
      background-color: $lll-color-background;
    }
  }
}

.v-expansion-panel {
  box-shadow: none;
  margin-bottom: 15px;
}
</style>
