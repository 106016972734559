import { convertFromJson } from 'fiql-query-builder';
import content from '@/content/staticContentForUI';
import { includesForQueryArgs } from '@/vuex/modules/ambassador';
import Ambassador from '@/models/v2/Ambassador';

const inputSelectionItems = content.inputSelectionItems;

const FILTERS_DEFAULT = {
  // 'search' can only filter by first_name, last_name, or UUID
  search: '',
  publish_status: '',
  ambassador_type: [],
  main_discipline: [],
  sub_discipline: [],
  ambassador_status: [],
  country: '',
  state: '',
  city: '',
  store: [],
  age: [],
  gender: [],
};

const FILTER_COUNTS_DEFAULT = {
  ambassador_details: 0,
  location: 0,
  personal_details: 0,
};

const PAGINATION_DEFAULT = {
  page_number: 1,
  page_size: 12,
};

const PAGINATION_BUTTONS_DEFAULT = {
  prev: false,
  first: false,
  last: false,
  next: false,
};

const initialState = () => {
  return {
    ambassadorsList: [],
    ambassadorsListCount: 0,
    currentFilterString: '',
    filters: { ...FILTERS_DEFAULT },
    filterCounts: { ...FILTER_COUNTS_DEFAULT },
    pagination: { ...PAGINATION_DEFAULT },
    paginationButtonStates: { ...PAGINATION_BUTTONS_DEFAULT },
  };
};

const actions = {
  async getAmbassadors({ commit, dispatch, state }) {
    const filterString = constructFiqlFilterString(state.filters, inputSelectionItems, commit);

    // save filter string in app state so it can be used by export function (AmbassadorsListExport)
    commit('currentFilterString', filterString);

    // define query args (pagination, filter, include) for GET request to service (JSON:API spec)
    const listingArgs = {
      page_number: state.pagination.page_number,
      page_size: state.pagination.page_size,
      'fields[ambassadors]':
        'id,first_name,last_name,sub_discipline,profile_photo,publish_status,ambassador_type,store_id',
    };

    if (filterString !== undefined && filterString !== '') {
      listingArgs['filter'] = filterString;
    }

    listingArgs['include'] = includesForQueryArgs;

    // perform GET request with query args using 'query' method from 'jsonapi-client' library
    const ambassadorsList = await Ambassador.query(listingArgs);

    // populate relationships for each Ambassador
    for (const ambassador of ambassadorsList) {
      // eslint-disable-next-line no-await-in-loop
      await dispatch('populateAmbassador', ambassador);
    }

    // update app state with new data
    commit('ambassadorsList', ambassadorsList);
    commit('ambassadorsListCount', ambassadorsList.meta.total);
    setPagination(ambassadorsList, commit);
  },
};

const getters = {
  ambassadorsList: state => state.ambassadorsList,
  ambassadorsListCount: state => state.ambassadorsListCount,
  paginationButtonStates: state => state.paginationButtonStates,
  currentFilterString: state => state.currentFilterString,
  filterCounts: state => state.filterCounts,
  filters: state => state.filters,
  pagination: state => state.pagination,
};

const mutations = {
  ambassadorsList(state, list) {
    state.ambassadorsList = list;
  },
  ambassadorsListCount(state, count) {
    state.ambassadorsListCount = count;
  },
  currentFilterString(state, currentFilterString) {
    state.currentFilterString = currentFilterString;
  },
  pagination(state, updatedPagination) {
    state.pagination = updatedPagination;
  },
  paginationButtonStates(state, paginationButtonStates) {
    state.paginationButtonStates = paginationButtonStates;
  },
  resetFilters(state) {
    Object.keys(state.filters).forEach(key => {
      state.filters[key] = FILTERS_DEFAULT[key];
    });
  },
  resetFilterCounts(state) {
    Object.keys(state.filterCounts).forEach(key => {
      state.filterCounts[key] = FILTER_COUNTS_DEFAULT[key];
    });
  },
  resetPagination(state) {
    Object.keys(state.pagination).forEach(key => {
      state.pagination[key] = PAGINATION_DEFAULT[key];
    });
  },
  updateFilter(state, payload) {
    state.filters[payload.filterName] = payload.filterValue;
  },
  updateFilterCounts(state, filterCounts) {
    state.filterCounts = filterCounts;
  },
  resetState(state) {
    Object.assign(state, initialState);
  },
};

const state = initialState;

// HELPER FUNCTIONS

// TODO: AMB-218 - Refactor constructFiqlFilterString function to reduce size and complexity
function constructFiqlFilterString(filterArgs, inputSelectionItems, commit) {
  const andStatements = [];
  const filterCounts = { ...FILTER_COUNTS_DEFAULT };

  if (filterArgs.search) {
    const isolatedSearchTerms = filterArgs.search.split(' ');

    for (const searchTerm of isolatedSearchTerms) {
      const validUUID = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

      if (validUUID.test(searchTerm)) {
        andStatements.push({
          equals: {
            selector: 'ambassadors.id',
            args: searchTerm,
          },
        });
      } else {
        const fuzzySearch = `${searchTerm}*`;

        andStatements.push({
          group: {
            or: [
              {
                equals: {
                  selector: 'ambassadors.first_name',
                  args: fuzzySearch,
                },
              },
              {
                equals: {
                  selector: 'ambassadors.last_name',
                  args: fuzzySearch,
                },
              },
            ],
          },
        });
      }
    }
  }

  if (filterArgs.publish_status) {
    andStatements.push({
      equals: {
        selector: 'ambassadors.publish_status',
        args: filterArgs.publish_status,
      },
    });
  }

  if (filterArgs.ambassador_type && filterArgs.ambassador_type.length > 0) {
    const typeStatements = [];
    for (const item of filterArgs.ambassador_type) {
      typeStatements.push({
        equals: {
          selector: 'ambassadors.ambassador_type.id',
          args: item.id,
        },
      });
    }
    andStatements.push({
      group: {
        or: typeStatements,
      },
    });
    filterCounts['ambassador_details'] += typeStatements.length;
  }

  if (filterArgs.main_discipline && filterArgs.main_discipline.length > 0) {
    const mainDisciplineStatements = [];
    for (const item of filterArgs.main_discipline) {
      mainDisciplineStatements.push({
        equals: {
          selector: 'ambassadors.sub_discipline.parent_discipline_id',
          args: item.id,
        },
      });
    }
    andStatements.push({
      group: {
        or: mainDisciplineStatements,
      },
    });
    filterCounts['ambassador_details'] += mainDisciplineStatements.length;
  }

  if (filterArgs.sub_discipline && filterArgs.sub_discipline.length > 0) {
    const subDisciplineStatements = [];
    for (const item of filterArgs.sub_discipline) {
      subDisciplineStatements.push({
        equals: {
          selector: 'ambassadors.sub_discipline.id',
          args: item.id,
        },
      });
    }
    andStatements.push({
      group: {
        or: subDisciplineStatements,
      },
    });
    filterCounts['ambassador_details'] += subDisciplineStatements.length;
  }

  if (filterArgs.ambassador_status && filterArgs.ambassador_status.length > 0) {
    const statusStatements = [];
    for (const item of filterArgs.ambassador_status) {
      statusStatements.push({
        equals: {
          selector: 'ambassadors.ambassador_status.id',
          args: item.id,
        },
      });
    }
    andStatements.push({
      group: {
        or: statusStatements,
      },
    });
    filterCounts['ambassador_details'] += statusStatements.length;
  }

  if (filterArgs.country) {
    andStatements.push({
      equals: {
        selector: 'ambassadors.country',
        args: filterArgs.country,
      },
    });
    filterCounts['location'] += 1;
  }

  if (filterArgs.state) {
    andStatements.push({
      equals: {
        selector: 'ambassadors.state',
        args: filterArgs.state,
      },
    });
    filterCounts['location'] += 1;
  }

  if (filterArgs.city) {
    andStatements.push({
      equals: {
        selector: 'ambassadors.city',
        args: filterArgs.city,
      },
    });
    filterCounts['location'] += 1;
  }

  if (filterArgs.store && filterArgs.store.length > 0) {
    const storeStatements = [];
    for (const item of filterArgs.store) {
      storeStatements.push({
        equals: {
          selector: 'ambassadors.store_id',
          args: item.id,
        },
      });
    }
    andStatements.push({
      group: {
        or: storeStatements,
      },
    });
    filterCounts['location'] += storeStatements.length;
  }

  if (filterArgs.age && filterArgs.age.length > 0) {
    const ageRanges = [];

    for (const filterItem of filterArgs.age) {
      for (const defaultItem of inputSelectionItems.ageRanges) {
        // the Age Range <v-select> component in AmbassadorsListFilters.vue
        // returns only the selected item label (not an object)
        if (filterItem === defaultItem.label) {
          ageRanges.push(defaultItem);
          break;
        }
      }
    }

    if (ageRanges.length > 0) {
      const ageOrStatements = [];
      let ageDateRange;
      for (const ageRange of ageRanges) {
        ageDateRange = convertAgeRangeToDates(ageRange);
        ageOrStatements.push({
          group: {
            and: [
              {
                greater_than_or_equal: {
                  selector: 'ambassadors.birth_date',
                  args: ageDateRange.high,
                },
              },
              {
                less_than_or_equal: {
                  selector: 'ambassadors.birth_date',
                  args: ageDateRange.low,
                },
              },
            ],
          },
        });
      }
      andStatements.push({
        or: ageOrStatements,
      });
      filterCounts['personal_details'] += ageOrStatements.length;
    }
  }

  if (filterArgs.gender) {
    const genderStatements = [];
    for (const item of filterArgs.gender) {
      genderStatements.push({
        equals: {
          selector: 'ambassadors.gender',
          args: item,
        },
      });
    }
    if (andStatements.length > 0 && genderStatements.length > 0) {
      andStatements.push({
        group: {
          or: genderStatements,
        },
      });
    }

    // This is commented out because it causes the fiqlArgs to be formatted incorrectly,
    // resulting in 400 error. However, this "else if" statement does stop the function
    // from returning an empty 'or' array.
    // else if (genderStatements.length > 0) {
    //   andStatements.push({
    //     'or': genderStatements
    //   })
    // }
    else {
      andStatements.push({
        or: genderStatements,
      });
    }
    filterCounts['personal_details'] += genderStatements.length;
  }

  if (andStatements.length === 0) {
    return '';
  }

  const fiqlArgs = {
    and: andStatements,
  };

  commit('updateFilterCounts', filterCounts);

  return convertFromJson(fiqlArgs);
}

function convertAgeRangeToDates(ageRange) {
  const today = new Date();

  return {
    low: new Date(
      today.getFullYear() - ageRange.low,
      today.getMonth(),
      today.getDay()
    ).toISOString(),
    high: new Date(
      today.getFullYear() - ageRange.high,
      today.getMonth(),
      today.getDay()
    ).toISOString(),
  };
}

function setPagination(ambassadorsList, commit) {
  const paginationButtonStates = { ...PAGINATION_BUTTONS_DEFAULT };

  for (const key in paginationButtonStates) {
    if (key in ambassadorsList.links) {
      paginationButtonStates[key] = true;
    }
  }

  commit('paginationButtonStates', paginationButtonStates);
}

export default {
  actions,
  getters,
  mutations,
  state,
};
