<template>
  <div class="login-form-layout">
    <div class="login-form">
      <div v-if="loginFailMsg" class="login-error">
        <v-icon color="error">error</v-icon><span class="error-text">{{ loginFailMsg }}</span>
      </div>

      <div>
        <h1 class="h-login-form">
          {{ content.mainHeading }}
        </h1>
        <button
          name="login-with-AD"
          class="btn btn-primary btn-fullwidth"
          @click.prevent="loginWithAD"
        >
          {{ content.loginButton }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { readUrlToken, redirectToFederatedIdentityLogin } from '@/vuex/modules/auth';
import { customMessages } from '@/utils/errorHandlers';
import content from '@/content/staticContentForUI';

export default {
  name: 'LoginForm',

  data() {
    return {
      content: content.loginForm,
      loginFailMsg: '',
    };
  },

  computed: {
    ...mapGetters(['loginStatus']),
  },

  mounted() {
    const tokens = readUrlToken(this.$route);
    const isADLogin = localStorage.getItem('amb-federated-login');

    if (tokens) {
      if (isADLogin) {
        this.$store
          .dispatch('loginAD', tokens)
          .then(state => {
            if ('uri' in state) {
              this.$router.push(state.uri);
            }

            if ('logout' in state) {
              this.$store.dispatch('logout');
            }
          })
          .catch(error => {
            console.error(error);
            if (this.loginStatus === 'failure') {
              this.loginFailMsg = customMessages.loginFailed;
            }
          });
      } else {
        this.$router.push('/login');
      }
    }
  },

  methods: {
    loginWithAD() {
      const prParam = this.$route?.query?.pr;
      window.location = redirectToFederatedIdentityLogin({
        uri: prParam
          ? `${window.location.origin}/ambassadors?pr=${prParam}`
          : `${window.location.origin}/ambassadors`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/forms-inputs.scss';

.login-form-layout {
  background-color: $lll-color-white;
  display: grid;
  grid-row-gap: 25px;
  grid-template-columns:
    minmax(5vw, 1fr)
    minmax(min-content, max-content)
    minmax(5vw, 1fr);
  grid-template-rows: max-content;
  height: 100vh;
  padding-top: 100px;
}

.h-login-form {
  font-size: 1rem;
  font-weight: $lll-font-weight-semibold;
  margin-bottom: 25px;
  text-align: center;
  text-transform: uppercase;
}

.login-form {
  grid-column: 2;
}

.login-error {
  color: $lll-color-red;
  display: flex;
  margin-bottom: 20px;

  .error-text {
    padding-left: 7px;
  }
}

button {
  &.btn-fullwidth {
    width: 100%;
  }
}
</style>
