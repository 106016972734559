/* eslint-disable prettier/prettier */
import Store from '@/models/v2/Store';

const SSC_STORE_INFO = { id: '00000', name: 'SSC' };
const SSC_STORE_TYPES = ['city', 'elite', 'global'];

const initialState = () => {
  return {
    storesList: [],
  };
};

const actions = {
  async loadStoreRegistry({ commit, state }) {
    // check whether stores have already been saved in state before making API call
    if ('storeRegistry' in state) {
      return state.storeRegistry;
    }

    const newRegistry = await createStoreRegistry();

    commit('storeRegistry', newRegistry);
    return newRegistry;
  },

  async getStoresList({ dispatch, commit, state }) {
    if ('storesList' in state && state.storesList.length) {
      return state.storesList;
    }

    const storeRegistry = await dispatch('loadStoreRegistry');
    const storesList = [];

    // eslint-disable-next-line guard-for-in
    for (const key in storeRegistry) {
      storesList.push({
        id: storeRegistry[key].id,
        name: storeRegistry[key].name
      });
    }

    storesList.sort((left, right) => {
      // eslint-disable-next-line prefer-template
      return ('' + left.name).localeCompare(right.name);
    });

    commit('storesList', storesList);
    return storesList;
  },

  async getStoreById({ dispatch }, storeId) {
    const storeRegistry = await dispatch('loadStoreRegistry');
    let store = storeRegistry[storeId];

    if (store === undefined) {
      store = {
        id: storeId,
        name: 'Unknown Store',
      };
    }

    return store;
  },
};

const getters = {
  storesList: state => state.storesList,
};

const mutations = {
  storesList(state, list) {
    state.storesList = list;
  },
  storeRegistry(state, storeRegistry) {
    state.storeRegistry = storeRegistry;
  },
  resetState(state) {
    Object.assign(state, initialState);
  },
};

const state = initialState;

// HELPER FUNCTIONS

async function createStoreRegistry() {
  /**
   * Using raw axios adapter rather than jsonapi-client wrapper.
   * The performance characteristics of running all this data through
   * the library is terrible and tends to crash chrome. By manually parsing
   * the data and injecting into the registry, this is not a problem.
   */
  const storeRegistry = {};
  const requestUrl = Store.constructBaseURL();
  const queryArgs = Store.toQueryString({
    'fields[stores]': 'name,store_status',
    page_size: 10000, // fetch all the stores in one page load
    page_number: 1,
    filter: 'store_status.id==active*,store_status.id==draft',
  });

  const rawStoreResponse = await Store.adapter.get(`${requestUrl}?${queryArgs}`);

  const activeStoresList = rawStoreResponse.data.data.filter(item => {
    return item.relationships.store_status.data.id !== 'active_conflict';
  });

  // keep log of stores that the service delivers with null data so we can use it for debugging
  const storesWithNullData = [];

  for (const item of activeStoresList) {
    // filter out stores that are missing 'id' and 'name' attributes
    if (item.id && item.attributes.name) {
      storeRegistry[item.id] = new Store({
        id: item.id,
        name: `${item.id} - ${item.attributes.name}`,
      });
    } else {
      storesWithNullData.push(item.id);
    }
  }

  /**
   * If managers report a store is missing or they can't see their records, use this console.warn
   * for debugging. This may not be a problem after Stores Service/AEM Pipeline is completed
   * (currently there are different stacks with different data).
   */
//   if (storesWithNullData.length) {
//     console.warn('Store service is delivering these stores with null data (missing ID and Name), so they are not included in list of stores: ', storesWithNullData);
//   }

  /**
   * SSC isn't included in list of stores from Stores Service, so it's manually added by FE.
   * This allows forms to include SSC in list of items in the drop-down menu for store field.
   */
  storeRegistry[SSC_STORE_INFO.id] = SSC_STORE_INFO;

  return storeRegistry;
}

export { SSC_STORE_INFO, SSC_STORE_TYPES };

export default {
  actions,
  getters,
  mutations,
  state,
};
