import Discipline from '@/models/v2/Discipline';

const initialState = () => {
  return {
    disciplines: [],
  };
};

const actions = {
  async getDisciplines({ commit, state }) {
    // check whether disciplines have already been saved in state before making API call
    if (state.disciplines.length) {
      return state.disciplines;
    }

    const disciplines = await Discipline.fetchAll();

    commit('disciplines', disciplines);
    return disciplines;
  },

  async getDisciplineById({ dispatch }, disciplineId) {
    const disciplineRegistry = await dispatch('loadDisciplineRegistry');
    let discipline = disciplineRegistry[disciplineId];

    if (discipline === undefined) {
      discipline = {
        id: disciplineId,
        label: 'Unknown Discipline',
      };
    }

    return discipline;
  },

  async loadDisciplineRegistry({ dispatch, commit, state }) {
    if ('disciplineRegistry' in state) {
      return state.disciplineRegistry;
    }

    const disciplines = await dispatch('getDisciplines');
    const disciplineRegistry = {};

    for (const discipline of disciplines) {
      disciplineRegistry[discipline.id] = discipline;
    }

    commit('disciplineRegistry', disciplineRegistry);
    return disciplineRegistry;
  },
};

const getters = {
  disciplines: state => state.disciplines,
};

const mutations = {
  disciplines(state, disciplines) {
    state.disciplines = disciplines;
  },
  disciplineRegistry(state, disciplineRegistry) {
    state.disciplineRegistry = disciplineRegistry;
  },
  resetState(state) {
    Object.assign(state, initialState);
  },
};

const state = initialState;

export default {
  actions,
  getters,
  mutations,
  state,
};
