import { getInitialState } from '@/vuex/state';

export default {
  resetState(state) {
    Object.assign(state, getInitialState());
  },
  updateEmptyRequiredFields(state, payload) {
    state.emptyRequiredFields[payload.section] = payload.list;
  },
};
