<template>
  <div class="full-page-message flex-centered-column text-xs-center">
    <h1 v-if="heading" class="heading">
      {{ heading }}
    </h1>
    <p class="h-secondary">
      {{ body }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'AMBMessageFullPage',

  props: {
    heading: {
      type: String,
      default: '',
    },
    body: {
      required: true,
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.full-page-message {
  padding: 10vw 5vw;

  @media (min-width: 600px) {
    padding: 50px 20vw;
  }
}

.heading {
  font-size: $lll-font-size-medium;
  font-weight: $lll-font-weight-semibold;
  margin-bottom: 15px;

  @media (min-width: 600px) {
    font-size: $lll-font-size-large;
  }
}
</style>
