import Ambassador from '@/models/v2/Ambassador';

/**
 * These attributes are relationships on the Ambassador model. Include these
 * in query args when making requests to the service (JSON:API spec).
 */
const includes = [
  'ambassador_status',
  'ambassador_type',
  'experiences',
  'profile_locale',
  'profile_photo',
  'profile_photo.tags',
  'sub_discipline',
  'sub_discipline.parent_discipline',
  'product_categories',
];
const includesForQueryArgs = includes.join(',');

const initialState = () => {
  return {
    currentAmbassador: new Ambassador(),
  };
};

const actions = {
  /**
   * Fetches the most recent data for a given ambassador ID and populates the relationships.
   *
   * @param {Object} context - First param is reserved for context object
   * @param {String} ambassadorId - 'id' attribute from Ambassador model (UUID)
   * @return - Ambassador model delivered from the service
   */
  async getAmbassadorById({ dispatch }, ambassadorId) {
    const args = { include: includesForQueryArgs };

    const ambassador = await Ambassador.fetch(ambassadorId, args);

    return dispatch('populateAmbassador', ambassador);
  },

  /**
   * Populates attributes that aren't present on the initial Ambassador model as delivered
   * from the service. This attribute data used by multiple components (e.g., ProfileDetailsMain).
   *
   * @param {Object} context - First param is reserved for context object
   * @param {Object} ambassador - Ambassador model
   * @return - Updated Ambassador model
   */
  async populateAmbassador({ dispatch }, ambassador) {
    /**
     * Store details are not stored in Ambassadors Service (only 'store_id'), so we must populate
     * 'ambassador.store' using data from Stores Service.
     */
    ambassador.store = await dispatch('getStoreById', ambassador.store_id);

    if (ambassador.sub_discipline.id) {
      /**
       * 'main_discipline' is not an attribute on the Ambassador record delivered from the
       * service, since it's part of 'sub_discipline' relationship. Add it here so components
       * can reference 'ambassador.main_discipline' without the need for special handlers.
       */
      ambassador.main_discipline = ambassador.sub_discipline.parent_discipline;
    }

    return ambassador;
  },

  /**
   * Fetches the most recent data for a given ambassador ID and updates the value of
   * 'currentAmbassador' saved in state. This ensures all components have access to the
   * latest data for that ambassador.
   *
   * @param {Object} context - First param is reserved for context object
   * @param {String} ambassadorId - 'id' attribute from Ambassador model (UUID)
   */
  async updateCurrentAmbassador({ commit, dispatch }, ambassadorId) {
    const currentAmbassador = await dispatch('getAmbassadorById', ambassadorId);
    commit('currentAmbassador', currentAmbassador);
  },
};

const getters = {
  currentAmbassador: state => state.currentAmbassador,
};

const mutations = {
  currentAmbassador(state, currentAmbassador) {
    state.currentAmbassador = currentAmbassador;
  },
  resetState(state) {
    Object.assign(state, initialState);
  },
};

const state = initialState;

export { includesForQueryArgs };

export default {
  actions,
  getters,
  mutations,
  state,
};
