<template>
  <div class="menu-filters">
    <div class="menu-header flex-space-between lll-hr-grey">
      <h1 class="h-section-title mb-3">
        {{ content.sectionTitle }}
      </h1>
      <button class="btn-link" name="clear-filters" @click="clearFilters">
        <v-icon>clear</v-icon>
        {{ content.clearButton }}
      </button>
    </div>

    <amb-input-label input-id="sort-users-list" label-text="Sort By" />
    <!-- Sort By -->
    <v-select
      id="sort-users-list"
      v-model="sortUsersBy"
      item-text="label"
      item-value="value"
      :items="sortOptions"
      chips
      deletable-chips
      solo
      flat
      hide-selected
      placeholder="Sort By"
      menu-props="offset-y"
      class="lll-input"
    />
    <amb-input-label input-id="filter-role" label-text="User Role" />
    <!-- Filter By  -->
    <v-select
      id="filter-role"
      v-model="filterUsersBy"
      multiple
      item-text="label"
      item-value="id"
      :items="roles"
      chips
      deletable-chips
      solo
      flat
      hide-selected
      placeholder="Filter by User Role"
      menu-props="offset-y"
      class="lll-input"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AMBInputLabel from '@/components/AMBInputLabel';
import content from '@/content/staticContentForUI';

export default {
  name: 'UsersListFilters',

  components: {
    'amb-input-label': AMBInputLabel,
  },

  data() {
    return {
      content: content.usersListFilters,
      sortOptions: [
        {
          label: 'Email',
          value: 'email',
        },
        {
          label: 'Last Active',
          value: 'last_authenticated_date',
        },
      ],
    };
  },

  computed: {
    ...mapGetters(['filterBy', 'roles', 'sortBy']),

    filterUsersBy: {
      get() {
        return this.filterBy;
      },
      set(value) {
        this.$store.commit('filterBy', value);
      },
    },

    sortUsersBy: {
      get() {
        return this.sortBy;
      },
      set(value) {
        this.$store.commit('sortBy', value);
      },
    },
  },

  methods: {
    clearFilters() {
      this.$store.commit('filterBy', []);
      this.$store.commit('sortBy', '');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/forms-inputs.scss';
</style>
