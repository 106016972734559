<template>
  <button class="btn-profile-card" @click="openProfileDetails(ambassador)">
    <div class="ambassador-card grid-2-col-responsive">
      <div class="card-header row lll-hr-grey">
        <amb-image
          circle
          :image-object="ambassador.profile_photo"
          :image-view-attributes="`&hei=150&qlt=85`"
          class="card-photo"
        />
        <div>
          <h1 class="h-primary mb-1">{{ ambassadorName }}</h1>
          <h2 class="h-secondary">{{ ambassadorType }}</h2>
        </div>
      </div>
      <div class="card-details row">
        <div>
          <h3 class="h-label">
            {{ content.disciplineLabel }}
          </h3>
          <amb-pending-label v-if="!ambassador.sub_discipline.label" />
          <div v-else class="lll-text-truncate">
            {{ ambassador.sub_discipline.label }}
          </div>
        </div>
        <div>
          <h3 class="h-label">
            {{ content.storeLabel }}
          </h3>
          <div class="lll-text-truncate">
            {{ ambassador.store.name }}
          </div>
        </div>
      </div>
    </div>
  </button>
</template>

<script>
import content from '@/content/staticContentForUI';
import AMBImage from '@/components/AMBImage';
import AMBPendingLabel from '@/components/AMBPendingLabel';

export default {
  name: 'AmbassadorsListCard',

  components: {
    'amb-image': AMBImage,
    'amb-pending-label': AMBPendingLabel,
  },

  props: {
    ambassador: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      publishStatuses: content.inputSelectionItems.publishStatuses,
      content: content.ambassadorsListCard,
    };
  },

  computed: {
    ambassadorName() {
      return `${this.ambassador.first_name} ${this.ambassador.last_name}`;
    },

    ambassadorType() {
      return `${this.ambassador.ambassador_type.label} ${this.content.ambassadorLabel}`;
    },
  },

  methods: {
    /**
     * Use programmatic navigation instead of <router-link>, so state.currentAmbassador can be
     * updated BEFORE user lands on ProfileDetails view. This avoids making extra API calls,
     * which increases load time and can cause the wrong data to display on screen (old profile
     * data displays for a second before new data is fetched).
     */
    openProfileDetails(ambassador) {
      const routeInfo = { name: 'ambassadorProfile', params: { id: ambassador.id } };

      this.$store.commit('currentAmbassador', ambassador);
      this.$router.push(routeInfo).catch(error => {
        console.warn('vue-router error:', error);
      });
    },

    getPublishStatusLabel(statusId) {
      const status = this.publishStatuses.find(item => item.id === statusId);

      return status.label;
    },
  },
};
</script>

<style lang="scss" scoped>
.ambassador-card {
  padding: 15px 15px 0 15px;
  background-color: $lll-color-white;
  font-size: $lll-font-size-small;
  grid-gap: 0;
}

.btn-profile-card {
  text-align: inherit;
  padding: 0;
  border: inherit;
  align-items: inherit;
}

.card-header {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.card-details {
  display: grid;
  grid-template-columns: minmax(20ch, 1fr);
  grid-gap: 15px;
  padding-bottom: 15px;

  @media (min-width: 475px) {
    grid-template-columns: repeat(2, minmax(20ch, 1fr));
  }
}

.card-status {
  background-color: $lll-color-grey-light;
  color: $lll-color-charcoal;
  font-weight: $lll-font-weight-medium;
  font-size: $lll-font-size-xsmall;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  padding: 5px;
}

.card-photo {
  width: 75px;
  height: 75px;
  margin-right: 20px;
}
</style>
