import Locale from '@/models/v2/Locale';

const initialState = () => {
  return {
    ambassadorLocales: [],
  };
};

const actions = {
  async getAmbassadorLocales({ commit, state }) {
    // check whether locales have already been saved in state before making API call
    if (state.ambassadorLocales.length) {
      return state.ambassadorLocales;
    }

    const ambassadorLocales = await Locale.fetchAll();
    commit('ambassadorLocales', ambassadorLocales);

    return ambassadorLocales;
  },
};

const getters = {
  ambassadorLocales: state => state.ambassadorLocales,
};

const mutations = {
  ambassadorLocales(state, locales) {
    state.ambassadorLocales = locales;
  },
  resetState(state) {
    Object.assign(state, initialState);
  },
};

const state = initialState;

export default {
  actions,
  getters,
  mutations,
  state,
};
