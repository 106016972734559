<template>
  <label :for="fieldId">
    <abbr v-show="showAsterisk" title="required" class="lll-asterisk">*</abbr>
    <span class="h-label">{{ fieldLabel }}</span>
  </label>
</template>

<script>
/**
 * This reusable component generates markup for a form field label. This component is similar
 * to AMBInputLabel, but it's designed to be used with form fields that are defined in
 * formHelpers. Those helper functions are used to get 'id' and label text.
 */
import isEmpty from 'lodash/isEmpty';
import { getFieldDetails, convertSnakeToKebab } from '@/utils/formHelpers';

export default {
  name: 'AMBFormFieldLabel',

  props: {
    /**
     * A unique identifier that matches the corresponding attribute name on Ambassador model
     * (e.g., 'first_name'). This must exist as a property in FORM_FIELD_DETAILS to be used by
     * functions in formHelpers. The string should be snake_case, all lowercase.
     */
    attributeId: {
      required: true,
      type: String,
      default: '',
    },
    /**
     * Custom value for input element's 'id' attribute. If empty, 'attributeId' will be used.
     */
    customFieldId: {
      type: String,
      default: '',
    },
    /**
     * Determines if asterisk will be displayed next to field label.
     */
    showAsterisk: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    fieldId() {
      return this.customFieldId || convertSnakeToKebab(this.attributeId);
    },

    fieldLabel() {
      const fieldDetails = getFieldDetails('attributeId', this.attributeId);

      if (isEmpty(fieldDetails)) {
        console.error(`Cannot find '${this.attributeId}' in FORM_FIELD_DETAILS`);
      }

      return fieldDetails[this.attributeId].fieldText.label;
    },
  },
};
</script>
