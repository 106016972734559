<template>
  <div class="lll-form-layout">
    <h1 class="h-page-title">
      {{ content.mainHeading }}
    </h1>
    <div class="lll-form-container user-create-form-container">
      <div v-if="loading" class="loading-spinner">
        <v-progress-circular :indeterminate="true" size="50" />
      </div>
      <v-form
        v-if="!loading && !createFormEnabled"
        ref="searchForm"
        class="lll-form-body lll-form-padding lll-bg-white"
      >
        <v-text-field
          ref="searchInput"
          v-model.trim="userSearchInput"
          :placeholder="content.placeholder"
          :rules="[validationRules.email_lll]"
          class="lll-input"
          browser-autocomplete="off"
          solo
          flat
          clearable
          prepend-inner-icon="search"
          aria-label="Search User by Email"
          @click:prepend-inner="searchEmail"
        />
        <div class="row btn-group-2-col">
          <button
            name="search"
            class="btn btn-primary"
            type="submit"
            :disabled="loading"
            @click.prevent="searchEmail"
          >
            {{ content.searchButton }}
          </button>
          <button name="cancel" class="btn" @click.prevent="cancelSearch">
            {{ content.cancelButton }}
          </button>
        </div>
      </v-form>
      <!-- hide search form and show create form on successful search -->
      <amb-form
        v-if="!loading && createFormEnabled"
        :ref="createFormName"
        :default-values="createFormFieldsDefault"
        :fields="createFormFields"
        class="lll-bg-white user-create-form"
      >
        <div>
          <abbr title="required" class="lll-asterisk">*</abbr>
          <span class="h-label">{{ content.required }}</span>
        </div>
        <div class="form-field">
          <amb-input-label input-id="user-name" :label-text="content.name" />
          <v-text-field
            id="user-name"
            :value="createFormFields.name"
            readonly
            solo
            flat
            hide-details
          />
        </div>
        <div class="form-field">
          <amb-input-label input-id="user-email" :label-text="content.email" />
          <v-text-field
            id="user-email"
            :value="createFormFields.email"
            readonly
            solo
            flat
            hide-details
          />
        </div>
        <div class="form-field">
          <amb-input-label input-id="user-role" :label-text="content.role" show-asterisk />
          <v-select
            id="user-role"
            v-model="createFormFields.role"
            :items="roles"
            item-text="label"
            item-value="id"
            return-object
            :rules="[validationRules.required]"
            solo
            flat
            hide-selected
            menu-props="offset-y"
          />
        </div>
        <div class="row btn-group-2-col">
          <button name="create" class="btn btn-primary" @click.prevent="saveUser">
            {{ content.saveButton }}
          </button>
          <button name="cancel" class="btn" @click.prevent="cancelSaveUser">
            {{ content.cancelButton }}
          </button>
        </div>
      </amb-form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import AMBForm from '@/components/AMBForm';
import AMBInputLabel from '@/components/AMBInputLabel';
import content from '@/content/staticContentForUI';
import * as formHelpers from '@/utils/formHelpers';
import User from '@/models/User';
import { getEmployeeData } from '@/vuex/modules/permissions';

// response from MS Graph (Workday) API call should include these employee details to save new user
const MS_GRAPH_FIELDS_REQUIRED = ['email', 'name', 'title'];
const USER_CREATE_FORM_FIELDS_DEFAULT = {
  email: '',
  job_title: '',
  name: '',
  role: '',
};

export default {
  name: 'UserProfileCreate',

  components: {
    'amb-form': AMBForm,
    'amb-input-label': AMBInputLabel,
  },

  beforeRouteLeave(routeTo, routeFrom, next) {
    if (!this.allowRouteChange && this.$refs[this.createFormName].isDirty()) {
      // trigger process to show error message for unsaved changes
      this.confirmRouteChange(routeTo);
      next(false);
    } else {
      // if route change is allowed, saved form data no longer needed
      formHelpers.removeSavedFormData(this.createFormName);
      next();
    }
  },

  data() {
    return {
      allowRouteChange: true,
      content: content.userProfileCreate,
      createFormEnabled: false,
      createFormFields: { ...USER_CREATE_FORM_FIELDS_DEFAULT },
      createFormFieldsDefault: { ...USER_CREATE_FORM_FIELDS_DEFAULT },
      createFormName: 'userProfileCreate',
      loading: false,
      userSearchInput: '',
      validationRules: formHelpers.VALIDATION_RULES,
    };
  },

  computed: {
    ...mapGetters(['roles', 'searchResults']),
  },

  created() {
    this.loading = true;
    this.getRoles()
      .then(() => {
        this.prepCreateForm();
        this.loading = false;
      })
      .catch(error => {
        this.$handleErrorMessage(error);
        this.loading = false;
      });
  },

  methods: {
    ...mapActions(['getRoles']),

    cancelFormInProgress({ onConfirmCallback, onCancelCallback }) {
      const errorSnackbarBasicConfig = this.$createErrorSnackbar(
        content.errorMessages.formIncomplete
      );

      if (this.$refs[this.createFormName].isDirty()) {
        this.$swal({
          ...errorSnackbarBasicConfig,
          showCancelButton: true,
          cancelButtonText: content.formErrorSnackbar.backToForm,
          confirmButtonText: content.formErrorSnackbar.confirmButton,
        }).then(result => {
          if (result.value) {
            onConfirmCallback();
          }
        });
      } else {
        onCancelCallback();
      }
    },

    cancelSaveUser() {
      this.cancelFormInProgress({
        onConfirmCallback: () => this.navigateToPreviousRoute(),
        onCancelCallback: () => this.navigateToPreviousRoute(),
      });
    },

    cancelSearch() {
      this.navigateToPreviousRoute();
    },

    confirmRouteChange(routeTo) {
      this.cancelFormInProgress({
        onConfirmCallback: () => this.enableRouteChange(routeTo),
        onCancelCallback: () => this.navigateToPreviousRoute(),
      });
    },

    enableRouteChange(routeTo) {
      this.allowRouteChange = true;

      if (routeTo.name) {
        // handle click on router-link
        this.$router.push(routeTo.path).catch(error => {
          console.warn('vue-router error:', error);
        });
      } else {
        // handle click on 'Cancel' button, in which case, there is no route object to pass in
        this.navigateToPreviousRoute();
      }
    },

    handleSavedFormData(formData) {
      Object.keys(formData).forEach(field => {
        this.createFormFields[field] = formData[field];
      });

      this.createFormEnabled = true;
      this.allowRouteChange = false;
    },

    handleSearchResults(user) {
      if (this.isMissingEmployeeData(user)) {
        const errorMessage = this.$createErrorSnackbar(this.content.errorEmployeeDataMissing);
        this.$swal({
          ...errorMessage,
        });
      } else if (this.hasProfile(user)) {
        const errorMessage = this.$createErrorSnackbar(this.content.errorExistingProfile);
        this.$swal({
          ...errorMessage,
        });
      } else {
        this.setFormFieldValues(user);
        this.createFormEnabled = true;
        this.allowRouteChange = false;
      }
    },

    hasProfile(user) {
      if (!user) {
        return false;
      }

      return !!user.custom_permission_id;
    },

    isMissingEmployeeData(data) {
      for (const field of MS_GRAPH_FIELDS_REQUIRED) {
        if (!data[field]) {
          return true;
        }
      }

      return false;
    },

    navigateToPreviousRoute() {
      this.allowRouteChange = true;

      /**
       * This handles rare case where user lands directly on this '/users/profile/create' route,
       * so this.$router.go(-1) does nothing, because there is no previous route.
       */
      if (window.history.length > 1) {
        this.$router.go(-1);
      } else {
        this.$router.push('/users').catch(error => {
          console.warn('vue-router error:', error);
        });
      }
    },

    prepCreateForm() {
      /**
       * This route query is present if search on UsersList page returned a valid user without a role,
       * and the "add new user" button was clicked to open this create form.
       */
      const hasSavedSearchResults = this.$route?.query?.searched === 1;
      const savedSearchResults = this.searchResults[0];
      // check for form in progress (e.g., session expired and app refreshed before form submitted)
      const savedForm = formHelpers.getSavedFormData(this.createFormName);

      if (!hasSavedSearchResults && !savedForm) {
        // search form will show first, so no need to prep create form
        return;
      }

      // if savedForm or savedSearchResults are present, bypass search form and show create form
      if (hasSavedSearchResults) {
        this.handleSearchResults(savedSearchResults);
      }

      if (savedForm?.data) {
        this.handleSavedFormData(savedForm.data);
      }
    },

    /**
     * Reset these values in state, so newly saved user will be added to list of all users and
     * search results won't be outdated when the UsersList component is mounted again.
     */
    resetUsersList() {
      this.$store.commit('needsProfile', '');
      this.$store.commit('needsRole', '');
      this.$store.commit('searchResults', []);
      this.$store.commit('updateSearchInput', '');
      this.$store.commit('users', []);
    },

    saveUser() {
      const formData = this.$refs[this.createFormName].getValidatedFormData();

      if (isEmpty(formData)) {
        return;
      }

      this.loading = true;
      formHelpers.saveFormInProgress(this.createFormName, formData);

      const newUser = new User(formData);
      newUser
        .save()
        .then(user => {
          this.allowRouteChange = true;
          this.loading = false;
          formHelpers.removeSavedFormData(this.formName);
          this.resetUsersList();
          this.$router
            .push({
              name: 'userProfile',
              params: { id: user.id },
            })
            .catch(error => {
              console.warn('vue-router error:', error);
            });
        })
        .catch(error => {
          this.loading = false;
          this.$handleErrorMessage(error);
        });
    },

    searchEmail() {
      const formValid = this.$refs.searchForm.validate();
      const searchInput = this.$refs.searchInput.value;

      if (!formValid || !searchInput) {
        return;
      }

      this.loading = true;

      getEmployeeData(this.userSearchInput)
        .then(employeeData => {
          this.handleSearchResults(employeeData);
          this.loading = false;
        })
        .catch(error => {
          if (error?.response?.status === 400) {
            const errorMessage = this.$createErrorSnackbar(this.content.errorEmailInvalid);
            this.$swal({
              ...errorMessage,
            });
          } else {
            this.$handleErrorMessage(error);
          }

          this.loading = false;
        });
    },

    setFormFieldValues(data) {
      this.createFormFields.name = data.name;
      this.createFormFields.email = data.email;
      this.createFormFields.job_title = data.title;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/forms-inputs.scss';

.user-create-form-container {
  @media (min-width: 600px) {
    width: 500px;
  }
}

.user-create-form {
  display: grid;
  gap: 16px;
}

.form-field {
  display: grid;
  gap: 4px;
}

.loading-spinner {
  display: grid;
  justify-content: center;
}
</style>
