import AmbassadorsBase from '@/models/v2/AmbassadorsBase';

class Country extends AmbassadorsBase {
  static _type = 'country';

  static name = 'country';

  static baseURL = '/attributes/country';

  constructor(args = {}) {
    super(args);
    this.id = args.id;
  }
}

export default Country;
