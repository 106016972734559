<template>
  <div class="profile-summary-layout">
    <a
      :href="profilePhotoFullSize"
      target="_blank"
      class="profile-photo"
      :class="profilePhotoLinkClass"
    >
      <amb-image
        :image-object="ambassador.profile_photo"
        :image-view-attributes="`&scl=2&qlt=85`"
      />
    </a>
    <div class="ambassador-details lll-bg-white lll-padding-med">
      <h2 class="h-primary ambassador-name">
        {{ ambassador.first_name }}
        {{ ambassador.last_name }}
      </h2>
      <h3 class="h-secondary mb-2">{{ ambassadorType }}</h3>
      <div>
        <h3 class="h-secondary mb-2">
          {{ `${content.statusLabel}: ${ambassador.ambassador_status.label}` }}
        </h3>
      </div>
      <div class="ambassador-discipline">
        <h3 class="h-secondary mb-2">
          {{ ambassador.sub_discipline.label }}
        </h3>
      </div>
      <div v-if="ambassador.profile_url_slug" class="profile-summary-button">
        <a
          :href="profileUrl"
          target="_blank"
          rel="noopener noreferrer"
          name="view-published-profile"
          class="btn btn-primary text-no-wrap"
        >
          {{ content.viewProfileButton }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AMBImage from '@/components/AMBImage';
import get from '@/utils/config';
import content from '@/content/staticContentForUI';

export default {
  name: 'ProfileDetailsSummary',

  components: {
    'amb-image': AMBImage,
  },

  props: {
    ambassador: {
      required: true,
      default: () => {},
      type: Object,
    },
    loading: {
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      content: content.profileDetailsSummary,
      profileHostname: get('VUE_APP_PROFILE_HOSTNAME') || process.env.VUE_APP_PROFILE_HOSTNAME,
    };
  },

  computed: {
    ...mapGetters(['userPermissions']),

    ambassadorType() {
      const type = this.ambassador.ambassador_type.label;
      return type ? `${type} ${this.content.ambassadorLabel}` : '';
    },

    profilePhotoFullSize() {
      const { publish_url, url } = this.ambassador.profile_photo;
      const photoUrl = publish_url || url;

      return photoUrl || null;
    },

    profilePhotoLinkClass() {
      return this.profilePhotoFullSize ? 'cursor-zoom' : 'cursor-default';
    },

    profileUrl() {
      return this.ambassador && this.ambassador.profile_url_slug
        ? `${this.profileHostname}/ambassadors/${this.ambassador.profile_url_slug}`
        : '';
    },

    showDivider() {
      return this.ambassador.profile_url_slug || this.userPermissions.canUpdateAmbassadorStatus;
    },
  },
};
</script>

<style lang="scss" scoped>
.ambassador-details {
  grid-area: details;
}

.ambassador-name {
  font-size: $lll-font-size-large;
  line-height: 1.25;
  margin-bottom: 15px;
}

.ambassador-discipline {
  display: grid;
  grid-template-columns: minmax(20ch, 1fr);
}

.cursor-zoom {
  cursor: -moz-zoom-in;
  cursor: -webkit-zoom-in;
  cursor: zoom-in;
}

.cursor-default {
  cursor: default;
}

.profile-photo {
  grid-area: photo;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.profile-summary-layout {
  display: grid;
  grid-template:
    'photo'
    'details'
    /
    1fr;
  align-content: start;

  @media (max-width: 1000px) and (min-width: 600px) {
    grid-template:
      'photo details'
      /
      1fr
      2fr;
  }
}

.profile-summary-button {
  margin-top: 25px;
}
</style>
