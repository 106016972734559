export default {
  emptyRequiredFields: state => state.emptyRequiredFields,
  emptyRequiredFieldsTotal: state => {
    let total = 0;

    Object.values(state.emptyRequiredFields).forEach(fieldsList => {
      total += fieldsList.length;
    });

    return total;
  },
};
