<template>
  <div v-if="userIsAdmin" class="container-admin-panel-outer text-xs-center">
    <v-bottom-sheet v-model="panelIsOpen" full-width>
      <template #activator>
        <button class="btn-link btn-toggle-admin-panel text-no-wrap">
          <v-icon class="pr-1">
            settings
          </v-icon>
          Open Admin Panel
        </button>
      </template>
      <v-sheet>
        <div class="container-admin-panel-inner">
          <div class="toolbar-btns-container admin-panel-header">
            <v-btn-toggle v-if="showFeatureToggle" v-model="activeFeature" mandatory>
              <v-btn
                v-for="feature in features"
                :key="feature.id"
                :value="feature.id"
                color="secondary"
                class="white--text"
                @click="activateFeature(feature.id)"
              >
                {{ feature.label }}
              </v-btn>
            </v-btn-toggle>
            <v-btn class="admin-panel-btn ml-2" :disabled="loading" @click.prevent="refreshSession">
              <v-icon>refresh</v-icon>
            </v-btn>
          </div>
          <div class="admin-panel-main lll-scrollable">
            <h2 class="h-primary mb-2">
              Current user
            </h2>
            <div class="admin-panel-details">
              <h3 class="h-label">
                Email:
              </h3>
              {{ userClaims.email }}
            </div>
            <div class="admin-panel-details">
              <h3 class="h-label">
                Stores:
              </h3>
              {{ userStores }}
            </div>
            <div class="admin-panel-details">
              <h3 class="h-label">
                Permissions:
              </h3>
              <ul class="list-unstyled">
                <li v-for="(item, index) in permissionsFormatted" :key="index">
                  {{ item }}
                </li>
              </ul>
            </div>
            <h2 class="h-primary">
              Sign in as another user
            </h2>
            <div class="admin-panel-form grid-2-col-responsive">
              <v-text-field
                v-model.trim="newEmail"
                placeholder="Enter user email"
                hide-details
                browser-autocomplete="off"
                :loading="loading"
              />
              <div class="btn-group-2-col">
                <button class="btn btn-primary" :disabled="loading" @click.prevent="assumeNewRole">
                  Sign In
                </button>
                <button class="btn" :disabled="loading" @click.prevent="resumeAsAdmin">
                  Resume as Original User
                </button>
              </div>
            </div>
          </div>
          <!-- ^ admin-panel-main -->
        </div>
        <!-- ^ container-admin-panel-inner -->
        <button class="btn-link btn-toggle-admin-panel text-no-wrap" @click.prevent="closePanel">
          <v-icon class="pr-1">
            close
          </v-icon>
          <span>Close Admin Panel</span>
        </button>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { formatResponseError } from '@/utils/errorHandlers';
import { userIsAdmin } from '@/vuex/modules/auth';

export default {
  name: 'AdminPanel',

  data() {
    return {
      loading: false,
      newEmail: '',
      panelIsOpen: false,
      activeFeature: '',
      showFeatureToggle: false,
      /**
       * Array of Objects containing 'id' and 'label' for each feature.
       * First item is used as default activeFeature.
       */
      features: [],
    };
  },

  computed: {
    ...mapGetters(['userClaims', 'userPermissions']),

    userIsAdmin() {
      return this.userClaims ? userIsAdmin() : false;
    },

    userStores() {
      let storesList;
      const storesInClaims = this.userClaims.ambassador_read;

      if (storesInClaims === '*') {
        storesList = 'All stores';
      } else {
        storesList = Array.from(storesInClaims).join(', ');
      }

      return storesList;
    },

    permissionsFormatted() {
      return Object.entries(this.userPermissions)
        .filter(item => {
          return item.includes(true);
        })
        .map(item => {
          return item[0].replace(/([A-Z])/g, ' $1');
        });
    },
  },

  created() {
    if (this.showFeatureToggle) {
      this.setActiveFeature();
    }
  },

  methods: {
    ...mapActions(['impersonateUser', 'restoreAdminUser']),

    /**
     * Throws error with status 401 to trigger session refresh. This is useful for QA/dev testing
     * functions related to 401 errors. Error handler checks value of 'error.response.status'.
     */
    refreshSession() {
      const fakeError = new Error('force session refresh');
      fakeError.response = { status: 401 };
      this.$handleErrorMessage(formatResponseError(fakeError));
    },

    activateFeature(featureId) {
      localStorage.setItem('activeFeature', featureId);
      // refresh the page after activating new feature
      this.$router.go();
    },

    setActiveFeature() {
      // if no features activated, use first list item as default
      this.activeFeature = localStorage.getItem('activeFeature') || this.features[0].id;
    },

    assumeNewRole() {
      if (!this.emailIsValid()) {
        const errorMessage = this.$createErrorSnackbar(
          "Please enter a valid lululemon email address for the user you're trying to sign in as."
        );
        this.$swal({
          ...errorMessage,
        });

        return;
      }

      this.loading = true;
      this.redirectHome();
      this.impersonateUser(this.newEmail)
        .then(() => {
          this.loading = false;
          this.closePanel();
        })
        .catch(error => {
          this.loading = false;
          this.$handleErrorMessage(formatResponseError(error));
        });
    },

    closePanel() {
      this.panelIsOpen = false;
    },

    emailIsValid() {
      const email = this.newEmail;
      // eslint-disable-next-line prettier/prettier
      const validEmailFormat = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        email
      );
      // eslint-disable-next-line prettier/prettier
      const lllEmail = /^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?(lululemon)\.com$/.test(
        email
      );

      if (validEmailFormat && lllEmail) {
        return true;
      }

      return false;
    },

    redirectHome() {
      /**
       * Before dispatching action, redirect to home page to avoid errors.
       * E.g., if Admin User is on profile page for Global Ambassador while
       * trying to sign in as Store Manager, a 403 error will pop-up and
       * profile content will disappear, because that manager should not
       * be able to view Global Ambassadors.
       */
      if (this.$router.currentRoute.name !== 'ambassadors') {
        this.$router.push('/').catch(error => {
          console.warn('vue-router error:', error);
        });
      }
    },

    resumeAsAdmin() {
      this.loading = true;
      this.redirectHome();
      this.restoreAdminUser()
        .then(() => {
          this.loading = false;
          this.closePanel();
        })
        .catch(error => {
          this.loading = false;
          this.$handleErrorMessage(formatResponseError(error));
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.container-admin-panel-outer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 7;
}

.container-admin-panel-inner {
  padding: 5vw;
  background-color: $lll-color-white;
  display: grid;
  grid-template:
    'toolbarBtns'
    'main' / 1fr;
  @media (min-width: 600px) {
    padding: 25px;
  }
}

.admin-panel-main {
  grid-area: main;
}

.toolbar-btns-container {
  grid-area: toolbarBtns;
  margin: 0 0 16px 0;
}

.btn-toggle-admin-panel {
  border-top: 1.5px solid $lll-color-grey-light;
  background-color: $lll-color-background;
  padding: 10px;
  width: 100%;
}

.admin-panel-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media (min-width: 600px) {
    justify-content: flex-end;
  }
}

.admin-panel-details {
  line-height: 1.5rem;
  margin: 0 0 15px 0;
}

.admin-panel-form {
  > .btn-group-2-col {
    margin: 0;
    padding: 0;
  }

  > .v-text-field {
    padding-top: 0;
  }
}

.email-input {
  width: 20em;
}

.lll-scrollable {
  max-height: 300px;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
  @media (min-width: 600px) {
    max-height: 600px;
  }
}

.container-admin-panel-outer {
  ::v-deep .v-bottom-sheet.v-dialog {
    overflow-y: auto;
  }
}

.admin-panel-btn.v-btn {
  padding: 0 8px;
  margin: 0;
  min-width: 40px;
}
</style>
