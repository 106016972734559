<template>
  <div>
    <div class="grid-2-col-space-between lll-padding-med">
      <h1 class="h-section-subtitle">{{ content.expandAccordions.instructions }}</h1>
      <button
        v-if="allAccordionsExpanded"
        name="collapse-accordions"
        class="btn-link"
        @click="collapseAccordions"
      >
        <v-icon class="pr-1">
          unfold_less
        </v-icon>
        {{ content.expandAccordions.collapseButton }}
      </button>
      <button v-else name="expand-accordions" class="btn-link" @click="expandAccordions">
        <v-icon class="pr-1">
          unfold_more
        </v-icon>
        {{ content.expandAccordions.expandButton }}
      </button>
    </div>
    <div class="accordions">
      <!-- ----- STATUS AND CONTRACT SECTION ----- -->
      <profile-details-accordion
        :ref="sections.statusAndContract.sectionName"
        :section-name="sections.statusAndContract.sectionName"
        :edits-disabled="profileEditsDisabled(sections.statusAndContract.sectionName)"
        :empty-required-fields="emptyRequiredFieldsStatusAndContract"
        :in-edit-mode="sections.statusAndContract.editing"
        :is-open.sync="sections.statusAndContract.isOpen"
        :loading="loading"
        :is-new-profile="isNewProfile"
        :show-onboarding-edit-btn="isNewProfile && userPermissions.canEditRecords"
        @cancel="cancelEdits(sections.statusAndContract.sectionName)"
        @close="closeAccordion(sections.statusAndContract.sectionName)"
        @save="submitForm"
        @updateEditMode="updateEditMode"
        @saveAndContinue="
          submitAndContinue({
            currentSection: sections.statusAndContract.sectionName,
            nextSection: sections.statusAndContract.sectionName,
          })
        "
      >
        <template #title>
          <span class="accordion-heading">{{ content.accordionHeadings.statusAndContract }}</span>
        </template>
        <template v-if="userPermissions.canEditRecords" #message>
          <div class="accordion-message">
            <div
              v-if="profileEditsDisabled(sections.statusAndContract.sectionName)"
              class="lll-notification-error"
            >
              <p class="compact">
                {{ getProfileEditsDisabledMessage(sections.statusAndContract.sectionName) }}
              </p>
            </div>
            <div
              v-else-if="emptyRequiredFieldsStatusAndContract.length"
              class="lll-notification-error"
            >
              <p>
                <span>{{ content.accordionMessage.required }} </span>
                <span>( <abbr class="lll-text-red">*</abbr> ).</span>
              </p>
              <p class="compact">
                {{ content.accordionMessage.statusAndContract }}
              </p>
            </div>
          </div>
        </template>
        <template #panel>
          <amb-form
            :ref="sections.statusAndContract.formName"
            :default-values="sections.statusAndContract.formFieldsDefaultValues"
            :fields="sections.statusAndContract.formFields"
          >
            <profile-details-status-and-contract
              :ref="sections.statusAndContract.sectionDetailsRef"
              :ambassador="ambassador"
              :section="sections.statusAndContract"
              @edit="updateEditMode(true, sections.statusAndContract.sectionName)"
            />
          </amb-form>
        </template>
      </profile-details-accordion>
      <!-- ----- PERSONAL DETAILS SECTION ----- -->
      <profile-details-accordion
        :ref="sections.personal.sectionName"
        :section-name="sections.personal.sectionName"
        :edits-disabled="profileEditsDisabled(sections.personal.sectionName)"
        :empty-required-fields="emptyRequiredFieldsPersonal"
        :in-edit-mode="sections.personal.editing"
        :is-open.sync="sections.personal.isOpen"
        :loading="loading"
        :is-new-profile="isNewProfile"
        :has-only-min-fields="hasOnlyMinFields"
        :show-onboarding-edit-btn="isNewProfile && userPermissions.canEditRecords"
        @cancel="cancelEdits(sections.personal.sectionName)"
        @close="closeAccordion(sections.personal.sectionName)"
        @save="submitForm"
        @updateEditMode="updateEditMode"
        @saveAndContinue="
          submitAndContinue({
            currentSection: sections.personal.sectionName,
            nextSection: sections.public.sectionName,
          })
        "
      >
        <template #title>
          <span class="accordion-heading">{{ content.accordionHeadings.personal }}</span>
        </template>
        <template v-if="userPermissions.canEditRecords" #message>
          <div class="accordion-message">
            <div
              v-if="profileEditsDisabled(sections.personal.sectionName)"
              class="lll-notification-error"
            >
              <p class="compact">
                {{ getProfileEditsDisabledMessage(sections.personal.sectionName) }}
              </p>
            </div>
            <div
              v-else-if="profileEditsDisabled(sections.public.sectionName)"
              class="lll-notification-error"
            >
              <p class="compact">
                {{ content.accordionMessage.editsDisabled.published }}
                {{ fieldText.home_address.label }}.
              </p>
            </div>
            <div v-else-if="emptyRequiredFieldsPersonal.length" class="lll-notification-error">
              <p>
                <span>{{ content.accordionMessage.required }} </span>
                <span>( <abbr class="lll-text-red">*</abbr> ).</span>
              </p>
              <p class="compact">
                {{ content.accordionMessage.personal }}
              </p>
            </div>
          </div>
        </template>
        <template #panel>
          <amb-form
            :ref="sections.personal.formName"
            :default-values="sections.personal.formFieldsDefaultValues"
            :fields="sections.personal.formFields"
          >
            <profile-details-personal
              :ref="sections.personal.sectionDetailsRef"
              :ambassador="ambassador"
              :section="sections.personal"
              @edit="updateEditMode(true, sections.personal.sectionName)"
            />
          </amb-form>
        </template>
      </profile-details-accordion>
      <!-- ----- PUBLIC DETAILS SECTION ----- -->
      <profile-details-accordion
        :ref="sections.public.sectionName"
        :section-name="sections.public.sectionName"
        :edits-disabled="profileEditsDisabled(sections.public.sectionName)"
        :empty-required-fields="emptyRequiredFieldsPublic"
        :in-edit-mode="sections.public.editing"
        :is-open.sync="sections.public.isOpen"
        :loading="loading"
        :is-new-profile="isNewProfile"
        :has-only-min-fields="hasOnlyMinFields"
        @cancel="cancelEdits(sections.public.sectionName)"
        @close="closeAccordion(sections.public.sectionName)"
        @save="submitForm"
        @updateEditMode="updateEditMode"
        @saveAndContinue="
          submitAndContinue({
            currentSection: sections.public.sectionName,
            nextSection: sections.photos.sectionName,
          })
        "
      >
        <template #title>
          <span class="accordion-heading">{{ content.accordionHeadings.public }}</span>
        </template>
        <template v-if="userPermissions.canEditRecords" #message>
          <div class="accordion-message">
            <div
              v-if="profileEditsDisabled(sections.public.sectionName)"
              class="lll-notification-error"
            >
              <p class="compact">
                {{ getProfileEditsDisabledMessage(sections.public.sectionName) }}
              </p>
            </div>
            <div v-else-if="emptyRequiredFieldsPublic.length" class="lll-notification-error">
              <p>
                <span>{{ content.accordionMessage.required }} </span>
                <span>( <abbr class="lll-text-red">*</abbr> ).</span>
              </p>
              <p class="compact">
                {{ content.accordionMessage.public }}
              </p>
            </div>
          </div>
        </template>
        <template #panel>
          <amb-form
            :ref="sections.public.formName"
            :default-values="sections.public.formFieldsDefaultValues"
            :fields="sections.public.formFields"
          >
            <profile-details-public
              :ref="sections.public.sectionDetailsRef"
              :ambassador="ambassador"
              :section="sections.public"
              @edit="updateEditMode(true, sections.public.sectionName)"
            />
          </amb-form>
        </template>
      </profile-details-accordion>
      <!-- PHOTOS -->
      <profile-details-accordion
        :ref="sections.photos.sectionName"
        :section-name="sections.photos.sectionName"
        :empty-required-fields="emptyRequiredFieldsPhotos"
        :is-open.sync="sections.photos.isOpen"
        :loading="loading"
        :is-new-profile="isNewProfile"
        :has-only-min-fields="hasOnlyMinFields"
        custom-cta
        @close="closeAccordion(sections.photos.sectionName)"
      >
        <template #title>
          <span class="accordion-heading">{{ content.accordionHeadings.photos }}</span>
        </template>
        <template #ctaButton>
          <button
            v-show="userPermissions.canEditRecords"
            name="add-photos"
            type="button"
            class="btn btn-primary text-no-wrap"
            :disabled="profileEditsDisabled(sections.photos.sectionName)"
            @click="openGalleryUploader"
          >
            {{ accordionContent.photosButton }}
          </button>
        </template>
        <template v-if="userPermissions.canEditRecords" #message>
          <div class="accordion-message">
            <div
              v-if="profileEditsDisabled(sections.photos.sectionName)"
              class="lll-notification-error"
            >
              <p class="compact">
                {{ getProfileEditsDisabledMessage(sections.photos.sectionName) }}
              </p>
            </div>
            <div v-else-if="emptyRequiredFieldsPhotos.length" class="lll-notification-error">
              <p class="compact">
                {{ content.accordionMessage.photos }}
              </p>
            </div>
          </div>
        </template>
        <template #panel>
          <div class="lll-form-padding">
            <profile-details-photos
              :ref="sections.photos.sectionDetailsRef"
              :ambassador="ambassador"
              :edits-disabled="profileEditsDisabled(sections.photos.sectionName)"
            />
          </div>
        </template>
      </profile-details-accordion>
    </div>
    <!-- ^ .accordions -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import content from '@/content/staticContentForUI';
import { userCanEditThisPublishStatus } from '@/vuex/modules/auth';
import { customMessages } from '@/utils/errorHandlers';
import Ambassador from '@/models/v2/Ambassador';
import * as formHelpers from '@/utils/formHelpers';
import AMBForm from '@/components/AMBForm';
import ProfileDetailsAccordion from '@/components/ProfileDetailsAccordion';
import ProfileDetailsStatusAndContract from '@/components/ProfileDetailsStatusAndContract';
import ProfileDetailsPersonal from '@/components/ProfileDetailsPersonal';
import ProfileDetailsPhotos from '@/components/ProfileDetailsPhotos';
import ProfileDetailsPublic from '@/components/ProfileDetailsPublic';

/**
 * Define these commonly used strings as constants to avoid errors due to misspelling.
 * Also, if these names change for some reason, it's easier to update everywhere.
 * Form names are imported from formHelpers in order to use consistent labelling,
 * which ensures helper functions can work properly.
 */
const FORM_NAME_STATUS_AND_CONTRACT = formHelpers.STATUS_AND_CONTRACT_FORM;
const FORM_NAME_PERSONAL = formHelpers.PERSONAL_DETAILS_FORM;
const FORM_NAME_PUBLIC = formHelpers.PUBLIC_DETAILS_FORM;
const SECTION_NAME_STATUS_AND_CONTRACT = 'statusAndContract';
const SECTION_NAME_PERSONAL = 'personal';
const SECTION_NAME_PUBLIC = 'public';
const SECTION_NAME_PHOTOS = 'photos';

const STATUS_AND_CONTRACT_FORM_FIELDS_DEFAULT = formHelpers.getFormFields(
  'tags',
  FORM_NAME_STATUS_AND_CONTRACT
);
const PERSONAL_DETAILS_FORM_FIELDS_DEFAULT = formHelpers.getFormFields('tags', FORM_NAME_PERSONAL);
const PUBLIC_DETAILS_FORM_FIELDS_DEFAULT = formHelpers.getFormFields('tags', FORM_NAME_PUBLIC);

export default {
  name: 'ProfileDetailsMain',

  components: {
    'amb-form': AMBForm,
    'profile-details-accordion': ProfileDetailsAccordion,
    'profile-details-status-and-contract': ProfileDetailsStatusAndContract,
    'profile-details-personal': ProfileDetailsPersonal,
    'profile-details-photos': ProfileDetailsPhotos,
    'profile-details-public': ProfileDetailsPublic,
  },

  props: {
    ambassador: {
      required: true,
      default: () => {},
      type: Object,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    isNewProfile: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      content: content.profileDetailsMain,
      accordionContent: content.profileDetailsAccordion,
      fieldText: content.formFields,
      inputSelectionItems: content.inputSelectionItems,
      noDataText: content.noDataText,
      sections: {
        statusAndContract: {
          editing: formHelpers.hasSavedFormData(FORM_NAME_STATUS_AND_CONTRACT),
          editsDisabled: false,
          formFields: { ...STATUS_AND_CONTRACT_FORM_FIELDS_DEFAULT },
          formFieldsDefaultValues: { ...STATUS_AND_CONTRACT_FORM_FIELDS_DEFAULT },
          formName: FORM_NAME_STATUS_AND_CONTRACT,
          isOpen: formHelpers.hasSavedFormData(FORM_NAME_STATUS_AND_CONTRACT),
          sectionDetailsRef: 'profileDetailsStatusAndContract',
          sectionName: SECTION_NAME_STATUS_AND_CONTRACT,
        },
        personal: {
          editing: formHelpers.hasSavedFormData(FORM_NAME_PERSONAL),
          editsDisabled: false,
          formFields: { ...PERSONAL_DETAILS_FORM_FIELDS_DEFAULT },
          formFieldsDefaultValues: { ...PERSONAL_DETAILS_FORM_FIELDS_DEFAULT },
          formName: FORM_NAME_PERSONAL,
          isOpen: formHelpers.hasSavedFormData(FORM_NAME_PERSONAL),
          sectionDetailsRef: 'profileDetailsPersonal',
          sectionName: SECTION_NAME_PERSONAL,
        },
        public: {
          editing: formHelpers.hasSavedFormData(FORM_NAME_PUBLIC),
          editsDisabled: false,
          formFields: { ...PUBLIC_DETAILS_FORM_FIELDS_DEFAULT },
          formFieldsDefaultValues: { ...PUBLIC_DETAILS_FORM_FIELDS_DEFAULT },
          formName: FORM_NAME_PUBLIC,
          isOpen: formHelpers.hasSavedFormData(FORM_NAME_PUBLIC),
          sectionDetailsRef: 'profileDetailsPublic',
          sectionName: SECTION_NAME_PUBLIC,
        },
        photos: {
          editsDisabled: false,
          formFields: null,
          formName: null,
          isOpen: false,
          sectionDetailsRef: 'profileDetailsPhotos',
          sectionName: SECTION_NAME_PHOTOS,
        },
      },
    };
  },

  computed: {
    ...mapGetters([
      'emptyRequiredFields',
      'emptyRequiredFieldsTotal',
      'userClaims',
      'userPermissions',
    ]),

    allAccordionsExpanded() {
      let numberExpanded = 0;
      for (const key in this.sections) {
        if (this.sections[key].isOpen) {
          numberExpanded += 1;
        }
      }

      return numberExpanded === Object.keys(this.sections).length;
    },

    hasOnlyMinFields() {
      const fieldsForCreate = formHelpers.getFieldsForCreate();
      const fieldsForPublish = formHelpers.getFieldsForPublish();
      /**
       * This represents the number of fields that are saved when a new profile is created.
       * We must add 1 for 'end_date', because while this field is not on the create form
       * (not tagged as REQUIRED_TO_CREATE), it gets automatically filled when a new profile
       * is created. So, for the purposes of this function, 'end_date' is counted as one of
       * the minimum required fields.
       */
      const minRequiredFields = fieldsForCreate.length + 1;
      /**
       * This represents the number of fields required to publish a profile.
       */
      const totalRequiredFields = fieldsForPublish.length;

      if (totalRequiredFields - minRequiredFields === this.emptyRequiredFieldsTotal) {
        return true;
      }

      return false;
    },

    emptyRequiredFieldsStatusAndContract() {
      return this.getEmptyRequiredFields(this.sections.statusAndContract.sectionName);
    },

    emptyRequiredFieldsPersonal() {
      return this.getEmptyRequiredFields(this.sections.personal.sectionName);
    },

    emptyRequiredFieldsPublic() {
      return this.getEmptyRequiredFields(this.sections.public.sectionName);
    },

    emptyRequiredFieldsPhotos() {
      return this.getEmptyRequiredFields(this.sections.photos.sectionName);
    },

    formIsInProgress() {
      let inProgress = false;

      for (const sectionName in this.sections) {
        if (this.hasFormInProgress(sectionName)) {
          inProgress = true;
          break;
        }
      }

      return inProgress;
    },
  },

  methods: {
    ...mapActions(['updateCurrentAmbassador']),

    cancelEdits(sectionName) {
      const {
        heading,
        cancelEdits,
        cancelButton,
        confirmButton,
      } = this.content.unsavedChangesAlert;

      if (!this.hasFormInProgress(sectionName)) {
        this.updateEditMode(false, sectionName);
      } else {
        const basicAlertConfig = this.$createAlert(heading, `<p>${cancelEdits}</p>`);

        this.$swal({
          ...basicAlertConfig,
          showCancelButton: true,
          cancelButtonText: cancelButton,
          confirmButtonText: confirmButton,
        }).then(result => {
          if (result.value) {
            this.submitForm(sectionName);
          } else {
            // Cancel edit mode for this section.
            this.updateEditMode(false, sectionName);
          }
        });
      }
    },

    closeAccordion(sectionName) {
      this.sections[sectionName].isOpen = false;
    },

    collapseAccordions() {
      let openForms = 0;

      for (const sectionName in this.sections) {
        if (this.hasFormInProgress(sectionName)) {
          openForms += 1;
        }
      }

      if (!openForms) {
        // eslint-disable-next-line guard-for-in
        for (const sectionName in this.sections) {
          this.closeAccordion(sectionName);
        }
      } else {
        const basicAlertConfig = this.$createAlert(
          this.content.unsavedChangesAlert.heading,
          `<p>${this.content.unsavedChangesAlert.collapseAccordions}</p>`
        );

        this.$swal({
          ...basicAlertConfig,
          allowOutsideClick: true,
        });
      }
    },

    confirmRouteLeave(routeTo) {
      const formIsInProgress = this.formIsInProgress;

      if (formIsInProgress) {
        const basicConfig = this.$createErrorSnackbar(customMessages.formIncomplete);

        this.$swal({
          ...basicConfig,
          showCancelButton: true,
          cancelButtonText: content.formErrorSnackbar.backToForm,
          confirmButtonText: content.formErrorSnackbar.confirmButton,
        }).then(result => {
          if (result.value) {
            // Reset forms so they're not flagged as in-progress and sessionStorage is cleared.
            for (const sectionName in this.sections) {
              if (this.sections[sectionName].editing) {
                this.updateEditMode(false, sectionName);
              }
            }

            this.$router.push(routeTo.path).catch(error => {
              console.warn('vue-router error:', error);
            });
          }
        });
      } else {
        // Abort route change and stay on current page.
        this.$router.go(-1);
      }
    },

    expandAccordions() {
      // eslint-disable-next-line guard-for-in
      for (const sectionName in this.sections) {
        /**
         * Don't use 'this.openAccordion' method, because that triggers the 'scrollToTop' method
         * for each section, which would cause problems when user is trying to expand all.
         */
        this.sections[sectionName].isOpen = true;
      }

      this.$scrollTo(this.$el);
    },

    openAccordion(sectionName) {
      this.sections[sectionName].isOpen = true;

      this.$nextTick(() => {
        this.$refs[sectionName].scrollToTop();
      });
    },

    getEmptyRequiredFields(sectionName) {
      if (this.loading) {
        return [];
      }

      const { fieldIsEmpty, fieldIsRequiredToPublish } = formHelpers;
      let fieldsList;

      if (sectionName === this.sections.photos.sectionName) {
        fieldsList = ['profile_photo'];
      } else {
        fieldsList = Object.keys(this.sections[sectionName].formFields);
      }

      const emptyFields = fieldsList.filter(field => {
        return fieldIsEmpty(field, this.ambassador) && fieldIsRequiredToPublish(field);
      });

      this.$store.commit('updateEmptyRequiredFields', { section: sectionName, list: emptyFields });

      return emptyFields;
    },

    getProfileEditsDisabledMessage(sectionName) {
      const photos = this.content.accordionHeadings.photos;
      const guestFacingDetails = this.content.accordionHeadings.public;
      const homeAddress = this.fieldText.home_address;
      const {
        archived,
        published,
        inReview,
        generic,
      } = this.content.accordionMessage.editsDisabled;

      if (['archived'].includes(this.ambassador.publish_status)) {
        return archived;
      }

      if (['ready_for_review'].includes(this.ambassador.publish_status)) {
        if (sectionName === this.sections.personal.sectionName) {
          return `${inReview} ${homeAddress}.`;
        }

        if (sectionName === this.sections.public.sectionName) {
          return `${inReview} ${guestFacingDetails}.`;
        }

        if (sectionName === this.sections.photos.sectionName) {
          return `${inReview} ${photos}.`;
        }
      }

      if (['published', 'ready_to_publish'].includes(this.ambassador.publish_status)) {
        if (sectionName === this.sections.personal.sectionName) {
          return `${published} ${homeAddress}.`;
        }

        if (sectionName === this.sections.public.sectionName) {
          return `${published} ${guestFacingDetails}.`;
        }

        if (sectionName === this.sections.photos.sectionName) {
          return `${published} ${photos}.`;
        }
      }

      return generic;
    },

    getValidatedFormData(sectionName) {
      const { formName, sectionDetailsRef } = this.sections[sectionName];

      // If any section contains custom inputs (not Vuetify), manually validate them here.
      if (sectionName === this.sections.personal.sectionName) {
        if (!this.$refs[sectionDetailsRef].customFieldsAreValid()) {
          const basicConfig = this.$createErrorSnackbar(customMessages.formInvalid);
          // Show error message if field data is invalid.
          this.$swal({
            ...basicConfig,
            confirmButtonText: content.formErrorSnackbar.backToForm,
          });

          // If one or more custom inputs are invalid, no need to check the rest of the form.
          return {};
        }
      }

      // Vuetify components get validated with the validation methods on AMBForm component.
      const validFormData = this.$refs[formName].getValidatedFormData();

      return validFormData;
    },

    hasFormInProgress(sectionName) {
      const section = this.sections[sectionName];

      if (!section.editing || !section.formFields) {
        return false;
      }

      let formIsDirty = this.$refs[section.formName].isDirty();

      if (formHelpers.hasSavedFormData(section.formName)) {
        const formDataCurrentValues = this.$refs[section.formName].captureFormData();

        for (const key in formDataCurrentValues) {
          if (!isEqual(this.ambassador[key], formDataCurrentValues[key])) {
            formIsDirty = true;
            break;
          }
        }
      }

      return section.editing && formIsDirty;
    },

    openGalleryUploader() {
      this.$refs[this.sections.photos.sectionDetailsRef].openGalleryUploader();
    },

    profileEditsDisabled(sectionName) {
      if (this.ambassador && this.ambassador.publish_status) {
        const { publish_status } = this.ambassador;
        const userCanEdit = userCanEditThisPublishStatus(publish_status, this.userClaims);
        // const isDocsSection = sectionName === this.sections.documents.sectionName;

        // if (publish_status === 'archived') {
        //   // Documents section is allowed to be edited during all phases of publishing flow
        //   if (!isDocsSection) {
        //     this.sections[sectionName].editsDisabled = !userCanEdit;
        //   }

        //   return !isDocsSection && !userCanEdit;
        // }

        if (sectionName === this.sections.public.sectionName) {
          this.sections.public.editsDisabled = !userCanEdit;

          return !userCanEdit;
        }

        if (sectionName === this.sections.photos.sectionName) {
          this.sections.photos.editsDisabled = !userCanEdit;

          return !userCanEdit;
        }
      }

      return false;
    },

    populateFormFields(section) {
      const savedForm = formHelpers.getSavedFormData(section.formName);
      const fieldsList = Object.keys(section.formFields);

      fieldsList.forEach(field => {
        if (savedForm && savedForm.data[field]) {
          section.formFields[field] = savedForm.data[field];
        } else if (this.ambassador[field]) {
          section.formFields[field] = this.ambassador[field];
        }
      });
    },

    prepFormsInProgress() {
      // Check for forms in progress (e.g., AD session refreshed before form could be submitted).
      for (const sectionName in this.sections) {
        if (this.sections[sectionName].editing) {
          this.populateFormFields(this.sections[sectionName]);
        }
      }
    },

    prepEditForm(section) {
      this.populateFormFields(section);
      this.$refs[section.formName].setInitialValues();
    },

    resetForm(sectionName) {
      const section = this.sections[sectionName];

      if (!section.formFields) {
        return;
      }

      this.resetFormFields(sectionName);
      this.resetValidation(sectionName);

      formHelpers.removeSavedFormData(section.formName);
    },

    resetFormFields(sectionName) {
      const section = this.sections[sectionName];

      if (!section.formFields) {
        return;
      }

      Object.keys(section.formFields).forEach(field => {
        let formFieldValue = this.ambassador[field];

        // Avoid null/undefined form field values, since it breaks other components/functions.
        if (formFieldValue === null || formFieldValue === undefined) {
          formFieldValue = this.$refs[section.formName].formDataInitialValues[field];
        }

        section.formFields[field] = formFieldValue;
      });

      // Reset special fields in 'personal' section (e.g., birth_date, address blocks).
      if (sectionName === this.sections.personal.sectionName) {
        this.$refs[section.sectionDetailsRef].resetSpecialFields();
      }
    },

    resetValidation(sectionName) {
      const section = this.sections[sectionName];

      if (!section.formFields) {
        return;
      }

      // This section contains custom inputs (not Vuetify), so validation must be manually reset.
      if (sectionName === this.sections.personal.sectionName) {
        this.$refs[section.sectionDetailsRef].resetCustomValidation();
      }

      // Reset validation for all Vuetify inputs (call method on AMBForm).
      this.$refs[section.formName].resetValidation();
    },

    editNextSection(sectionNames = {}) {
      const { currentSection, nextSection } = sectionNames;

      this.closeAccordion(currentSection);
      this.openAccordion(nextSection);
    },

    saveFormsInProgress() {
      // eslint-disable-next-line guard-for-in
      for (const sectionName in this.sections) {
        const { formName } = this.sections[sectionName];

        if (this.hasFormInProgress(sectionName)) {
          formHelpers.saveFormInProgress(formName, this.$refs[formName].captureFormData());
        }
      }
    },

    submitAndContinue(sectionNames = {}) {
      this.submitForm(sectionNames.currentSection, () => this.editNextSection(sectionNames));
    },

    submitForm(sectionName, onboardingSuccessCallback = undefined) {
      const formData = this.getValidatedFormData(sectionName);

      // If formData is empty Object, that means one or more fields have invalid data.
      if (isEmpty(formData)) {
        return;
      }

      this.saveFormsInProgress();
      this.updateAmbassadorDetails(sectionName, formData, onboardingSuccessCallback);
    },

    updateAmbassadorDetails(sectionName, formData, onboardingSuccessCallback = undefined) {
      const formName = this.sections[sectionName].formName;
      const formDataFieldsList = Object.keys(formData);

      this.updateLoading(true);

      /**
       * This step allows the jsonapi-client library to populate the Ambassador model with correct
       * attribute values. If you simply assign all form field values to Ambassador
       * (e.g., this.ambassador[field] = formData[field]), the model you submit to 'save' method
       * will be missing relationship/type data needed for proper serialization.
       */
      const updatedAmbassador = new Ambassador(formData);

      formDataFieldsList.forEach(field => {
        this.ambassador[field] = updatedAmbassador[field];
      });

      this.ambassador
        .save()
        .then(ambassador => {
          /**
           * Reset 'state.ambassadorCountryIdSet' to an empty Set, so the items in 'Country' filter
           * in 'AmbassadorsListFilters' can be updated when the filters component is mounted again.
           */
          this.$store.commit('ambassadorCountryIdSet', new Set());
          /**
           * This async action makes API call to get latest ambassador data, populates attributes,
           * and updates 'currentAmbassador' in state so it's available to all components. The main
           * purpose of this is to set values for 'main_discipline' and 'store', otherwise they'll
           * be undefined (they're not attributes on initial model, so not delivered from service).
           */
          this.updateCurrentAmbassador(ambassador.id)
            .then(() => {
              this.updateLoading(false);
              this.sections[sectionName].editing = false;
              formHelpers.removeSavedFormData(formName);

              if (onboardingSuccessCallback) {
                onboardingSuccessCallback();
              }
            })
            .catch(error => {
              this.updateLoading(false);
              this.$handleErrorMessage(error);
            });
        })
        .catch(error => {
          // stop loading before calling this.$refs, otherwise $refs will be undefined
          this.updateLoading(false);

          // reset this.ambassador to initial values, since updates were not saved
          formDataFieldsList.forEach(field => {
            this.ambassador[field] = this.$refs[formName].formDataInitialValues[field];
          });

          this.$handleErrorMessage(error);
        });
    },

    updateEditMode(boolean, sectionName) {
      // if edit mode is turned on, ensure form fields are populated
      if (boolean === true) {
        this.prepEditForm(this.sections[sectionName]);
      } else {
        this.resetForm(sectionName);
      }

      this.sections[sectionName].editing = boolean;
    },

    updateLoading(boolean) {
      this.$emit('update:loading', boolean);
    },
  },
};
</script>

<style lang="scss" scoped>
.accordions {
  counter-reset: accordion-heading;
  .accordion-heading:before {
    counter-increment: accordion-heading;
    content: counter(accordion-heading) '.';
    color: $lll-color-red;
    margin-right: 10px;
  }

  section {
    margin: 0 0 10px 0;
  }
}

// matches right/left padding for '.lll-form-padding'
.accordion-message {
  padding: 0 5vw;

  @media (min-width: 700px) {
    padding: 0 50px;
  }
}

.grid-2-col-space-between {
  display: grid;
  justify-content: center;
  grid-template-columns: max-content;
  grid-gap: 25px;
  @media (min-width: 600px) {
    justify-content: space-between;
    grid-template-columns: max-content max-content;
  }
}
</style>
