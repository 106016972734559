/* eslint-disable prettier/prettier */
import i18next from 'i18next';
import { getPreferredLocale } from '@/utils/preferredLocale';

import en from '@/content/locales/en';
import ja from '@/content/locales/ja';
import ko from '@/content/locales/ko';
import zh_Hans from '@/content/locales/zh-Hans';
import zh_Hant from '@/content/locales/zh-Hant';
// import new locale files here and add them to 'resources' object below

function setupLocalizationPlugin() {
  // assign locale file to its corresponding language
  const locales = {
    'en': { translation: en },
    'ja': { translation: ja },
    'ko': { translation: ko },
    'zh': { translation: zh_Hans },
    'zh-Hans': { translation: zh_Hans },
    'zh-CN': { translation: zh_Hans },
    'zh-Hant': { translation: zh_Hant },
    'zh-TW': { translation: zh_Hant },
    'zh-HK': { translation: zh_Hant },
  };

  /**
   * Set 'fallbackLng', 'keySeparator', and 'nsSeparator' as false to handle natural language keys.
   * Set 'returnEmptyString' as false so missing values fallback to natural language key.
   * (e.g., extraction plugin adds new keys to locale JSON file, but no translations have been
   * added yet so value is empty string). Example: "Add Photos": "" => "Add Photos").
   *
   * https://www.i18next.com/overview/configuration-options
   */
  const options = {
    // debug: true,
    lng: 'en',
    fallbackLng: false,
    keySeparator: false,
    nsSeparator: false,
    returnEmptyString: false,
    resources: locales,
  };

  i18next.init(options);

  const localeDetails = getPreferredLocale();

  // change to user's preferred language (if not supported, default will be 'en')
  i18next.changeLanguage(localeDetails.languageCode);
}

// create alias for translation function
// extraction plugin will search for strings wrapped in t()
/* i18next-extract-disable-next-line */
function t(string) {
  /* i18next-extract-disable-next-line */
  return i18next.t(string);
}

export { setupLocalizationPlugin, t };
