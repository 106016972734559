import { SSC_STORE_INFO, SSC_STORE_TYPES } from '@/vuex/modules/ambassadorStore';
import { REQUIRED_TO_PUBLISH, REQUIRED_TO_CREATE } from '@/utils/formHelpers/tags';
import { FORM_FIELD_DETAILS } from '@/utils/formHelpers/ambassadorFormFieldDetails';

/**
 * Transforms attribute name to kebab-case. This is useful for generating a HTML input 'id'.
 * Attribute names from Ambassadors Service are snake_case.
 *
 * @param {String} attributeId - Name of attribute, which is a unique identifier that matches
 * the corresponding attribute on Ambassador model (e.g., 'first_name').
 * @return {String} - Name of attribute in kebab-case (e.g., 'first-name').
 *  */
function convertSnakeToKebab(attributeId) {
  return attributeId.replace(/_/g, '-');
}

/**
 * Checks if attribute data is saved with ambassador record (in Ambassadors Service).
 *
 * @param {String} attributeId - Name of attribute, which is a unique identifier that matches
 * the corresponding attribute on Ambassador model (e.g., 'first_name').
 * @param {Object} ambassadorData - Object created from Ambassador model with data from
 * Ambassadors Service (e.g., currentAmbassador).
 * @return {Boolean} - Confirms whether or not the field value is saved with Ambassador record.
 */
function fieldIsEmpty(attributeId, ambassadorData) {
  const attributeValue = ambassadorData[attributeId];

  /**
   * Since these attribute values are Objects, checking value of 'ambassadorData[attributeId]'
   * will evaluate to true, even if it's an empty Object. Instead, check value of 'id'.
   * Since 'main_discipline' and 'store' are not on the Ambassador model as delivered from
   * the service, confirm that these attributes exist before checking for 'id' (avoid error).
   */
  const attributesOfTypeObject = [
    'ambassador_status',
    'ambassador_type',
    'main_discipline',
    'sub_discipline',
    'store',
    'profile_photo',
  ];

  if (attributesOfTypeObject.includes(attributeId) && attributeValue) {
    return !attributeValue.id;
  }

  /**
   * Since these attribute values are Arrays, checking value of 'ambassadorData[attributeId]'
   * will evaluate to true, even if it's an empty Array. Instead, check value of Array.length.
   */
  const attributesOfTypeArray = ['experiences', 'product_categories'];

  if (attributesOfTypeArray.includes(attributeId)) {
    return !attributeValue.length;
  }

  const emptyValues = [null, '', undefined, false];

  return emptyValues.includes(attributeValue);
}

/**
 * Checks if a field is listed as REQUIRED_TO_PUBLISH in FORM_FIELD_DETAILS. These fields must
 * be filled out before ambassador record can be moved past 'draft' in the publishing pipeline.
 *
 * @param {String} attributeId - Name of attribute, which is a unique identifier that matches
 * the corresponding attribute on Ambassador model (e.g., 'first_name').
 * @return {Boolean} - Confirms whether or not the field is required.
 */
function fieldIsRequiredToPublish(attributeId) {
  const field = FORM_FIELD_DETAILS.find(item => item.attributeId === attributeId);

  if (!field) {
    console.warn(
      `Cannot find field with attribute id '${attributeId}'. Check spelling, confirm this field exists in FORM_FIELD_DETAILS, and ensure all entries match recommended spec.`
    );
    return false;
  }

  return field.tags.includes(REQUIRED_TO_PUBLISH);
}

/**
 * Creates a filtered list of fields with their properties from FORM_FIELD_DETAILS.
 *
 * @param {String} propertyName - Name of property to filter by (e.g., 'tags').
 * @param {Any} propertyValue - Corresponding value to filter by (e.g., PUBLIC_DETAILS_FORM).
 * @return {Array of Objects} - List containing field details. Example of return value:
 *  [{
 *    attributeId: 'first_name',
 *    fieldText: fieldText.first_name,
 *    tags: [PROFILE_CREATE_FORM, PUBLIC_DETAILS_FORM, REQUIRED_TO_PUBLISH],
 *    validation: [required],
 *  }]
 */
function filterFieldDetailsByProperty(propertyName, propertyValue) {
  const filteredList = [];

  FORM_FIELD_DETAILS.forEach(fieldObj => {
    const existingPropValue = fieldObj[propertyName];

    if (!existingPropValue) {
      // if 'propertyName' is not one of the top level props, check if it exists in 'fieldText'
      const fieldText = fieldObj['fieldText'];
      // 'fieldText' is an Object with values of type String
      if (propertyName in fieldText) {
        if (fieldText[propertyName].includes(propertyValue)) {
          filteredList.push(fieldObj);
        }
      }
    } else if (existingPropValue.includes(propertyValue)) {
      filteredList.push(fieldObj);
    }
  });

  if (!filteredList.length) {
    console.warn(
      `The property '${propertyName}: ${propertyValue}' does not exist on any fields. Check spelling, confirm this field exists in FORM_FIELD_DETAILS, and ensure all entries match recommended spec.`
    );
  }

  return filteredList;
}

// TODO: NOTICKET - Add documentation for these filter functions

function filterItemsForStoreField(userClaims, ambassadorTypeId, storesList) {
  if (!userClaims) {
    return [];
  }

  const storeIdSSC = SSC_STORE_INFO.id;
  let filterFn;

  /**
   * If the ambassador type is in the list of SSC types,
   * remove all options other than the SSC store.
   */
  if (SSC_STORE_TYPES.includes(ambassadorTypeId)) {
    /**
     * Only need to filter by SSC store ID here, since only users
     * with access to the SSC store types can see this.
     */
    filterFn = item => {
      return item.id === storeIdSSC;
    };
  } else {
    // Filter out the SSC store and then any stores that are not in the claims set.
    filterFn = item => {
      if (item.id === storeIdSSC) {
        return false;
      }
      if (userClaims === '*') {
        return true;
      }
      return userClaims.has(item.id);
    };
  }

  return storesList.filter(filterFn);
}

function filterItemsForTypeField(userClaims, typesList) {
  if (userClaims === '*') {
    return typesList;
  }

  return typesList.filter(item => {
    if (SSC_STORE_TYPES.includes(item.id)) {
      return false;
    }
    return true;
  });
}

/**
 * Creates a registry of fields with their properties from FORM_FIELD_DETAILS.
 *
 * @param {String} propertyName - Name of property to filter by.
 * @param {Any} propertyValue - Corresponding value to filter by.
 * @return {Object} - Each key is the 'attributeId' from FORM_FIELD_DETAILS, and each value is
 * an Object containing the field details.
 */
function getFieldDetails(propertyName, propertyValue) {
  const fieldsList = filterFieldDetailsByProperty(propertyName, propertyValue);
  const fieldRegistry = {};

  for (const field of fieldsList) {
    fieldRegistry[field.attributeId] = field;
  }

  return fieldRegistry;
}

/**
 * Creates a list of field that are tagged as REQUIRED_TO_CREATE.
 * @return {Array} - List of field names (attribute IDs). e.g.: ['first_name', 'last_name', 'email']
 */
function getFieldsForCreate() {
  return filterFieldDetailsByProperty('tags', REQUIRED_TO_CREATE).map(item => item.attributeId);
}

/**
 * Creates a list of fields that are tagged as REQUIRED_TO_PUBLISH.
 * @return {Array} - List of field names (attribute IDs). e.g.: ['store', 'ambassador_type']
 */
function getFieldsForPublish() {
  return filterFieldDetailsByProperty('tags', REQUIRED_TO_PUBLISH).map(item => item.attributeId);
}

/**
 * Creates a dictionary of form fields with default value set according to FORM_FIELD_DETAILS.
 * This is useful for generating the fields needed for each form component's data object.
 * Field names must match attribute name on Ambassador model in order for the
 * jsonapi-client library to properly handle form data for saving/updating records.
 *
 * @param {String} propertyName - Name of property to filter by.
 * @param {Any} propertyValue - Corresponding value to filter by.
 * @return {Object} - Attribute IDs and default values for form fields. Form component will use
 * each key name (attributeId) as the 'v-model' for each form field.
 */
function getFormFields(propertyName, propertyValue) {
  const fieldsList = filterFieldDetailsByProperty(propertyName, propertyValue);
  const fields = {};

  for (const field of fieldsList) {
    fields[field.attributeId] = field.defaultValue;
  }

  return fields;
}

/**
 * Calculates percentage that describes how many fields listed as REQUIRED_TO_PUBLISH are complete.
 * "Complete" means the attribute value is saved to the Ambassador record in the service.
 * "Empty" means the attribute value is not saved yet.
 *
 * @param {Number} totalEmptyFields - Number of empty fields, including optional fields.
 * @return {Number} - Percentage of completed requred fields.
 */
function requiredFieldsPercentComplete(totalEmptyFields) {
  const requiredFieldsList = [];

  // eslint-disable-next-line guard-for-in
  for (const field in FORM_FIELD_DETAILS) {
    const attributeId = FORM_FIELD_DETAILS[field].attributeId;

    if (fieldIsRequiredToPublish(attributeId)) {
      requiredFieldsList.push(attributeId);
    }
  }

  const totalRequiredFields = requiredFieldsList.length;

  const completeFields = totalRequiredFields - totalEmptyFields;

  return (completeFields * 100) / totalRequiredFields;
}

// TODO: NOTICKET - Add documentation for these functions related to auto-save feature

function hasSavedFormData(formName) {
  if (!getSavedFormData(formName)) {
    return false;
  }

  return true;
}

function getSavedFormData(formName) {
  const formsInProgress = sessionStorage.getItem('formsInProgress');

  if (!formsInProgress) {
    return '';
  }

  const formsList = JSON.parse(formsInProgress);
  return formsList.find(form => form.name === formName);
}

function removeSavedFormData(formName) {
  const formsInProgress = sessionStorage.getItem('formsInProgress');

  if (!formsInProgress) {
    return;
  }

  const formsList = JSON.parse(formsInProgress);
  if (formsList.length === 1 && formsList[0].name === formName) {
    sessionStorage.removeItem('formsInProgress');
    return;
  }

  const updatedFormsInProgress = formsList.filter(form => form.name !== formName);
  sessionStorage.setItem('formsInProgress', JSON.stringify(updatedFormsInProgress));
}

function saveFormInProgress(formName, formData) {
  const currentForm = {
    name: formName,
    data: formData,
  };

  const formsInProgress = sessionStorage.getItem('formsInProgress');

  if (!formsInProgress) {
    sessionStorage.setItem('formsInProgress', JSON.stringify([currentForm]));
  } else {
    const savedForms = JSON.parse(formsInProgress);
    // check if currentForm.name is in savedForms and remove it
    const listWithoutCurrentForm = savedForms.filter(form => form.name !== currentForm.name);
    const savedFormsUpdated = listWithoutCurrentForm.concat(currentForm);

    sessionStorage.setItem('formsInProgress', JSON.stringify(savedFormsUpdated));
  }
}

export {
  convertSnakeToKebab,
  fieldIsEmpty,
  fieldIsRequiredToPublish,
  filterFieldDetailsByProperty,
  filterItemsForStoreField,
  filterItemsForTypeField,
  hasSavedFormData,
  getFormFields,
  getFieldDetails,
  getFieldsForCreate,
  getFieldsForPublish,
  getSavedFormData,
  removeSavedFormData,
  requiredFieldsPercentComplete,
  saveFormInProgress,
};
