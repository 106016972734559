<template>
  <div class="pagination">
    <button
      class="btn-prev btn btn-primary text-no-wrap"
      type="button"
      :name="buttonNames.previous"
      :disabled="loading || disableButton(buttonNames.previous)"
      @click="handlePagination(buttonNames.previous)"
    >
      <span class="chevron-right mirrored" />
      <span class="btn-label">{{ content.previousButton }}</span>
    </button>
    <button
      class="btn-first btn text-no-wrap"
      type="button"
      :name="buttonNames.first"
      :disabled="loading"
      @click="handlePagination(buttonNames.first)"
    >
      <span class="chevron-right mirrored" />
      <span class="chevron-right mirrored" />
      <span class="btn-label">{{ content.firstButton }}</span>
    </button>
    <button
      class="btn-last btn text-no-wrap"
      type="button"
      :name="buttonNames.last"
      :disabled="loading"
      @click="handlePagination(buttonNames.last)"
    >
      <span class="btn-label">{{ content.lastButton }}</span>
      <span class="chevron-right" />
      <span class="chevron-right" />
    </button>
    <button
      class="btn-next btn btn-primary text-no-wrap"
      type="button"
      :name="buttonNames.next"
      :disabled="loading || disableButton(buttonNames.next)"
      @click="handlePagination(buttonNames.next)"
    >
      <span class="btn-label">{{ content.nextButton }}</span>
      <span class="chevron-right" />
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import content from '@/content/staticContentForUI';

const queryString = require('query-string');

export default {
  name: 'AmbassadorsListPagination',

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      buttonNames: {
        previous: 'prev',
        next: 'next',
        first: 'first',
        last: 'last',
      },
      content: content.ambassadorsListPagination,
    };
  },

  computed: {
    ...mapGetters(['ambassadorsList', 'paginationButtonStates']),
  },

  methods: {
    disableButton(buttonName) {
      return !this.paginationButtonStates[buttonName];
    },

    handlePagination(buttonName) {
      const parsed = queryString.parseUrl(this.ambassadorsList.links[buttonName]);
      const updatedPagination = {
        page_number: parsed.query.page_number,
        page_size: parsed.query.page_size,
      };

      this.updatePagination(updatedPagination);
    },

    updatePagination(updatedPagination) {
      this.$store.commit('pagination', updatedPagination);
      this.$emit('updatePagination');
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  display: grid;
  grid-row-gap: 15px;
  grid-template:
    'prev . next'
    'first . last' / min-content 1fr min-content;

  @media (min-width: 600px) {
    grid-gap: 20px;
    grid-template:
      'prev first . last next' /
      min-content min-content 1fr min-content min-content;
  }
}

.btn-prev {
  grid-area: prev;
}

.btn-next {
  grid-area: next;
}

.btn-first {
  grid-area: first;
}

.btn-last {
  grid-area: last;
}

.btn-label {
  margin: 0 0.5em;
}

.chevron-right {
  &::before {
    border-style: solid;
    border-width: 2px 2px 0 0;
    content: '';
    display: inline-block;
    height: 0.5rem;
    transform: rotate(45deg);
    vertical-align: middle;
    width: 0.5rem;
  }
  &.mirrored {
    &:before {
      transform: rotate(225deg);
    }
  }
}
</style>
