import { customMessages } from '@/utils/errorHandlers';

export default {
  methods: {
    /**
     * Uses VueSweetalert2 library to display front-end error messages
     * that user can interact with.
     *
     * For 401 errors:
     *   Interrupts app activity to display dialog with 'Session Expired'
     *   message. Clicking the confirm button calls function to
     *   refresh the session and dismiss the dialog.
     *
     * For other errors:
     *   Displays a snackbar at the bottom of the page with message and
     *   a button to dismiss the snackbar.
     *
     * @param {object} error
     *  This is either an Error instance or a custom object
     *  returned from 'formatResponseError()' in 'errorHandlers.js'.
     *  Example of custom object:
     *    {
     *      'isFormatted': true,
     *      'function': refreshAuthToken,
     *      'customMessage': 'Your Active Directory session has expired.',
     *      'originalMessage': 'Request failed with status code 401',
     *      'status': 401,
     *    }
     */
    $handleErrorMessage(error) {
      let confirmBtnFunction = null;
      let message = customMessages.unknownError;
      let details = error.message;
      let status = '';

      if (error.isFormatted) {
        confirmBtnFunction = error.function;
        message = error.customMessage;
        details = error.originalMessage;
        status = error.status;
      }

      if (status === 401) {
        const basicAlertConfig = this.$createAlert(
          customMessages.sessionExpired.alertHeading,
          message
        );
        this.$swal({
          ...basicAlertConfig,
          allowEscapeKey: false,
          allowOutsideClick: false,
        }).then(result => {
          if (result.value) {
            confirmBtnFunction();
          }
        });
      } else {
        const basicSnackbarConfig = this.$createErrorSnackbar(message, details);

        this.$swal({
          ...basicSnackbarConfig,
        });
      }
    },

    $createAlert(heading, message) {
      return {
        html: `<h1 class="lll-header">${heading}</h1><div class="lll-alert h-secondary">${message}</div>`,
        buttonsStyling: false,
        customClass: {
          confirmButton: 'lll-alert btn btn-primary',
          cancelButton: 'lll-alert btn',
        },
        padding: '0',
      };
    },

    $createErrorSnackbar(message, details = '') {
      return {
        backdrop: false,
        allowOutsideClick: false,
        position: 'bottom',
        width: '40rem',
        html: `<div class="lll-snackbar"><p>${message}</p><p class="font-italic">${details}</p></div>`,
        buttonsStyling: false,
        padding: '25px',
        background: '#D31334',
        customClass: {
          actions: 'lll-snackbar',
          confirmButton: 'lll-snackbar btn btn-ghost',
          cancelButton: 'lll-snackbar btn btn-ghost ml-3',
        },
      };
    },
  },
};
