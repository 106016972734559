<template>
  <div class="lll-form-layout">
    <h1 class="h-page-title">
      {{ content.mainHeading }}
    </h1>
    <div class="lll-form-container">
      <amb-form
        :ref="formName"
        :default-values="formFieldsDefaultValues"
        :fields="formFields"
        class="lll-bg-white"
      >
        <div :class="loading ? 'lll-disabled lll-flash' : ''">
          <p class="row">
            <abbr title="required" class="lll-asterisk">*</abbr>
            <span class="h-label">{{ content.required }}</span>
          </p>
          <section class="grid-2-col-responsive">
            <div class="row mb-3">
              <amb-form-field-label attribute-id="profile_locale" show-asterisk />
              <v-select
                :id="getFieldId('profile_locale')"
                v-model="formFields.profile_locale"
                :rules="formFieldDetails.profile_locale.validation"
                validate-on-blur
                :items="ambassadorLocales"
                item-text="label"
                item-value="id"
                :no-data-text="noDataText.default"
                return-object
                hide-selected
                solo
                flat
                :placeholder="formFieldDetails.profile_locale.fieldText.placeholder"
                :hint="formFieldDetails.profile_locale.fieldText.hint"
                persistent-hint
                menu-props="offset-y"
              />
            </div>
            <div>
              <amb-form-field-label attribute-id="first_name" show-asterisk />
              <v-text-field
                :id="getFieldId('first_name')"
                v-model.trim="formFields.first_name"
                :rules="formFieldDetails.first_name.validation"
                validate-on-blur
                browser-autocomplete="off"
                solo
                flat
              />
            </div>
            <div>
              <amb-form-field-label attribute-id="last_name" show-asterisk />
              <v-text-field
                :id="getFieldId('last_name')"
                v-model.trim="formFields.last_name"
                :rules="formFieldDetails.last_name.validation"
                validate-on-blur
                browser-autocomplete="off"
                solo
                flat
              />
            </div>
            <div>
              <amb-form-field-label attribute-id="ambassador_type" show-asterisk />
              <v-select
                :id="getFieldId('ambassador_type')"
                v-model="formFields.ambassador_type"
                :rules="formFieldDetails.ambassador_type.validation"
                validate-on-blur
                :items="itemsAmbassadorType"
                item-text="label"
                item-value="id"
                :no-data-text="noDataText.default"
                return-object
                solo
                flat
                :placeholder="formFieldDetails.ambassador_type.fieldText.placeholder"
                menu-props="offset-y"
              />
            </div>
            <div>
              <amb-form-field-label attribute-id="store_id" show-asterisk />
              <v-autocomplete
                :id="getFieldId('store_id')"
                v-model="formFields.store_id"
                browser-autocomplete="off"
                :rules="formFieldDetails.store_id.validation"
                validate-on-blur
                :items="itemsAmbassadorStore"
                item-text="name"
                item-value="id"
                :no-data-text="noDataText.default"
                solo
                flat
                :placeholder="formFieldDetails.store_id.fieldText.placeholder"
                prepend-inner-icon="search"
                menu-props="offset-y"
              />
            </div>
            <div>
              <amb-form-field-label attribute-id="email" show-asterisk />
              <v-text-field
                :id="getFieldId('email')"
                v-model.trim="formFields.email"
                :rules="formFieldDetails.email.validation"
                validate-on-blur
                browser-autocomplete="off"
                solo
                flat
                :hint="formFieldDetails.email.fieldText.hint"
                persistent-hint
              />
            </div>
            <div>
              <amb-form-field-label attribute-id="start_date" show-asterisk />
              <v-menu
                v-model="menuStartDatePicker"
                :close-on-content-click="false"
                full-width
                lazy
                max-width="300px"
              >
                <v-text-field
                  :id="getFieldId('start_date')"
                  slot="activator"
                  v-model="formFields.start_date"
                  :rules="formFieldDetails.start_date.validation"
                  solo
                  flat
                  readonly
                  :placeholder="formFieldDetails.start_date.fieldText.placeholder"
                  append-icon="arrow_drop_down"
                />
                <v-date-picker
                  ref="startDatePicker"
                  v-model="formFields.start_date"
                  full-width
                  no-title
                  show-current="false"
                  color="primary"
                  @input="menuStartDatePicker = false"
                />
              </v-menu>
            </div>
          </section>
          <div class="row btn-group-2-col">
            <button
              name="create"
              class="btn btn-primary"
              :disabled="loading"
              @click.prevent="submitForm"
            >
              {{ content.createButton }}
            </button>
            <button name="cancel" class="btn" :disabled="loading" @click.prevent="cancelSubmit">
              {{ content.cancelButton }}
            </button>
          </div>
        </div>
      </amb-form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import content from '@/content/staticContentForUI';
import * as formHelpers from '@/utils/formHelpers';
import Ambassador from '@/models/v2/Ambassador';
import { customMessages } from '@/utils/errorHandlers';
import AMBForm from '@/components/AMBForm';
import AMBFormFieldLabel from '@/components/AMBFormFieldLabel';
import { getPreferredLocale } from '@/utils/preferredLocale';

/**
 * Form name is imported from formHelpers in order to use consistent labelling,
 * which ensures helper functions can work properly.
 */
const FORM_NAME = formHelpers.PROFILE_CREATE_FORM;
const PROFILE_CREATE_FORM_FIELDS_DEFAULT = formHelpers.getFormFields('tags', FORM_NAME);

export default {
  name: 'ProfileCreate',

  components: {
    'amb-form': AMBForm,
    'amb-form-field-label': AMBFormFieldLabel,
  },

  beforeRouteLeave(routeTo, routeFrom, next) {
    if (this.$refs[this.formName].isDirty() && !this.allowRouteChange) {
      // trigger process to show error message for unsaved changes
      this.cancelSubmit(routeTo);
      next(false);
    } else {
      next();
    }
  },

  data() {
    return {
      allowRouteChange: false,
      content: content.profileCreate,
      formFieldDetails: formHelpers.getFieldDetails('tags', FORM_NAME),
      formFields: { ...PROFILE_CREATE_FORM_FIELDS_DEFAULT },
      formFieldsDefaultValues: { ...PROFILE_CREATE_FORM_FIELDS_DEFAULT },
      formName: FORM_NAME,
      loading: false,
      menuStartDatePicker: false,
      noDataText: content.noDataText,
      validForm: false,
    };
  },

  computed: {
    ...mapGetters(['ambassadorLocales', 'ambassadorTypes', 'storesList', 'userClaims']),

    itemsAmbassadorStore() {
      return formHelpers.filterItemsForStoreField(
        this.userClaims.ambassador_create,
        this.formFields.ambassador_type.id,
        this.storesList
      );
    },

    itemsAmbassadorType() {
      return formHelpers.filterItemsForTypeField(
        this.userClaims.ambassador_create,
        this.ambassadorTypes
      );
    },
  },

  watch: {
    itemsAmbassadorStore(newValue) {
      /**
       * When 'Ambassador Type' changes, the selection list for
       * 'Ambassador Store' will change. This watcher resets the
       * 'Ambassador Store' field so the user will be prompted to
       * select a new 'Ambassador Store'.
       */
      const storeItems = newValue;
      let found = false;

      for (const item of storeItems) {
        if (item.id === this.formFields.store_id) {
          found = true;
          break;
        }
      }

      if (!found) {
        this.formFields.store_id = '';
      }
    },
  },

  created() {
    this.prepCreateForm();
  },

  methods: {
    ...mapActions([
      'getAmbassadorLocales',
      'getAmbassadorTypes',
      'getStoresList',
      'updateCurrentAmbassador',
    ]),

    cancelSubmit(routeTo) {
      if (this.$refs[this.formName].isDirty()) {
        const basicConfig = this.$createErrorSnackbar(customMessages.formIncomplete);

        this.$swal({
          ...basicConfig,
          showCancelButton: true,
          cancelButtonText: content.formErrorSnackbar.backToForm,
          confirmButtonText: content.formErrorSnackbar.confirmButton,
        }).then(result => {
          if (result.value) {
            this.forceCancelSubmit(routeTo);
          }
        });
      } else {
        this.$router.go(-1);
      }
    },

    forceCancelSubmit(routeTo) {
      this.resetFormFields();
      this.$refs[this.formName].resetValidation();
      formHelpers.removeSavedFormData(this.formName);
      this.allowRouteChange = true;

      if (routeTo.name) {
        // handle click on router-link
        this.$router.push(routeTo.path).catch(error => {
          console.warn('vue-router error:', error);
        });
      } else {
        // handle click on 'Cancel' button, in which case,
        // there is no route object to pass in
        this.$router.go(-1);
      }
    },

    getFieldId(attributeId) {
      return formHelpers.convertSnakeToKebab(attributeId);
    },

    getValidatedFormData() {
      return this.$refs[this.formName].getValidatedFormData();
    },

    populateSavedFormData() {
      const savedForm = formHelpers.getSavedFormData(this.formName);

      if (!savedForm) {
        return;
      }

      Object.keys(savedForm.data).forEach(field => {
        this.formFields[field] = savedForm.data[field];
      });
    },

    prepCreateForm() {
      this.loading = true;

      /**
       * Dispatch async actions to get selection items for form fields (drop-down lists) from
       * Ambassadors Service (API call not made if data already saved in state). Ensure these
       * items are loaded BEFORE the form fields are populated to avoid empty fields or errors.
       */
      const stores = this.getStoresList();
      const types = this.getAmbassadorTypes();
      const locales = this.getAmbassadorLocales();

      Promise.all([stores, types, locales])
        .then(() => {
          // Set initial values so we can check later if form is 'dirty'.
          this.$refs[this.formName].setInitialValues();

          // Check for saved form data (e.g., if AD session expired and app refreshed).
          this.populateSavedFormData();

          // Set default value of profile_locale (user can change it).
          if (!this.formFields.profile_locale) {
            const preferredLocale = getPreferredLocale();
            this.formFields.profile_locale = this.ambassadorLocales.find(
              item => item.id === preferredLocale.localeId
            );
          }

          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          this.$handleErrorMessage(error);
        });
    },

    resetFormFields() {
      Object.keys(this.formFields).forEach(field => {
        this.formFields[field] = this.$refs[this.formName].formDataInitialValues[field];
      });
    },

    saveAmbassador(formData) {
      this.loading = true;

      const newAmbassador = new Ambassador(formData);

      newAmbassador
        .save()
        .then(ambassador => {
          /**
           * Reset state.ambassadorCountryIdSet to an empty Set,
           * so the items in 'Country' filter can be updated
           * when the filters component is mounted again.
           */
          this.$store.commit('ambassadorCountryIdSet', new Set());

          // Make API call, populate attributes, and update currentAmbassador in state.
          this.updateCurrentAmbassador(ambassador.id)
            .then(() => {
              this.allowRouteChange = true;
              this.loading = false;
              formHelpers.removeSavedFormData(this.formName);
              this.$router
                .push({
                  name: 'ambassadorProfile',
                  params: { id: ambassador.id, isNewProfile: true },
                })
                .catch(error => {
                  console.warn('vue-router error:', error);
                });
            })
            .catch(error => {
              this.loading = false;
              this.$handleErrorMessage(error);
            });
        })
        .catch(error => {
          this.loading = false;
          this.$handleErrorMessage(error);
        });
    },

    submitForm() {
      const formData = this.getValidatedFormData();

      if (isEmpty(formData)) {
        return;
      }

      formHelpers.saveFormInProgress(this.formName, formData);
      this.saveAmbassador(formData);
    },
  },
};
</script>
