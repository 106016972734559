<template>
  <div class="grid-3-col-responsive break-at-1200">
    <!-- CONTACT INFORMATION -->
    <h2 class="h-primary section-header top row">
      {{ content.formSectionHeaders.contactInfo }}
    </h2>
    <profile-details-form-field
      :show-form-input="showFormInput('email')"
      :section="section"
      field-type="text"
      attribute-id="email"
      :display-data="ambassador.email"
      @edit="enableEdit"
    />
    <profile-details-phone
      ref="phonePrimary"
      :show-form-input="showFormInput('phone')"
      :ambassador="ambassador"
      attribute-id="phone"
      :section="section"
      @edit="enableEdit"
    />
    <profile-details-form-field
      :show-form-input="showFormInput('studio_business_name')"
      :section="section"
      attribute-id="studio_business_name"
      field-type="text"
      :display-data="ambassador.studio_business_name"
      @edit="enableEdit"
    />
    <profile-details-address-block
      ref="addressPrimary"
      address-type="home_address"
      :ambassador="ambassador"
      :section="section"
      :edits-disabled="homeAddressEditsDisabled"
      @edit="enableEdit"
    />
    <profile-details-form-field
      :show-form-input="showFormInput('contact_email')"
      :section="section"
      field-type="text"
      attribute-id="contact_email"
      :display-data="ambassador.contact_email"
      @edit="enableEdit"
    />
    <profile-details-phone
      ref="phoneEmergency"
      :show-form-input="showFormInput('contact_phone')"
      :ambassador="ambassador"
      attribute-id="contact_phone"
      :section="section"
      @edit="enableEdit"
    />
    <profile-details-form-field
      :show-form-input="showFormInput('contact_name')"
      :section="section"
      field-type="text"
      attribute-id="contact_name"
      :display-data="ambassador.contact_name"
      @edit="enableEdit"
    />
    <!-- emergency_address fields are temporarily hidden. See https://lululemon.atlassian.net/browse/COCO-3291 -->
    <!-- <profile-details-address-block
      ref="addressEmergency"
      address-type="emergency_address"
      :ambassador="ambassador"
      :section="section"
      @edit="enableEdit"
    /> -->
    <!-- PERSONAL INFORMATION -->
    <h2 class="h-primary section-header row">
      {{ content.formSectionHeaders.personalInfo }}
    </h2>
    <profile-details-form-field
      :show-form-input="showFormInput('gender')"
      :section="section"
      field-type="select"
      attribute-id="gender"
      :display-data="getItemLabel('genders', 'gender')"
      :items="inputSelectionItems.genders"
      @edit="enableEdit"
    />
    <profile-details-form-field
      :show-form-input="showFormInput('language')"
      :section="section"
      field-type="autocomplete"
      attribute-id="language"
      :display-data="getItemLabel('languages', 'language')"
      :items="inputSelectionItems.languages"
      :no-data-text="noDataText.default"
      @edit="enableEdit"
    />
    <profile-details-form-field
      :disable-edit-button="!showFormInput('birth_date')"
      :show-form-input="showFormInput('birth_date')"
      :section="section"
      attribute-id="birth_date"
      custom-display-data
      @edit="enableEdit"
    >
      <template #input>
        <v-menu
          v-if="showBirthdateField"
          v-model="menuBirthdatePicker"
          :close-on-content-click="false"
          full-width
          lazy
          max-width="300px"
        >
          <v-text-field
            id="update-birth-date"
            slot="activator"
            v-model="section.formFields.birth_date"
            :rules="[validationRules.birth_date]"
            solo
            flat
            readonly
            :placeholder="fieldText.birth_date.placeholder"
            append-icon="arrow_drop_down"
          />
          <v-date-picker
            ref="birthdatePicker"
            v-model="section.formFields.birth_date"
            full-width
            no-title
            show-current="false"
            :max="new Date().toISOString().substr(0, 10)"
            min="1940-01-01"
            @input="menuBirthdatePicker = false"
          />
        </v-menu>
        <div v-else class="lll-form-field-display-data lll-form-field-bottom-margin">
          <p class="h-label">
            {{ fieldText.birth_date.note }}
            <button
              type="button"
              class="lll-link text-xs-left"
              :disabled="!section.editing"
              @click.prevent="editBirthdate"
            >
              {{ fieldText.birth_date.noteCTA }}
            </button>
          </p>
        </div>
      </template>
      <template #display-data>
        <amb-pending-label
          v-if="fieldIsEmpty('birth_date') && !userPermissions.canEditRecords"
          class="lll-form-field-display-data"
        />
        <p v-else class="h-label">
          {{ fieldText.birth_date.note }}
          <button
            v-show="userPermissions.canEditRecords"
            type="button"
            class="lll-link text-xs-left"
            :disabled="!section.editing"
            @click.prevent="editBirthdate"
          >
            {{ fieldText.birth_date.noteCTA }}
          </button>
        </p>
      </template>
    </profile-details-form-field>
    <profile-details-form-field
      :show-form-input="showFormInput('dietary_restrictions')"
      :section="section"
      field-type="text"
      attribute-id="dietary_restrictions"
      :display-data="ambassador.dietary_restrictions"
      @edit="enableEdit"
    />
    <profile-details-form-field
      :show-form-input="showFormInput('top_size')"
      :section="section"
      field-type="select"
      attribute-id="top_size"
      :display-data="getItemLabel('apparelTopSizes', 'top_size')"
      :items="inputSelectionItems.apparelTopSizes"
      @edit="enableEdit"
    />
    <profile-details-form-field
      :show-form-input="showFormInput('bottom_size')"
      :section="section"
      field-type="select"
      attribute-id="bottom_size"
      :display-data="getItemLabel('apparelBottomSizes', 'bottom_size')"
      :items="inputSelectionItems.apparelBottomSizes"
      @edit="enableEdit"
    />
    <profile-details-form-field
      :show-form-input="showFormInput('shoe_size')"
      :section="section"
      field-type="select"
      attribute-id="shoe_size"
      :display-data="getItemLabel('apparelShoeSizes', 'shoe_size')"
      :items="inputSelectionItems.apparelShoeSizes"
      @edit="enableEdit"
    />
    <profile-details-form-field
      :show-form-input="showFormInput('outerwear_size')"
      :section="section"
      field-type="select"
      attribute-id="outerwear_size"
      :display-data="getItemLabel('apparelOuterwearSizes', 'outerwear_size')"
      :items="inputSelectionItems.apparelOuterwearSizes"
      @edit="enableEdit"
    />
    <profile-details-form-field
      :show-form-input="showFormInput('gift_card_number')"
      :section="section"
      field-type="text"
      attribute-id="gift_card_number"
      :display-data="ambassador.gift_card_number"
      @edit="enableEdit"
    />
    <profile-details-form-field
      :show-form-input="showFormInput('communication_consent')"
      :section="section"
      attribute-id="communication_consent"
      custom-display-data
      @edit="enableEdit"
    >
      <template #input>
        <v-checkbox
          id="update-communication-consent"
          v-model="section.formFields.communication_consent"
          :readonly="ambassador.communication_consent"
          :value="ambassador.communication_consent"
          :color="ambassador.communication_consent ? 'grey' : 'primary'"
          class="lll-checkbox lll-checkbox-align-start"
          :disabled="!section.editing"
        >
          <div slot="label">
            <span>
              {{ fieldText.communication_consent.checkboxLabel }}
            </span>
            <div class="h-label">
              <a
                :href="fieldText.communication_consent.linkHref"
                target="_blank"
                class="lll-link"
                @click.stop
              >
                {{ fieldText.communication_consent.linkLabel }}
              </a>
            </div>
          </div>
        </v-checkbox>
      </template>
      <template #display-data>
        <amb-pending-label
          v-if="fieldIsEmpty('communication_consent') && !userPermissions.canEditRecords"
          class="lll-form-field-display-data"
        />
        <div v-else-if="ambassador.communication_consent" class="form-field-display-data">
          {{ fieldText.communication_consent.displayData }}
        </div>
      </template>
    </profile-details-form-field>
    <profile-details-form-field
      :show-form-input="showFormInput('legal_consent')"
      :section="section"
      attribute-id="legal_consent"
      custom-display-data
      @edit="enableEdit"
    >
      <template #input>
        <v-checkbox
          id="update-legal-consent"
          v-model="section.formFields.legal_consent"
          :readonly="ambassador.legal_consent"
          :value="ambassador.legal_consent"
          :color="ambassador.legal_consent ? 'grey' : 'primary'"
          class="lll-checkbox lll-checkbox-align-start"
          :disabled="!section.editing"
        >
          <div slot="label">
            <div>
              <span>{{ fieldText.legal_consent.checkboxLabel }}</span>
            </div>
          </div>
        </v-checkbox>
      </template>
      <template #display-data>
        <amb-pending-label
          v-if="fieldIsEmpty('legal_consent') && !userPermissions.canEditRecords"
          class="lll-form-field-display-data"
        />
        <div v-else-if="ambassador.legal_consent" class="form-field-display-data">
          {{ fieldText.legal_consent.displayData }}
        </div>
      </template>
    </profile-details-form-field>
    <profile-details-form-field
      :show-form-input="showFormInput('egiftcard_sent')"
      :section="section"
      attribute-id="egiftcard_sent"
      custom-display-data
      @edit="enableEdit"
    >
      <template #input>
        <v-checkbox
          id="update-egiftcard-sent"
          v-model="section.formFields.egiftcard_sent"
          :value="ambassador.egiftcard_sent"
          :color="ambassador.egiftcard_sent ? 'grey' : 'primary'"
          class="lll-checkbox lll-checkbox-align-start"
          :disabled="!section.editing"
        >
          <div slot="label">
            <span>
              {{ fieldText.egiftcard_sent.checkboxLabel }}
            </span>
          </div>
        </v-checkbox>
      </template>
      <template #display-data>
        <amb-pending-label
          v-if="fieldIsEmpty('egiftcard_sent') && !userPermissions.canEditRecords"
          class="lll-form-field-display-data"
        />
        <div v-else-if="ambassador.egiftcard_sent" class="form-field-display-data">
          {{ fieldText.egiftcard_sent.displayData }}
        </div>
      </template>
    </profile-details-form-field>
    <profile-details-form-field
      attribute-id="profile_locale"
      :display-data="ambassador.profile_locale ? ambassador.profile_locale.label : ''"
      edits-disabled
      :section="section"
    />
    <!-- DIVERSITY & INCLUSION TAGS -->
    <h2 class="h-primary section-header row">
      {{ content.formSectionHeaders.moreInfo }}
    </h2>
    <div class="row experiences-section">
      <div class="experiences-field-1 mb-3">
        <profile-details-form-field
          :show-form-input="showFormInput('campaign_status')"
          :section="section"
          attribute-id="campaign_status"
          custom-label
          custom-display-data
          class="lll-form-field-bottom-padding"
          @edit="enableEdit"
        >
          <template #input>
            <v-checkbox
              v-model="section.formFields.campaign_status"
              :label="fieldText.campaign_status.label"
              :value="ambassador.campaign_status"
              hide-details
              color="primary"
              class="lll-checkbox lll-checkbox-align-start"
              :disabled="!section.editing"
            />
          </template>
          <template #display-data>
            <div v-if="fieldIsEmpty('campaign_status') && !userPermissions.canEditRecords">
              <amb-form-field-label attribute-id="campaign_status" />
              <amb-pending-label class="lll-form-field-display-data" />
            </div>
            <div v-else-if="ambassador.campaign_status" class="form-field-display-data">
              <v-icon color="secondary" class="pr-2">check_box</v-icon>
              {{ fieldText.campaign_status.label }}
            </div>
          </template>
        </profile-details-form-field>
      </div>
      <div class="experiences-field-2">
        <profile-details-form-field
          :show-form-input="showFormInput('experience_modelling_or_casting')"
          :section="section"
          field-type="textarea"
          attribute-id="experience_modelling_or_casting"
          :display-data="ambassador.experience_modelling_or_casting"
          class="lll-form-field-bottom-padding"
          @edit="enableEdit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as formHelpers from '@/utils/formHelpers';
import content from '@/content/staticContentForUI';
import { userCanEditThisPublishStatus } from '@/vuex/modules/auth';
import AMBFormFieldLabel from '@/components/AMBFormFieldLabel';
import AMBPendingLabel from '@/components/AMBPendingLabel';
import ProfileDetailsAddressBlock from '@/components/ProfileDetailsAddressBlock';
import ProfileDetailsFormField from '@/components/ProfileDetailsFormField';
import ProfileDetailsPhone from '@/components/ProfileDetailsPhone';

export default {
  name: 'ProfileDetailsPersonal',

  components: {
    'amb-form-field-label': AMBFormFieldLabel,
    'amb-pending-label': AMBPendingLabel,
    'profile-details-address-block': ProfileDetailsAddressBlock,
    'profile-details-form-field': ProfileDetailsFormField,
    'profile-details-phone': ProfileDetailsPhone,
  },

  props: {
    ambassador: {
      required: true,
      type: Object,
      default: () => {},
    },
    section: {
      required: true,
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      content: content.profileDetailsMain,
      fieldText: content.formFields,
      inputSelectionItems: content.inputSelectionItems,
      noDataText: content.noDataText,
      hideBirthdate: true,
      menuBirthdatePicker: false,
      menuStartDatePicker: false,
      menuEndDatePicker: false,
      // validation for Vuetify form fields
      validationRules: formHelpers.VALIDATION_RULES,
    };
  },

  computed: {
    ...mapGetters(['experiences', 'userClaims', 'userPermissions']),

    homeAddressEditsDisabled() {
      return !userCanEditThisPublishStatus(this.ambassador.publish_status, this.userClaims);
    },

    showBirthdateField() {
      return !this.ambassador.birth_date || !this.hideBirthdate;
    },
  },

  methods: {
    customFieldsAreValid() {
      /**
       * Any custom inputs (not Vuetify) won't get validated along with other form fields
       * via the 'isValid' method on AMBForm component, so we must validate them individually.
       */
      const fieldRefs = ['phonePrimary', 'phoneEmergency'];
      let result = true;

      for (const field of fieldRefs) {
        if (!this.$refs[field].validate()) {
          result = false;
          break;
        }
      }

      return result;
    },

    editBirthdate() {
      this.hideBirthdate = false;

      if (!this.section.editing) {
        this.enableEdit();
      }
    },

    enableEdit() {
      this.$emit('edit');
    },

    fieldIsEmpty(attributeId) {
      return formHelpers.fieldIsEmpty(attributeId, this.ambassador);
    },

    getItemLabel(listName, attributeName) {
      const items = this.inputSelectionItems[listName];
      const listItem = items.find(item => {
        return item.id === this.ambassador[attributeName];
      });

      return listItem ? listItem.label : '';
    },

    resetCustomValidation() {
      /**
       * Any custom validation (not Vuetify) won't get reset via the 'resetValidation' method
       * on AMBForm component, so we must manually reset each custom field.
       */
      const fieldRefs = ['phonePrimary', 'phoneEmergency'];

      for (const field of fieldRefs) {
        this.$refs[field].resetValidation();
      }
    },

    resetSpecialFields() {
      this.hideBirthdate = true;
      this.$refs.addressPrimary.resetFields();
      // emergency_address fields are temporarily hidden, so this is not needed for now. See https://lululemon.atlassian.net/browse/COCO-3291
      // this.$refs.addressEmergency.resetFields();
    },

    showFormInput(attributeId) {
      if (this.userPermissions && !this.userPermissions.canEditRecords) {
        return false;
      }

      const inEditMode = this.section.editing;
      /**
       * If field is in 'edit mode', show input.
       * If data not yet saved with ambassador record, show input.
       */
      return inEditMode || formHelpers.fieldIsEmpty(attributeId, this.ambassador);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/forms-inputs.scss';

.experiences-section {
  display: grid;
  grid-column-gap: 50px;
  grid-template:
    'one'
    'two'
    'three'
    'four'
    /
    1fr;

  @media (min-width: 1000px) {
    grid-template:
      'one one'
      'two three'
      'four four'
      /
      1fr 1fr;
  }
}

.experiences-field-1 {
  grid-area: one;
}

.experiences-field-2 {
  grid-area: two;
}

.experiences-field-3 {
  grid-area: three;
}

.experiences-field-4 {
  grid-area: four;
}

.experiences-input {
  padding: 5px 0 0 10px;
}

.list-item-icon-with-text {
  display: grid;
  grid-template-columns: min-content minmax(50px, 1fr);
  align-items: start;
  margin-bottom: 10px;
}
</style>
