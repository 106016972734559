<template>
  <div>
    <v-form role="search" @submit.prevent="filterResults">
      <v-text-field
        id="filter-search"
        v-model.trim="filterSearch"
        type="search"
        name="ambassador-search"
        browser-autocomplete="off"
        solo
        flat
        clearable
        :placeholder="content.placeholder"
        aria-label="Search Ambassadors by Name or ID"
        prepend-inner-icon="search"
        @click:prepend-inner="filterResults"
        @click:clear="clearSearchResults"
      />
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import content from '@/content/staticContentForUI';

export default {
  name: 'AmbassadorsListSearch',

  data() {
    return {
      content: content.ambassadorsListSearch,
    };
  },

  computed: {
    ...mapGetters(['filters']),

    filterSearch: {
      get() {
        return this.filters.search;
      },
      set(value) {
        this.$store.commit('updateFilter', { filterName: 'search', filterValue: value });
      },
    },
  },

  methods: {
    clearSearchResults() {
      this.$store.commit('updateFilter', { filterName: 'search', filterValue: '' });
      this.filterResults();
    },

    filterResults() {
      this.$emit('filterListing');
    },
  },
};
</script>
