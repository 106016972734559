<template>
  <div>
    <amb-pending-label
      v-if="!galleryHasPhotos && !userPermissions.canEditRecords"
      class="lll-form-field-display-data"
    />
    <div v-else-if="galleryHasPhotos">
      <photo-gallery-main-with-crop
        v-if="userCanTestCropper"
        ref="galleryMain"
        :ambassador-id="ambassador.id"
        :current-profile-photo="ambassador.profile_photo"
        :edits-disabled="editsDisabled"
        :gallery-loading="loadingPhotos"
        :photos-list="photosList"
        @openGalleryUploader="openGalleryUploader"
        @updatePhotosList="updatePhotosList"
      />
      <photo-gallery-main
        v-else
        ref="galleryMain"
        :ambassador-id="ambassador.id"
        :current-profile-photo="ambassador.profile_photo"
        :edits-disabled="editsDisabled"
        :gallery-loading="loadingPhotos"
        :photos-list="photosList"
        @openGalleryUploader="openGalleryUploader"
        @updatePhotosList="updatePhotosList"
      />
    </div>
    <amb-modal :open-modal.sync="galleryUploaderIsOpen">
      <photo-gallery-uploader-with-crop
        v-if="userCanTestCropper"
        ref="galleryUploader"
        :class="galleryUploaderIsOpen ? 'gallery-uploader-open' : ''"
        :ambassador-id="ambassador.id"
        :current-profile-photo="ambassador.profile_photo"
        :open-modal.sync="galleryUploaderIsOpen"
        :photos-list="photosList"
        :photos-count="photosCount"
        @updatePhotosList="updatePhotosList"
      />
      <photo-gallery-uploader
        v-else
        ref="galleryUploader"
        :class="galleryUploaderIsOpen ? 'gallery-uploader-open' : ''"
        :ambassador-id="ambassador.id"
        :current-profile-photo="ambassador.profile_photo"
        :open-modal.sync="galleryUploaderIsOpen"
        :photos-list="photosList"
        :photos-count="photosCount"
        @updatePhotosList="updatePhotosList"
      />
    </amb-modal>
  </div>
</template>

<script>
// TODO: COCO-412 - After photo cropper is implemented:
// remove 'userCanTestCropper' computed prop,
// remove alternate components from template,
// remove obsolete components and import statements

/**
 * This component determines which gallery components to display in the Photos section of
 * the ProfileDetails view. This parent component also listens for events from the child
 * components and updates the current ambassador data when the gallery is updated. This
 * ensures all gallery components are using the same ambassador data.
 */
import { mapActions, mapGetters } from 'vuex';
import AMBModal from '@/components/AMBModal';
import AMBPendingLabel from '@/components/AMBPendingLabel';
import PhotoGalleryMain from '@/components/PhotoGalleryMain';
import PhotoGalleryUploader from '@/components/PhotoGalleryUploader';
import PhotoGalleryMainWithCrop from '@/components/PhotoGalleryMainWithCrop';
import PhotoGalleryUploaderWithCrop from '@/components/PhotoGalleryUploaderWithCrop';

export default {
  name: 'ProfileDetailsPhotos',

  components: {
    'amb-modal': AMBModal,
    'amb-pending-label': AMBPendingLabel,
    'photo-gallery-main': PhotoGalleryMain,
    'photo-gallery-uploader': PhotoGalleryUploader,
    'photo-gallery-main-with-crop': PhotoGalleryMainWithCrop,
    'photo-gallery-uploader-with-crop': PhotoGalleryUploaderWithCrop,
  },

  props: {
    ambassador: {
      required: true,
      default: () => {},
      type: Object,
    },
    editsDisabled: {
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      galleryMainIsOpen: false,
      galleryUploaderIsOpen: false,
      loadingPhotos: false,
    };
  },

  computed: {
    ...mapGetters(['currentAmbassadorPhotos', 'userClaims', 'userPermissions']),

    photosList() {
      return this.currentAmbassadorPhotos.photosList || [];
    },

    photosCount() {
      return this.photosList.length;
    },

    galleryHasPhotos() {
      return !!this.photosList.length;
    },

    userCanTestCropper() {
      // These SSC users have access to photo cropper for UAT.
      // return [
      //   'bcollins@lululemon.com',
      //   'kateyconway@lululemon.com',
      //   'kchadijah@lululemon.com',
      //   'SHearn@lululemon.com',
      // ].includes(this.userClaims.email);

      // Commenting this out instead of deleting it, so we can enable this later if needed.
      // This function should be removed entirely when cropper is released to all users.
      return false;
    },
  },

  methods: {
    ...mapActions(['getAmbassadorPhotos', 'updateCurrentAmbassador']),

    openGalleryUploader() {
      this.galleryUploaderIsOpen = true;
    },

    updatePhotosList() {
      this.loadingPhotos = true;
      this.getAmbassadorPhotos(this.ambassador.id)
        .then(() => {
          this.updateCurrentAmbassador(this.ambassador.id);
          this.loadingPhotos = false;
        })
        .catch(error => {
          this.loadingPhotos = false;
          this.$handleErrorMessage(error);
        });
    },
  },
};
</script>
