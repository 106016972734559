<template>
  <error-message :body="message" />
</template>

<script>
import { customMessages } from '@/utils/errorHandlers';
import AMBMessageFullPage from '@/components/AMBMessageFullPage.vue';

export default {
  name: 'ErrorPage',

  components: {
    'error-message': AMBMessageFullPage,
  },

  computed: {
    message() {
      return customMessages.cannotAccessPage;
    },
  },
};
</script>
