<template>
  <div class="container-ambassadors-main">
    <h1 class="h-page-title">
      {{ content.mainHeading }}
    </h1>
    <ambassadors-list-search class="container-search" @filterListing="filterListing" />
    <ambassadors-list-filters class="container-filters" @filterListing="filterListing" />
    <div v-if="loading" class="container-list lll-padding-lg text-xs-center">
      <v-progress-circular :indeterminate="true" size="50" />
    </div>
    <div v-else class="container-list">
      <div class="menu-header menu-header-mobile flex-space-between lll-hr-grey">
        <div class="list-count mb-3">{{ ambassadorsListCount }} {{ content.listCountLabel }}</div>
        <ambassadors-list-export />
      </div>
      <div v-if="ambassadorsFound" class="ambassador-card-grid">
        <ambassadors-list-card
          v-for="ambassador in ambassadorsList"
          :key="ambassador.id"
          :ambassador="ambassador"
        />
      </div>
      <p v-else class="no-response-text text-xs-center">
        {{ content.noResults }}
      </p>
      <ambassadors-list-pagination
        v-if="ambassadorsFound"
        :loading.sync="updatingPagination"
        @updatePagination="updatePagination"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AmbassadorsListCard from '@/components/AmbassadorsListCard';
import AmbassadorsListExport from '@/components/AmbassadorsListExport';
import AmbassadorsListFilters from '@/components/AmbassadorsListFilters';
import AmbassadorsListPagination from '@/components/AmbassadorsListPagination';
import AmbassadorsListSearch from '@/components/AmbassadorsListSearch';
import content from '@/content/staticContentForUI';

export default {
  name: 'AmbassadorsList',

  components: {
    'ambassadors-list-card': AmbassadorsListCard,
    'ambassadors-list-export': AmbassadorsListExport,
    'ambassadors-list-filters': AmbassadorsListFilters,
    'ambassadors-list-pagination': AmbassadorsListPagination,
    'ambassadors-list-search': AmbassadorsListSearch,
  },

  data() {
    return {
      loading: false,
      content: content.ambassadorsList,
      updatingPagination: false,
    };
  },

  computed: {
    ...mapGetters(['ambassadorsList', 'ambassadorsListCount']),

    ambassadorsFound() {
      return this.ambassadorsListCount > 0;
    },
  },

  created() {
    this.prepListing();
  },

  methods: {
    ...mapActions([
      'getStoresList',
      'getAmbassadors',
      'getAmbassadorTypes',
      'getAmbassadorStatuses',
      'getDisciplines',
      'loadSubDisciplineRegistry',
      'getAmbassadorCountryIds',
    ]),

    prepListing() {
      this.loading = true;

      const stores = this.getStoresList();
      const types = this.getAmbassadorTypes();
      const statuses = this.getAmbassadorStatuses();
      const disciplines = this.getDisciplines();
      const subDisciplines = this.loadSubDisciplineRegistry();
      const countries = this.getAmbassadorCountryIds();

      Promise.all([stores, types, statuses, disciplines, subDisciplines, countries])
        .then(() => {
          // Dispatch this 'getAmbassadors' action after storesList is loaded to avoid duplicate
          // calls to Store Service, which increases the already long page load time.
          this.getAmbassadors()
            .then(() => {
              this.loading = false;
            })
            .catch(error => {
              this.loading = false;
              this.$handleErrorMessage(error);
            });
        })
        .catch(error => {
          this.loading = false;
          this.$handleErrorMessage(error);
        });
    },

    filterListing() {
      this.loading = true;
      // update pagination
      this.$store.commit('resetPagination');
      // get new listing
      this.getAmbassadors()
        .then(() => {
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          this.$handleErrorMessage(error);
        });
    },

    updatePagination() {
      this.updatingPagination = true;
      this.getAmbassadors()
        .then(() => {
          this.updatingPagination = false;
        })
        .catch(error => {
          this.$handleErrorMessage(error);
          this.updatingPagination = false;
        });
    },
  },
};
</script>

<style lang="scss">
.container-ambassadors-main {
  display: grid;
  grid-template:
    'title'
    'search'
    'filters'
    'list' / 1fr;
  grid-template-rows: min-content;
  grid-row-gap: 25px;
  padding: 50px 5vw 25px 5vw;

  @media (min-width: 900px) {
    grid-template:
      'title title search'
      'filters . list' / minmax(300px, min-content) 50px 1fr;
    padding: 75px 5vw 25px 5vw;
  }

  > .h-page-title {
    grid-area: title;

    @media (max-width: 900px) {
      margin: 0;
    }
  }
}

.container-filters {
  grid-area: filters;
}

.container-list {
  grid-area: list;
}

.container-search {
  grid-area: search;

  @media (min-width: 900px) {
    justify-self: end;
  }
}

.ambassador-card-grid {
  display: grid;
  grid-gap: 25px;
  grid-template-columns: 1fr;
  margin-bottom: 25px;

  @media (min-width: 475px) {
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  }
}

.list-count {
  font-size: $lll-font-size-small;
  line-height: 2.5rem;
  margin-bottom: 15px;
}

.menu-header-mobile {
  @media (max-width: 475px) {
    display: grid;
    border-bottom: none;
    justify-content: left;
  }
}
</style>
