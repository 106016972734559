import axios from 'axios';
import AmbassadorsBase from '@/models/v2/AmbassadorsBase';
import PhotoTag from '@/models/v2/PhotoTag';
import get from '@/utils/config';

const bucketPath = get('VUE_APP_PHOTOS_S3_BUCKET_DOMAIN');
const cloudfrontPath = get('VUE_APP_CLOUDFRONT_PHOTOS_DOMAIN');

class Photo extends AmbassadorsBase {
  static _type = 'photos';

  static name = 'photo';

  static baseURL = '/photo_gallery/:ambassador_id';

  constructor(args = {}) {
    if (args === null) {
      args = {};
    }
    super(args);
    this.id = args.id;
    this.publish_url = args.publish_url;
    this.url = args.url?.replace(bucketPath, cloudfrontPath);

    this.added = args.added;

    this.tags = this.hasMany(PhotoTag, args.tags);

    this.baseURL = this.constructor.constructBaseURL(args);
  }

  constructBaseURL() {
    return this.baseURL;
  }

  _update() {
    return this.constructor.adapter
      .patch(`${this.baseURL}/${this.id}`, this.serialize())
      .then(({ data }) => this.constructor.deserialize(data))
      .catch(err => {
        // eslint-disable-next-line no-undef
        this.errors = new Errors(err.data);
        throw err;
      });
  }

  // TODO: make this delete method work and remove second one
  // delete() {
  //   if (!this.persisted) {
  //     return;
  //   }
  //   return this.adapter.delete(`${this.baseURL}/${this.id}`);
  // }

  static async deletePhoto(args = {}) {
    return await this.adapter.delete(`/photo_gallery/${args.ambassador_id}/${args.photo_id}`, args);
  }

  static async newPhoto(ambassadorId, file, tags = []) {
    // Check if the file size exceeds 5MB (5 * 1024 * 1024 bytes)
    const maxFileSizeBytes = 5 * 1024 * 1024;
    if (file?.size > maxFileSizeBytes) {
      throw new Error('File size exceeds the maximum allowed size of 5MB.');
    }

    const signedURL = await this.getSignedURL(ambassadorId, file)
      .then(response => response.data)
      .catch(error => {
        throw error;
      });

    await this.uploadFile(signedURL, file);

    const args = {
      ambassador_id: ambassadorId,
      url: signedURL.data.attributes.url + signedURL.data.attributes.fields.key,
    };
    const photo = new Photo(args);

    if (tags.length) {
      for (const tag of tags) {
        photo.tags.push(tag);
      }
    }

    await photo.save();

    return photo;
  }

  static async getSignedURL(ambassadorId, file) {
    const args = {
      params: {
        content_type: file.type,
        filename: file.name,
      },
    };

    return await this.adapter.get(`/signed_urls/${ambassadorId}/photo`, args);
  }

  // use signedURL to upload file to S3 bucket
  static async uploadFile(signedURL, file) {
    const photoData = new FormData();

    // eslint-disable-next-line guard-for-in
    for (const key in signedURL.data.attributes.fields) {
      photoData.append(key, signedURL.data.attributes.fields[key]);
    }

    photoData.append('file', file);

    await axios.post(signedURL.data.attributes.url, photoData);
  }
}

export default Photo;
