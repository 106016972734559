import {
  AuthenticationDetails,
  CognitoIdToken,
  CognitoUserPool,
  CognitoUser,
} from 'amazon-cognito-identity-js';
import axios from 'axios';
import idleTimer from 'idle-timer';
import get from '@/utils/config';
import { getCookie, setCookie } from '@/utils/cookies';

const TOKEN_EXPIRATION_ACTIVE_BUFFER_MINS = 10;
const TOKEN_EXPIRATION_IDLE_BUFFER_MINS = 1;
const MAX_IDLE_TIME_MINS = get('VUE_APP_MAX_IDLE_TIME_MINS');

// use this array to check only the claims that affect the UI
// (e.g., show/hide buttons based on user claims)
const ESCAPED_CLAIM_KEYS = [
  'ambassador_assume_role',
  'ambassador_read',
  'ambassador_create',
  'ambassador_update',
  'ambassador_update_status',
  'ambassador_export',
  'set_draft',
  'edit_draft',
  'set_ready_for_review',
  'edit_ready_for_review',
  'set_ready_to_publish',
  'edit_ready_to_publish',
  'set_published',
  'edit_published',
  'set_archived',
  'edit_archived',
  'manage_permissions',
];

const poolData = {
  UserPoolId: get('VUE_APP_COGNITO_USER_POOL_ID'),
  ClientId: get('VUE_APP_COGNITO_CLIENT_ID'),
};

const userPool = new CognitoUserPool(poolData);

const initialState = () => {
  return {
    loginStatus: '',
    token: '',
    userPermissions: {},
    claims: {},
  };
};

const actions = {
  initAuth({ commit, dispatch }) {
    const storedAuthToken = getAuthToken();
    const storedPermissions = getStoredPermissions();
    const storedClaims = getStoredClaims();

    if (storedAuthToken) {
      commit('setAuthToken', storedAuthToken);
    }

    if (storedClaims) {
      commit('setClaims', convertClaimsToSets(storedClaims));
    }

    if (storedPermissions) {
      commit('setUserPermissions', storedPermissions);
    }

    idleTimer({
      idleTime: MAX_IDLE_TIME_MINS * 60000,
      callback: () => {
        onIdleUser(dispatch);
      },
      activeCallback: () => {
        onActiveUser(dispatch);
      },
    });
  },

  /**
   * Handles users logging in with email/password. This method was created for app MVP,
   * but stakeholders have since decided to only allow logging in via ActiveDirectory.
   *
   * @param {Object} payload - user email and password collected from login form
   */
  login({ commit }, payload) {
    return new Promise((resolve, reject) => {
      commit('loginRequest');

      const authDetails = new AuthenticationDetails({
        Username: payload.email,
        Password: payload.password,
      });

      const cognitoUser = new CognitoUser({
        Username: payload.email,
        Pool: userPool,
      });

      cognitoUser.authenticateUser(authDetails, {
        onSuccess: async result => {
          const idToken = result.getIdToken();
          let claims = idToken.payload;
          const accessToken = result.getAccessToken().getJwtToken();
          setCookie('accessToken', accessToken);
          if (claims.ambassador_perms_url) {
            claims = await loadAdditionalClaims(idToken.getJwtToken(), claims);
          }

          setClaims(commit, claims);
          setPermissions(commit, claims);
          setAuthToken(commit, idToken.getJwtToken());
          commit('loginSuccess');
          resolve(result);
        },
        onFailure: err => {
          commit('loginFailure');
          reject(err);
        },
      });
    });
  },

  async loginAD({ commit }, tokens) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      commit('loginRequest');
      localStorage.removeItem('amb-federated-login');

      if ('id_token' in tokens) {
        const idToken = new CognitoIdToken({ IdToken: tokens['id_token'] });
        let claims = idToken.payload;

        setCookie('accessToken', tokens['access_token']);

        if (claims.ambassador_perms_url) {
          claims = await loadAdditionalClaims(idToken.getJwtToken(), claims);
        }

        setClaims(commit, claims);
        setPermissions(commit, claims);
        setAuthToken(commit, tokens['id_token']);
        commit('loginSuccess');

        const default_state = { uri: '/ambassadors' };
        let state = default_state;

        if ('state' in tokens) {
          state = JSON.parse(decodeURIComponent(tokens['state']));

          if ('logout' in state) {
            const email = state.logout;
            const new_email = claims.identities[0].userId;
            if (email !== new_email) {
              state = default_state;
            }
          }
        }

        resolve(state);
      } else {
        commit('loginFailure');
        reject(tokens);
      }
    });
  },

  logout({ commit }) {
    return new Promise(resolve => {
      const cognitoUser = userPool.getCurrentUser();
      if (cognitoUser !== null) {
        // it was an email/password form login
        cognitoUser.signOut();
        clearLocalStorageAndCookies();
        sessionStorage.removeItem('formsInProgress');
        commit('resetState');
        resolve();
      } else {
        // it was an AD login
        const tokenExpired = isTokenExpired();
        if (tokenExpired) {
          const claims = getStoredClaims();
          const state = { logout: claims.identities[0].userId };
          const uri = redirectToFederatedIdentityLogin(state);

          window.location = uri;
        } else {
          clearLocalStorageAndCookies();
          sessionStorage.removeItem('formsInProgress');

          const cognitoDomain = get('VUE_APP_COGNITO_DOMAIN');
          const cognitoClientId = get('VUE_APP_COGNITO_CLIENT_ID');
          const logout_uri = encodeURIComponent(`${window.location.origin}/logout`);
          const uri = `https://${cognitoDomain}/logout?client_id=${cognitoClientId}&logout_uri=${logout_uri}`;
          window.location = uri;
        }
      }
    });
  },

  refresh({ commit }) {
    return new Promise((resolve, reject) => {
      commit('loginRequest');
      const cognitoUser = userPool.getCurrentUser();

      if (cognitoUser) {
        // it was an email/password form login
        cognitoUser.getSession((err, session) => {
          const refreshToken = session.getRefreshToken();

          cognitoUser.refreshSession(refreshToken, async (err, session) => {
            if (err) {
              reject(err);
              return;
            }

            const idToken = session.getIdToken();
            let claims = idToken.payload;
            const accessToken = session.getAccessToken().getJwtToken();
            setCookie('accessToken', accessToken);

            if (claims.ambassador_perms_url) {
              claims = await loadAdditionalClaims(idToken.getJwtToken(), claims);
            }

            setClaims(commit, claims);
            setPermissions(commit, claims);
            setAuthToken(commit, idToken.getJwtToken());
            resolve();
          });
        });
      } else {
        // it was an AD login
        refreshAuthToken();
      }
    });
  },

  // actions for Impersonation Utility (AdminPanel.vue):

  async impersonateUser({ commit }, email) {
    let storedAdminClaims = getCookie('adminClaims');
    if (!storedAdminClaims) {
      storedAdminClaims = getCookie('claims');
      setCookie('adminClaims', storedAdminClaims);
    }

    const assumedRoleClaims = await loadClaimsForAssumedRole(email);
    assumedRoleClaims['email'] = email;

    if (assumedRoleClaims.ambassador_role === 'none') {
      throw new Error(
        `Invalid email. User "${assumedRoleClaims.email}" does not have access to this site.`
      );
    }

    setClaims(commit, assumedRoleClaims);
    setPermissions(commit, assumedRoleClaims);
    window.location.reload();
  },

  restoreAdminUser({ commit }) {
    const storedAdminClaims = getCookie('adminClaims');
    const storedClaims = getStoredClaims();

    if (!storedAdminClaims && storedClaims.ambassador_assume_role) {
      // no need to continue, since user is already signed in as Admin
      return;
    }

    const adminClaims = JSON.parse(storedAdminClaims);

    setClaims(commit, adminClaims);
    setPermissions(commit, adminClaims);
    window.location.reload();
  },
};

const getters = {
  loginStatus: state => state.loginStatus,
  userLoggedIn: state => !!state.token,
  userClaims: state => state.claims,
  userPermissions: state => state.userPermissions,
};

const mutations = {
  loginRequest(state) {
    state.loginStatus = 'loading';
  },
  loginSuccess(state) {
    state.loginStatus = 'success';
  },
  loginFailure(state) {
    state.loginStatus = 'failure';
    state.token = '';
  },
  setAuthToken(state, token) {
    state.token = token;
  },
  setClaims(state, claims) {
    state.claims = claims;
  },
  setUserPermissions(state, userPermissions) {
    state.userPermissions = userPermissions;
  },
  resetState(state) {
    Object.assign(state, initialState);
  },
};

const state = initialState;

// HELPER FUNCTIONS

function calculateUserPermissions(claims) {
  let userClaims = {};
  const permissionsMap = {};

  // If 'claims' parameter is JSON, parse it first.
  // Otherwise, assume parameter is object literal and continue.
  try {
    userClaims = JSON.parse(claims);
  } catch (e) {
    userClaims = claims;
  }

  ESCAPED_CLAIM_KEYS.forEach(key => {
    if (key in userClaims && userClaims[key]) {
      permissionsMap[key] = true;
    } else {
      permissionsMap[key] = false;
    }
  });

  const userPermissions = {
    canAssumeRoles: permissionsMap.ambassador_assume_role,
    canViewRecords: permissionsMap.ambassador_read,
    canCreateRecords: permissionsMap.ambassador_create,
    canEditRecords: permissionsMap.ambassador_update,
    canUpdateAmbassadorStatus: permissionsMap.ambassador_update_status,
    canExportRecords: permissionsMap.ambassador_export,
    canSetDraftStatus: permissionsMap.set_draft,
    canEditDraftStatus: permissionsMap.edit_draft,
    canSetReadyForReviewStatus: permissionsMap.set_ready_for_review,
    canEditReadyForReviewStatus: permissionsMap.edit_ready_for_review,
    canSetReadyToPublishStatus: permissionsMap.set_ready_to_publish,
    canEditReadyToPublishStatus: permissionsMap.edit_ready_to_publish,
    canSetPublishedStatus: permissionsMap.set_published,
    canEditPublishedStatus: permissionsMap.edit_published,
    canSetArchivedStatus: permissionsMap.set_archived,
    canEditArchivedStatus: permissionsMap.edit_archived,
    canManagePermissions: permissionsMap.manage_permissions,
  };

  return userPermissions;
}

function clearLocalStorageAndCookies() {
  document.cookie = 'claims=; path=/';
  document.cookie = 'adminClaims=; path=/';
  document.cookie = 'accessToken=; path=/';
  document.cookie = 'userPermissions=; path=/';
  document.cookie = 'token=; path=/';
  localStorage.removeItem('amb-federated-login');
}

/**
 * Edits the claims object. Original claims object includes a list of store IDs
 * for the value of each item in ESCAPED_CLAIM_KEYS. This function replaces
 * each Array of store IDs with a Set of store IDs, which improves efficiency.
 *
 * @param {Object} claims - Claims Object from user's JWT. Depending on which
 * function is calling this, parameter passed in may be either JSON format or
 * object literal.
 * @return - Updated claims
 */
function convertClaimsToSets(claims) {
  ESCAPED_CLAIM_KEYS.forEach(key => {
    const claimSet = new Set();

    if (key in claims && claims[key]) {
      // If claims[key] value is "*", user has access to all stores.
      if (claims[key] !== '*') {
        let claimsArray;
        if (Array.isArray(claims[key])) {
          claimsArray = claims[key];
        } else {
          claimsArray = JSON.parse(claims[key]);
        }
        // If claims[key] value is one or more store IDs, add store IDs to claimSet.
        for (const item of claimsArray) {
          claimSet.add(item);
        }
        claims[key] = claimSet;
      }
    }
  });

  return claims;
}

function userCanEditThisPublishStatus(publishStatus, userClaims) {
  if (!userClaims.ambassador_update) {
    return false;
  }

  if (!publishStatus) {
    return false;
  }

  const claimsList = Object.keys(userClaims);
  const prefix = 'edit_';
  const requiredEditClaim = prefix.concat(publishStatus);

  if (!claimsList.includes(requiredEditClaim)) {
    return false;
  }

  return true;
}

function getAuthToken() {
  return getCookie('token');
}

function getStoredClaims() {
  const claims = getCookie('claims');
  if (!claims) {
    return null;
  }

  return JSON.parse(claims);
}

function getStoredPermissions() {
  const permissions = getCookie('userPermissions');
  if (!permissions) {
    return null;
  }

  return JSON.parse(permissions);
}

function isTokenExpired(expiryBufferSecs = 0) {
  const token = getAuthToken();

  if (token) {
    const idToken = new CognitoIdToken({ IdToken: token });
    const exp = idToken.payload.exp - expiryBufferSecs;
    const now = Math.floor(Date.now() / 1000);
    // console.log("Compare: ", new Date(now*1000), "<", new Date(exp*1000), "=", now > exp);
    return now > exp;
  }

  return false;
}

/**
 * Loads additional claims when user's claims exceed the auth header's
 * size limit. Some claims include a list of store IDs that the user
 * has access to. Some Area/Regional Managers have so many store IDs
 * that their claims are too long to fit in the header.
 */
async function loadAdditionalClaims(idToken, claims) {
  return loadClaims(claims.ambassador_perms_url, idToken);
}

async function loadClaims(url, token) {
  const response = await axios
    .create({
      headers: { Authorization: `Bearer ${token}` },
    })
    .get(url);

  const claims = {};

  // eslint-disable-next-line guard-for-in
  for (const key in response.data) {
    claims[key] = response.data[key];
  }

  return claims;
}

async function loadClaimsForAssumedRole(email) {
  const host = get('VUE_APP_PERMISSIONS_HOST');
  const url = `${host}/v1/permissions/${email}`;
  const token = getAuthToken();

  return loadClaims(url, token);
}

function onActiveUser(dispatch) {
  if (isTokenExpired(TOKEN_EXPIRATION_ACTIVE_BUFFER_MINS * 60)) {
    dispatch('refresh');
  }
}

function onIdleUser(dispatch) {
  if (isTokenExpired(TOKEN_EXPIRATION_IDLE_BUFFER_MINS * 60)) {
    // we do this to ensure that we are logging out with a valid token,
    // because in AD/Cognito, you cannot log out with an expired token
    dispatch('refresh');
  } else {
    dispatch('logout');
  }
}

function readUrlToken(route) {
  // I *think* this is only undefined while testing, but a decent safety check
  if (typeof route === 'undefined') {
    return false;
  }

  const fragment = route.hash;

  if (fragment !== '') {
    const raw_tokens = fragment.substr(1).split('&');
    const token_pairs = raw_tokens.map(x => x.split('='));
    const tokens = token_pairs.reduce((o, pair) => {
      o[pair[0]] = pair[1]; // or what ever object you want inside
      return o;
    }, {});

    return tokens;
  }

  return false;
}

function redirectToFederatedIdentityLogin(state) {
  clearLocalStorageAndCookies();

  localStorage.setItem('amb-federated-login', true);

  const cognitoDomain = get('VUE_APP_COGNITO_DOMAIN');
  const cognitoClientId = get('VUE_APP_COGNITO_CLIENT_ID');
  const identityProvider = get('VUE_APP_COGNITO_IDENTITY_PROVIDER');
  const encodedState = encodeURIComponent(JSON.stringify(state));
  const redirect_uri = encodeURIComponent(`${window.location.origin}/login`);
  const urlTemplate = `https://${cognitoDomain}/oauth2/authorize?response_type=token&client_id=${cognitoClientId}&redirect_uri=${redirect_uri}&identity_provider=${identityProvider}&state=${encodedState}`;
  return urlTemplate;
}

function refreshAuthToken() {
  // save 'pathname' so vue-router can return user to original path after the refresh
  // append 'search' params if present (e.g., PR environment params)
  const current_uri = window.location.pathname + window.location.search;

  window.location = redirectToFederatedIdentityLogin({ uri: current_uri });
}

function setAuthToken(commit, token) {
  setCookie('token', token);
  commit('setAuthToken', token);
}

function setClaims(commit, claims) {
  setCookie('claims', JSON.stringify(claims));
  commit('setClaims', convertClaimsToSets(claims));
}

function setPermissions(commit, claims) {
  const userPermissions = calculateUserPermissions(claims);
  setCookie('userPermissions', JSON.stringify(userPermissions));
  commit('setUserPermissions', userPermissions);
}

function userIsAdmin() {
  const claims = getStoredClaims();

  if (claims && claims.ambassador_assume_role) {
    return true;
  }

  let parsedAdminClaims;
  const adminClaims = getCookie('adminClaims');
  if (adminClaims) {
    parsedAdminClaims = JSON.parse(adminClaims);
  }

  if (parsedAdminClaims && parsedAdminClaims.ambassador_assume_role) {
    return true;
  }

  return false;
}

export {
  userCanEditThisPublishStatus,
  getAuthToken,
  getStoredClaims,
  readUrlToken,
  redirectToFederatedIdentityLogin,
  refreshAuthToken,
  userIsAdmin,
  clearLocalStorageAndCookies,
};

export default {
  actions,
  getters,
  mutations,
  state,
};
