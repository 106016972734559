import { refreshAuthToken } from '@/vuex/modules/auth';
import content from '@/content/staticContentForUI';

const customMessages = content.errorMessages;

/**
 * Creates a new error object with a structure that can be used by the $handleErrorMessage()
 * mixin, which triggers an error message to show in the UI (e.g., alert/dialog or snackbar).
 *
 * @param {Error} originalError
 * @returns {Object} formatted error
 *
 * Example of return value:
 *    {
 *      'isFormatted': true,
 *      'function': refreshAuthToken,
 *      'customMessage': 'Your Active Directory session has expired.',
 *      'originalMessage': 'Request failed with status code 401',
 *      'status': 401,
 *    }
 */
function formatResponseError(originalError) {
  let error = originalError;
  // this ensures an error is passed on, even if original error
  // somehow didn't get passed to this function properly
  if (!originalError || !(originalError instanceof Error)) {
    error = new Error(
      `${customMessages.unknownError} There may be a problem with the error handling functions.`
    );
  }

  const errorStatus = getErrorStatus(error);

  try {
    const formattedError = {
      isFormatted: true,
      function: getResponseErrorFunction(error, errorStatus),
      customMessage: getResponseErrorMessage(error, errorStatus),
      originalMessage: error.message,
      status: errorStatus,
    };

    return formattedError;
  } catch (e) {
    console.error('Problem with error handling functions: ', e);
  }

  return error;
}

function getErrorStatus(error) {
  let errorStatus = '';

  if (error.response) {
    errorStatus = error.response.status;
  } else if (error.request) {
    errorStatus = error.request.status;
  }

  return errorStatus;
}

function getResponseErrorFunction(error, errorStatus) {
  if (errorStatus === 401) {
    return refreshAuthToken;
  }

  return null;
}

function getResponseErrorMessage(error, errorStatus) {
  // set default message
  let customMessage = customMessages.unknownError;

  if (errorStatus === 401) {
    customMessage = customMessages.sessionExpired.default;

    if (sessionStorage.getItem('formsInProgress')) {
      customMessage = customMessages.sessionExpired.formSubmissionFailed;
    }

    if (sessionStorage.getItem('photoUploadInProgress')) {
      customMessage = customMessages.sessionExpired.profilePhotoUploadFailed;
    }

    if (sessionStorage.getItem('fileUploadInProgress')) {
      customMessage = customMessages.sessionExpired.documentUploadFailed;
    }
  }

  if (errorStatus === 409 && error.response.data.errors[0].code === 'ambassadors_email_key') {
    customMessage = customMessages.duplicateEmail;
  }

  return customMessage;
}

export { customMessages, formatResponseError, getResponseErrorMessage };
