<template>
  <vue-tel-input
    v-model.trim="phone"
    :input-id="inputId"
    :preferred-countries="['ca', 'us', 'cn', 'hk', 'jp', 'kr']"
    autocomplete="off"
    :placeholder="placeholder"
    wrapper-classes="lll-input-with-border"
    :disabled="disabled"
    :disabled-fetching-country="disabled"
    @input="resetValidation"
    @blur="validate"
    @validate="handleValidatedNumber"
  />
</template>

<script>
/**
 * Documentation for this component:
 * https://github.com/EducationLink/vue-tel-input
 */
import VueTelInput from 'vue-tel-input';

export default {
  name: 'InputPhone',

  components: {
    'vue-tel-input': VueTelInput,
  },

  props: {
    currentAmbassadorPhone: {
      type: String,
      default: '',
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inputId: {
      type: String,
      default: 'phone',
    },
    placeholder: {
      type: String,
      default: 'Enter a phone number',
    },
  },

  data() {
    return {
      phone: this.currentAmbassadorPhone || '',
      phoneIsValid: false,
    };
  },

  watch: {
    currentAmbassadorPhone(value) {
      this.phone = value;
    },
    phone() {
      this.emitNumber();
    },
  },

  /**
   * These params are available to methods that get called @onInput, @onBlur, and @onValidate:
   *
   * @param {String} number
   * The phone number inputted by the user will be formatted along with country code.
   *   Example:
   *     before formatting, input = (CA) '604-732-6124' or '(604) 732-6124' or '604.732.6124'
   *     after formatting, number = '+1 604 732 6124'
   *
   * @param {Boolean} isValid
   * @param {String} country
   */
  methods: {
    /**
     * When number is emitted, the parent component can use it to populate the phone number field.
     */
    emitNumber() {
      this.$emit('phoneNumber', this.phone);
    },

    /**
     * This is called after phone number is validated by vue-tel-input component. Value of
     * 'isValid' param is assigned to data prop so other methods can access it. Empty string
     * is considered invalid in this case.
     */
    handleValidatedNumber({ isValid }) {
      this.phoneIsValid = isValid;
    },

    /**
     * This exists so parent components can call 'this.$refs[refName].resetValidation()' and
     * trigger method in parent component.
     */
    resetValidation() {
      this.$emit('resetValidation');
    },

    /**
     * This triggers error messages in the parent component and helps the parent component know
     * which error message to show. Parent component can call 'this.$refs[refName].validate()'.
     */
    validate() {
      /**
       * Service validation for this field accepts empty string or integer between 7-30 digits.
       * Since `vue-tel-input` validates the number's length/structure, we only need to confirm
       * that it doesn't have invalid text (`this.phoneIsValid` allows for letters like "ext.").
       *
       * Example: `+1 512 555 5555 ext. 200` is valid according to `this.phoneIsValid` but not
       * valid according to Ambassadors Service.
       */
      const passesRegexpUsedByService = /^$|^[0-9/+ ]{7,30}$/.test(this.phone);
      const numberIsValid = this.phone === '' || (this.phoneIsValid && passesRegexpUsedByService);

      const validationStatus = {
        isPresent: !!this.phone,
        isValid: numberIsValid,
      };

      this.$emit('validationStatus', validationStatus);
      return numberIsValid;
    },
  },
};
</script>
