<template>
  <div class="gallery-cropper-container">
    <div class="gallery-cropper-img-original grid-centered">
      <div class="h-primary mb-2">{{ content.original }}</div>
      <div class="image-container">
        <img ref="originalImage" alt="" class="image-preview" :src="originalImage" />
      </div>
      <div class="gallery-cropper-buttons mt-2">
        <button class="btn-link" :disabled="loading" @click="rotateLeft">
          <v-icon>rotate_left</v-icon>
        </button>
        <span class="h-label mb-0">{{ content.rotate }}</span>
        <button class="btn-link" :disabled="loading" @click="rotateRight">
          <v-icon>rotate_right</v-icon>
        </button>
      </div>
    </div>
    <div class="gallery-cropper-img-preview grid-centered">
      <div class="h-primary mb-2">{{ content.preview }}</div>
      <div v-if="loading" class="image-container">
        <div class="gallery-loading-spinner">
          <v-progress-circular :indeterminate="true" size="50" />
        </div>
      </div>
      <div v-show="!loading" class="image-container">
        <img class="image-preview" alt="" :src="previewCropped" />
      </div>
    </div>
    <div class="gallery-cropper-instructions">
      <p>{{ content.instructions.adjustFrame }}</p>
      <p>
        <span class="h-primary">{{ content.preview }}</span>
        {{ content.instructions.preview }}
      </p>
      <div class="btn-group-2-col">
        <button
          name="crop-image"
          class="btn btn-primary"
          :disabled="loading"
          @click="handleImageSave"
        >
          {{ content.saveButton }}
        </button>
        <button name="cancel-image-crop" class="btn" :disabled="loading" @click="cancelImageCrop">
          {{ content.cancelButton }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Cropper from 'cropperjs';
import debounce from 'lodash/debounce';
import content from '@/content/staticContentForUI';

export default {
  name: 'PhotoGalleryCropper',

  props: {
    /**
     * Id of current ambassador. This id must be used as param in save action.
     */
    ambassadorId: {
      type: String,
      default: '',
    },
    /**
     * Object with 'ambassador.profile_photo' attributes delivered from service.
     * This Photo model must be used as param in save action.
     * e.g.: {
     *         id: "string-with-unique-id",
     *         publish_url: "https://s7d2.scene7.com/is/image/...",
     *         url: "https://lll-nonprod-src-ambassadors-us-west-2.s3.amazonaws.com/...",
     *         added: "date-added",
     *         tags: [Array of PhotoTag elements],
     *         baseURL: "/photo_gallery/string-with-unique-id"
     *       }
     */
    currentProfilePhoto: {
      type: Object,
      default: () => {},
    },
    /**
     * Photo model (existing gallery photo) passed in from parent component.
     * This may be needed as param in save action.
     */
    galleryPhoto: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      content: content.photoGalleryCropper,
      cropper: null,
      debouncedUpdatePreview: debounce(this.updatePreview, 75),
      uploadedFileBlob: '',
      loading: false,
      previewCropped: null,
    };
  },

  computed: {
    originalImage() {
      return this.galleryPhoto ? this.galleryPhoto.url : this.uploadedFileBlob;
    },
  },

  methods: {
    ...mapActions(['saveNewProfilePhoto', 'updateProfilePhoto']),

    cancelImageCrop() {
      this.resetCropper();
      this.closeImageCropper();
    },

    closeImageCropper() {
      this.$emit('closeImageCropper');
    },

    resetCropper() {
      if (!this.cropper) {
        return;
      }

      this.cropper.reset();
    },

    rotateLeft() {
      if (!this.cropper) {
        console.warn('Setup new cropper instance to use this function.');
        return;
      }

      this.cropper.rotate(-90);
    },

    rotateRight() {
      if (!this.cropper) {
        console.warn('Setup new cropper instance to use this function.');
        return;
      }

      this.cropper.rotate(90);
    },

    setupImageCropper() {
      this.loading = true;

      if (this.cropper) {
        this.cropper.destroy();
      }

      if (!this.originalImage) {
        this.cropper = null;
        this.previewCropped = null;
        this.loading = false;
        return;
      }

      this.$nextTick(this.setupCropperInstance);
    },

    setupCropperInstance() {
      this.cropper = new Cropper(this.$refs.originalImage, {
        aspectRatio: 25 / 28,
        autoCropArea: 1,
        checkOrientation: false,
        highlight: false,
        viewMode: 1,
        zoomable: false,
        crop: this.debouncedUpdatePreview,
      });
    },

    updatePreview() {
      const canvas = this.cropper.getCroppedCanvas();
      this.previewCropped = canvas.toDataURL('image/png');
      this.loading = false;
    },

    // TODO: If Ambassador Team decides to use this cropping tool, fix bug COCO-1226. The blob has
    // a different filename from original photo, which causes an error during save.
    // https://lululemon.atlassian.net/browse/COCO-412
    // https://lululemon.atlassian.net/browse/COCO-1226
    handleImageSave() {
      const canvas = this.cropper.getCroppedCanvas();

      // Save cropped image. Original image will remain in gallery.
      canvas.toBlob(blob => {
        // Since this is a new photo, ambassador ID must be included.
        const payload = {
          ambassadorId: this.ambassadorId,
          newProfilePhoto: blob,
          currentProfilePhoto: this.currentProfilePhoto,
        };

        this.saveCroppedPhoto(payload);
      });
    },

    saveCroppedPhoto(payload) {
      this.loading = true;
      sessionStorage.setItem('photoUploadInProgress', true);

      this.saveNewProfilePhoto(payload)
        .then(() => {
          this.$emit('photoSaved');
          this.closeImageCropper();
          this.loading = false;
          sessionStorage.removeItem('photoUploadInProgress');
        })
        .catch(error => {
          this.loading = false;
          this.$handleErrorMessage(error);
          sessionStorage.removeItem('photoUploadInProgress');
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.gallery-cropper-container {
  display: grid;
  grid-template:
    'original' min-content
    'preview' min-content
    'instructions' min-content
    /
    1fr;
  grid-row-gap: 50px;
  grid-column-gap: 0;
  padding: 5vw;
  place-content: center;

  @media (min-width: 800px) {
    grid-template:
      'original preview' min-content
      'instructions instructions' min-content
      /
      min-content min-content;
    grid-column-gap: 25px;
  }

  @media (min-width: 1500px) {
    grid-template:
      'original preview instructions' min-content
      /
      min-content min-content 1fr;
  }
}

.gallery-cropper-buttons {
  display: grid;
  grid-template-columns: repeat(3, min-content);
  grid-gap: 15px;
}

.gallery-cropper-img-original {
  grid-area: original;
  align-content: start;
}

.gallery-cropper-img-preview {
  grid-area: preview;
  position: relative;
  align-content: start;
}

.gallery-cropper-instructions {
  grid-area: instructions;
  font-size: $lll-font-size-medium;

  @media (min-width: 1500px) {
    padding: 50px;
  }
}

// TODO: NOTICKET - Handle this repeated style? Also used in PhotoGalleryMain.vue
.gallery-loading-spinner {
  position: absolute;
  display: grid;
  place-items: center;
  place-content: center;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
}

/**
 * Calculate height relative to width. This allows us to easily adjust photo size
 * while keeping a consistent aspect ratio and responsive layout.
 * @param {css value} width - The photo width in px (e.g., 750px).
 * @return {css value} The height in px (e.g., 840px).
 */
@function relative-photo-height($width) {
  // current preferred dimensions are 840px/750px, which has aspect ratio of 25:28
  @return (840px/750px) * $width;
}

$photo-width-sm: 225px;
$photo-width-med: 350px;

.image-container {
  display: inline-block;
  background-color: $lll-color-grey-dark;
  overflow: hidden;
  width: $photo-width-sm;
  height: relative-photo-height($photo-width-sm);

  @media (min-width: 1100px) {
    width: $photo-width-med;
    height: relative-photo-height($photo-width-med);
  }
}

.image-preview {
  display: block;
  max-height: 840px;
  max-width: 100%;
}
</style>
