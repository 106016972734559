<template>
  <div class="profile-container">
    <h1 class="h-page-title">
      {{ content.mainHeading }}
    </h1>
    <div :class="loading ? 'lll-flash lll-disabled' : ''">
      <div class="profile-body-container">
        <!-- Removing Publish Status for now since we are no longer publishing -->
        <!-- <div v-if="loading" class="publish-status-container lll-padding-med lll-bg-white">
          <v-progress-linear v-if="loading" :indeterminate="true" color="secondary" />
        </div>
        <profile-details-publish-status
          v-else
          :ambassador="currentAmbassador"
          :loading.sync="loading"
          class="publish-status-container lll-bg-white"
        /> -->
        <profile-details-summary
          :ambassador="currentAmbassador"
          :loading.sync="loading"
          class="profile-summary-container"
        />
        <div class="profile-main-container">
          <profile-details-main
            ref="editFormContainer"
            :ambassador="currentAmbassador"
            :loading.sync="loading"
            :is-new-profile="isNewProfile"
          />
          <div class="container-ambassador-id lll-padding-med">
            <h3 class="h-secondary">{{ content.uuidLabel }} {{ currentAmbassador.id }}</h3>
            <h3 class="h-secondary">
              {{ content.oktaIdLabel }}
              {{ loading ? '' : currentAmbassador.okta_id || content.oktaIdDefault }}
            </h3>
          </div>
        </div>
      </div>
      <!-- ^ .profile-body-container -->
    </div>
    <!-- ^ loading animation -->
  </div>
  <!-- ^ .profile-container -->
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ProfileDetailsMain from '@/components/ProfileDetailsMain';
// import ProfileDetailsPublishStatus from '@/components/ProfileDetailsPublishStatus';
import ProfileDetailsSummary from '@/components/ProfileDetailsSummary';
import content from '@/content/staticContentForUI';

export default {
  name: 'ProfileDetails',

  components: {
    'profile-details-main': ProfileDetailsMain,
    // 'profile-details-publish-status': ProfileDetailsPublishStatus,
    'profile-details-summary': ProfileDetailsSummary,
  },

  beforeRouteLeave(routeTo, routeFrom, next) {
    if (this.$refs.editFormContainer.formIsInProgress) {
      // Trigger process to show error message for unsaved changes.
      this.$refs.editFormContainer.confirmRouteLeave(routeTo);
      next(false);
    } else {
      next();
    }
  },

  props: {
    id: {
      type: String,
      default: '',
    },
    isNewProfile: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      content: content.profileDetails,
      loading: false,
    };
  },

  computed: {
    ...mapGetters(['currentAmbassador']),
  },

  created() {
    // By setting loading status, child components can be prepped before user interacts with them.
    this.loading = true;

    // ...make API call, populate attributes, and update currentAmbassador in state.
    this.updateCurrentAmbassador(this.id)
      .then(() => {
        this.prepProfileDetails();
      })
      .catch(error => {
        this.loading = false;
        this.$handleErrorMessage(error);
      });
  },

  methods: {
    ...mapActions([
      'getAmbassadorDocuments',
      'getAmbassadorPhotos',
      'getAmbassadorTypes',
      'getStoresList',
      'getDisciplines',
      'getSubDisciplines',
      'getExperiences',
      'updateCurrentAmbassador',
      'getProductCategories',
    ]),

    prepProfileDetails() {
      /**
       * Dispatch async actions to get photos and documents while page is loading.
       */
      const photos = this.getAmbassadorPhotos(this.id);
      const documents = this.getAmbassadorDocuments(this.id);
      /**
       * Dispatch async actions to get selection items for form fields (drop-down lists) from
       * Ambassadors Service (API call not made if data already saved in state). Ensure these
       * items are loaded BEFORE the form fields are populated to avoid empty fields or errors.
       * This is only necessary for these four fields. Other fields (e.g., 'gender') get items
       * from 'src/content/inputSelectionItems.js'.
       */
      const stores = this.getStoresList();
      const types = this.getAmbassadorTypes();
      const disciplines = this.getDisciplines();
      const subDisciplines = this.getSubDisciplines();
      const experiences = this.getExperiences();
      const productCategories = this.getProductCategories();

      Promise.all([
        photos,
        documents,
        stores,
        types,
        disciplines,
        subDisciplines,
        experiences,
        productCategories,
      ])
        .then(() => {
          this.$refs.editFormContainer.prepFormsInProgress();
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          this.$handleErrorMessage(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.container-ambassador-id {
  background-color: $lll-color-grey-light;
}

.profile-container {
  padding: 0 5vw;
  height: 100%;
}

.profile-body-container {
  display: grid;
  grid-template:
    'summary'
    'main'
    /
    1fr;
  grid-gap: 10px;

  @media (min-width: 1000px) {
    grid-template:
      'summary main'
      '. main'
      /
      minmax(min-content, 275px) 1fr;
  }
}

.profile-main-container {
  grid-area: main;

  ::v-deep .grid-3-col-responsive,
  .grid-2-col-responsive {
    grid-column-gap: 50px;
    grid-row-gap: 0;
  }
}

.profile-summary-container {
  grid-area: summary;
}

.publish-status-container {
  grid-area: status;
}
</style>
