import { render, staticRenderFns } from "./UsersListFilters.vue?vue&type=template&id=671f1b2e&scoped=true"
import script from "./UsersListFilters.vue?vue&type=script&lang=js"
export * from "./UsersListFilters.vue?vue&type=script&lang=js"
import style0 from "./UsersListFilters.vue?vue&type=style&index=0&id=671f1b2e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "671f1b2e",
  null
  
)

export default component.exports