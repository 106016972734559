import Experience from '@/models/v2/Experience';

const initialState = () => {
  return {
    experiences: [],
  };
};

const actions = {
  async getExperiences({ commit, state }) {
    // check whether experiences have already been saved in state before making API call
    if (state.experiences.length) {
      return state.experiences;
    }
    // sort list by item label so UI displays consistent order
    const experiences = await Experience.query({ sort: 'label' });

    commit('experiences', experiences);
    return experiences;
  },
};

const getters = {
  experiences: state => state.experiences,
};

const mutations = {
  experiences(state, experiences) {
    state.experiences = experiences;
  },
  resetState(state) {
    Object.assign(state, initialState);
  },
};

const state = initialState;

export default {
  actions,
  getters,
  mutations,
  state,
};
