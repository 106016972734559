import AmbassadorsBase from '@/models/v2/AmbassadorsBase';

class ProductCategory extends AmbassadorsBase {
  static _type = 'product_categories';

  static name = 'product_category';

  static baseURL = '/product_categories';

  constructor(args = {}) {
    super(args);
    this.id = args.id;
    this.label = args.label;
    this.added = args.added;
  }
}

export default ProductCategory;
