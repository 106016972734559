function get(key) {
  let value;
  const windowConfig = window.__CONFIG__;
  if (windowConfig && key in windowConfig) {
    value = windowConfig[key];
  } else if (process.env[key]) {
    value = process.env[key];
  } else {
    throw new Error(`Cannot load config key ${key}`);
  }
  return value;
}

export default get;
