<template>
  <profile-details-form-field
    :show-form-input="showFormInput"
    :section="section"
    :attribute-id="attributeId"
    :display-data="ambassador[attributeId]"
    @edit="enableEdit"
  >
    <template #input>
      <input-phone
        ref="inputPhone"
        :current-ambassador-phone="section.formFields[attributeId]"
        :input-id="fieldId"
        :placeholder="fieldText[attributeId].placeholder"
        :disabled="!section.editing"
        @phoneNumber="populatePhoneNumber"
        @validationStatus="handlePhoneValidation"
        @resetValidation="phoneErrorMsg.show = false"
      />
      <div v-if="phoneErrorMsg.show" class="lll-input-validation-error">
        {{ phoneErrorMsg.msgText }}
      </div>
      <div v-else class="pa-3">
        <!--Empty div has a purpose (feel free to replace it with better solution).-->
        <!--Keeps consistent spacing between elements when error msg is hidden.-->
        <!--Needed for custom inputs. Vuetify inputs don't need this placeholer.-->
      </div>
    </template>
  </profile-details-form-field>
</template>

<script>
import { customMessages } from '@/utils/errorHandlers';
import content from '@/content/staticContentForUI';
import * as formHelpers from '@/utils/formHelpers';
import InputPhone from '@/components/InputPhone';
import ProfileDetailsFormField from '@/components/ProfileDetailsFormField';

export default {
  name: 'ProfileDetailsPhone',

  components: {
    'input-phone': InputPhone,
    'profile-details-form-field': ProfileDetailsFormField,
  },

  props: {
    ambassador: {
      required: true,
      type: Object,
      default: () => {},
    },
    attributeId: {
      required: true,
      type: String,
      default: '',
    },
    section: {
      required: true,
      type: Object,
      default: () => {},
    },
    showFormInput: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      fieldText: content.formFields,
      phoneErrorMsg: {
        msgText: '',
        show: false,
      },
    };
  },

  computed: {
    fieldId() {
      return `update-${formHelpers.convertSnakeToKebab(this.attributeId)}`;
    },
  },

  methods: {
    enableEdit() {
      this.$emit('edit');
    },

    // handles data passed to parent from <input-phone /> component
    handlePhoneValidation(validationStatus) {
      const { isPresent, isValid } = validationStatus;
      const fieldDetails = formHelpers.filterFieldDetailsByProperty(
        'attributeId',
        this.attributeId
      );
      const isRequired = fieldDetails[0].validation.includes('required');

      if (isRequired && !isPresent) {
        this.phoneErrorMsg = {
          msgText: customMessages.profileFormFields.required,
          show: true,
        };
        return;
      }

      if (!isValid) {
        this.phoneErrorMsg = {
          msgText: customMessages.profileFormFields.phone,
          show: true,
        };
        return;
      }

      this.phoneErrorMsg.show = false;
    },

    // handles data passed to parent from <input-phone /> component
    populatePhoneNumber(number) {
      this.section.formFields[this.attributeId] = number;
    },

    resetValidation() {
      this.$refs.inputPhone.resetValidation();
    },

    validate() {
      return this.$refs.inputPhone.validate();
    },
  },
};
</script>
