<template>
  <div>
    <abbr v-show="showAsterisk" title="required" class="lll-asterisk">*</abbr>
    <span v-if="!inputId" class="h-label">{{ labelText }}</span>
    <label v-else :for="inputId" class="h-label">{{ labelText }}</label>
  </div>
</template>

<script>
/**
 * This reusable component generates markup for either an input <label> or <span> element.
 * This makes it easier to update markup and styles for all labels consistently.
 */
export default {
  name: 'AMBInputLabel',

  props: {
    /**
     * String to be used for input element's 'id' attribute (should be kebab-case and lowercase).
     * If not provided, assume the semantic <label> element is not needed and use <span> instead.
     */
    inputId: {
      type: String,
      default: '',
    },
    /**
     * String to be displayed as label.
     */
    labelText: {
      required: true,
      type: String,
      default: '',
    },
    /**
     * Determines if asterisk will be displayed next to field label.
     */
    showAsterisk: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
