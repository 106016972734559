<template>
  <div class="grid-3-col-responsive break-at-1200">
    <!-- AMBASSADOR DETAILS -->
    <h2 class="h-primary section-header top row">
      {{ content.formSectionHeaders.ambassadorDetails }}
    </h2>
    <profile-details-form-field
      :show-form-input="showFormInput('first_name')"
      :section="section"
      field-type="text"
      attribute-id="first_name"
      :display-data="ambassador.first_name"
      @edit="enableEdit"
    />
    <profile-details-form-field
      :show-form-input="showFormInput('last_name')"
      :section="section"
      field-type="text"
      attribute-id="last_name"
      :display-data="ambassador.last_name"
      @edit="enableEdit"
    />
    <profile-details-form-field
      :show-form-input="showFormInput('ambassador_type')"
      :section="section"
      field-type="select"
      attribute-id="ambassador_type"
      :display-data="ambassador.ambassador_type.label"
      :items="itemsAmbassadorType"
      :return-object="true"
      :no-data-text="noDataText.default"
      validate-on-change
      @edit="enableEdit"
    />
    <profile-details-form-field
      :show-form-input="showFormInput('store_id')"
      :section="section"
      field-type="autocomplete"
      attribute-id="store_id"
      :display-data="storeName"
      :items="itemsAmbassadorStore"
      item-text="name"
      :no-data-text="noDataText.default"
      validate-on-change
      @edit="enableEdit"
    />
    <profile-details-form-field
      :show-form-input="showFormInput('main_discipline')"
      :section="section"
      field-type="autocomplete"
      attribute-id="main_discipline"
      :display-data="ambassador.sub_discipline.parent_discipline.label"
      :items="disciplines"
      :return-object="true"
      :no-data-text="noDataText.default"
      validate-on-change
      @edit="enableEdit"
    />
    <profile-details-form-field
      :show-form-input="showFormInput('sub_discipline')"
      :section="section"
      field-type="autocomplete"
      attribute-id="sub_discipline"
      :display-data="ambassador.sub_discipline.label"
      :items="itemsSubDiscipline"
      :return-object="true"
      :no-data-text="noDataTextSubDiscipline"
      validate-on-change
      @edit="enableEdit"
    />
    <!-- Bio fields are temporarily hidden. See https://lululemon.atlassian.net/browse/COCO-3291 -->
    <!-- <div
      v-for="field in bioFields"
      :key="field.attributeId"
      class="row"
      :class="getBioFieldClass(field.attributeId)"
    >
      <profile-details-form-field
        :show-form-input="showFormInput(field.attributeId)"
        :section="section"
        field-type="textarea"
        :attribute-id="field.attributeId"
        custom-display-data
        :rows="field.attributeId === 'biography' ? '15' : '2'"
        :hint="getWordCounterResults(field.attributeId)"
        @edit="enableEdit"
      >
        <template #display-data>
          <amb-pending-label
            v-if="fieldIsEmpty(field.attributeId) && !userPermissions.canEditRecords"
            class="lll-form-field-display-data"
          />
          <span v-else class="preserve-white-space break-word">{{
            ambassador[field.attributeId]
          }}</span>
        </template>
      </profile-details-form-field>
      <div v-show="showFormInput(field.attributeId)" class="hint-text mt-1">
        <h3 class="h-label">
          {{ content.formFieldNoteLabel }}
        </h3>
        <span class="preserve-white-space">{{ field.fieldText.note }}</span>
      </div>
    </div> -->
    <!-- SOCIAL -->
    <h2 class="h-primary section-header row">
      {{ content.formSectionHeaders.social }}
    </h2>
    <profile-details-form-field
      v-for="field in socialLinks"
      :key="field.attributeId"
      :show-form-input="showFormInput(field.attributeId)"
      :section="section"
      :attribute-id="field.attributeId"
      custom-display-data
      @edit="enableEdit"
    >
      <template #input>
        <v-text-field
          :id="`update-${field.attributeId}`"
          v-model.trim="section.formFields[field.attributeId]"
          :rules="field.validation"
          validate-on-blur
          solo
          flat
          :hint="field.fieldText.hint"
          persistent-hint
          :placeholder="field.fieldText.placeholder"
          browser-autocomplete="off"
          :disabled="!section.editing"
        >
          <span slot="prepend-inner">{{ field.fieldText.prependInner }}</span>
        </v-text-field>
      </template>
      <template #display-data>
        <amb-pending-label
          v-if="fieldIsEmpty(field.attributeId) && !userPermissions.canEditRecords"
          class="lll-form-field-display-data"
        />
        <div v-else-if="!field.fieldText.hrefDomain" class="lll-form-field-display-data">
          {{ ambassador[field.attributeId] }}
        </div>
        <a
          v-else
          :href="getSocialLink(field.attributeId)"
          target="_blank"
          class="lll-link social-link"
        >
          {{ ambassador[field.attributeId] }}
        </a>
      </template>
    </profile-details-form-field>
    <profile-details-form-field
      :show-form-input="showFormInput('website')"
      :section="section"
      field-type="text"
      attribute-id="website"
      custom-display-data
      @edit="enableEdit"
    >
      <template #display-data>
        <amb-pending-label
          v-if="fieldIsEmpty('website') && !userPermissions.canEditRecords"
          class="lll-form-field-display-data"
        />
        <a
          v-else
          :href="ambassador.website"
          target="_blank"
          class="lll-link social-link preserve-white-space break-word"
        >
          {{ ambassador.website }}
        </a>
      </template>
    </profile-details-form-field>
    <profile-details-form-field
      :show-form-input="showFormInput('instagram_followers')"
      :section="section"
      field-type="text"
      attribute-id="instagram_followers"
      :display-data="instagramFollowers"
      @edit="enableEdit"
    />
    <profile-details-form-field
      :show-form-input="showFormInput('product_categories')"
      :section="section"
      attribute-id="product_categories"
      custom-display-data
      class="lll-form-field-bottom-padding"
      @edit="enableEdit"
    >
      <template #input>
        <div class="product-categories-input">
          <span class="hint-text">{{ fieldText.product_categories.note }}</span>
          <v-checkbox
            v-for="(item, idx) in productCategories"
            :key="item.id"
            v-model="section.formFields.product_categories"
            :label="productCategories[idx].label"
            :value="productCategories[idx]"
            multiple
            hide-details
            color="primary"
            class="lll-checkbox lll-checkbox-align-start"
            :disabled="!section.editing"
          />
        </div>
      </template>
      <template #display-data>
        <amb-pending-label
          v-if="fieldIsEmpty('product_categories') && !userPermissions.canEditRecords"
          class="lll-form-field-display-data"
        />
        <div v-else-if="ambassador.product_categories.length" class="form-field-display-data">
          <ul class="list-unstyled">
            <li
              v-for="item in ambassador.product_categories"
              :key="item.id"
              class="list-item-icon-with-text"
            >
              <v-icon color="secondary" class="pr-2">
                check_box
              </v-icon>
              {{ item.label }}
            </li>
          </ul>
        </div>
      </template>
    </profile-details-form-field>
    <div class="row grid-3-col-responsive">
      <div class="col-span-2">
        <profile-details-form-field
          :show-form-input="showFormInput('affiliated_brands')"
          :section="section"
          field-type="text"
          attribute-id="affiliated_brands"
          :display-data="ambassador.affiliated_brands"
          @edit="enableEdit"
        />
      </div>
    </div>
    <profile-details-form-field
      :show-form-input="showFormInput('mirror')"
      :section="section"
      attribute-id="mirror"
      custom-label
      custom-display-data
      class="lll-form-field-bottom-padding"
      @edit="enableEdit"
    >
      <template #input>
        <v-checkbox
          v-model="section.formFields.mirror"
          :label="fieldText.mirror.label"
          :value="ambassador.mirror"
          :hint="fieldText.mirror.hint"
          persistent-hint
          color="primary"
          class="lll-checkbox lll-checkbox-align-start"
          :disabled="!section.editing || !userIsSSC"
        />
      </template>
      <template #display-data>
        <div v-if="fieldIsEmpty('mirror') && !userPermissions.canEditRecords">
          <amb-form-field-label attribute-id="mirror" />
          <amb-pending-label class="lll-form-field-display-data" />
        </div>
        <div v-else-if="ambassador.mirror" class="form-field-display-data">
          <v-icon color="secondary" class="pr-2">check_box</v-icon>
          {{ fieldText.mirror.label }}
        </div>
      </template>
    </profile-details-form-field>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as formHelpers from '@/utils/formHelpers';
import content from '@/content/staticContentForUI';
import AMBFormFieldLabel from '@/components/AMBFormFieldLabel';
import AMBPendingLabel from '@/components/AMBPendingLabel';
import ProfileDetailsFormField from '@/components/ProfileDetailsFormField';

export default {
  name: 'ProfileDetailsPublic',

  components: {
    'amb-form-field-label': AMBFormFieldLabel,
    'amb-pending-label': AMBPendingLabel,
    'profile-details-form-field': ProfileDetailsFormField,
  },

  props: {
    ambassador: {
      required: true,
      type: Object,
      default: () => {},
    },
    section: {
      required: true,
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      content: content.profileDetailsMain,
      fieldText: content.formFields,
      inputSelectionItems: content.inputSelectionItems,
      noDataText: content.noDataText,
    };
  },

  computed: {
    ...mapGetters([
      'ambassadorTypes',
      'disciplines',
      'subDisciplines',
      'storesList',
      'userClaims',
      'userPermissions',
      'productCategories',
    ]),

    // Bio fields are temporarily hidden, so this is not needed for now. See https://lululemon.atlassian.net/browse/COCO-3291
    // bioFields() {
    //   return formHelpers.filterFieldDetailsByProperty('tags', 'bio');
    // },

    socialLinks() {
      return formHelpers.filterFieldDetailsByProperty('tags', 'social');
    },

    instagramFollowers() {
      const { instagram_followers } = this.ambassador;

      return instagram_followers !== null && instagram_followers !== undefined
        ? instagram_followers.toString()
        : '';
    },

    itemsAmbassadorStore() {
      return formHelpers.filterItemsForStoreField(
        this.userClaims.ambassador_update,
        this.section.formFields.ambassador_type.id,
        this.storesList
      );
    },

    itemsAmbassadorType() {
      return formHelpers.filterItemsForTypeField(
        this.userClaims.ambassador_update,
        this.ambassadorTypes
      );
    },

    /**
     * Filter the selection list for 'Sub Discipline' based on selected 'Main Discipline'.
     * For example, if Yoga is chosen, only yoga-related subDisciplines should be shown.
     */
    itemsSubDiscipline() {
      return this.subDisciplines.filter(
        item => item.parent_discipline.id === this.section.formFields.main_discipline.id
      );
    },

    noDataTextSubDiscipline() {
      const mainDiscipline = this.section.formFields.main_discipline;

      return mainDiscipline ? this.noDataText.default : this.noDataText.subDiscipline;
    },

    storeName() {
      return this.ambassador.store ? this.ambassador.store.name : '';
    },

    // This is used to disable the "Mirror" field on edit form for users below SSC level.
    userIsSSC() {
      return this.userPermissions.canEditReadyForReviewStatus;
    },
  },

  watch: {
    itemsAmbassadorStore(newValue) {
      /**
       * When 'Ambassador Type' changes, the selection list for 'Ambassador Store' will change.
       * This watcher resets the 'Ambassador Store' field so the user will be prompted to
       * select a new 'Ambassador Store'.
       */
      const storeItems = newValue;
      let found = false;

      for (const item of storeItems) {
        if (item.id === this.section.formFields.store_id) {
          found = true;
          break;
        }
      }

      if (!found) {
        this.section.formFields.store_id = '';
      }
    },

    itemsSubDiscipline(newValue) {
      /**
       * When 'Main Discipline' changes, the selection list for 'Sub Discipline' will change.
       * This watcher resets the 'Sub Discipline' field so the user will be prompted to
       * select a new 'Sub Discipline'.
       */
      const subDisciplineItems = newValue;
      let found = false;

      for (const item of subDisciplineItems) {
        const subDisciplineId = this.section.formFields.sub_discipline.id;

        if (!subDisciplineId || item.id === subDisciplineId) {
          found = true;
          break;
        }
      }

      if (!found) {
        this.section.formFields.sub_discipline = '';
      }
    },
  },

  methods: {
    enableEdit() {
      this.$emit('edit');
    },

    fieldIsEmpty(attributeId) {
      return formHelpers.fieldIsEmpty(attributeId, this.ambassador);
    },

    // Bio fields are temporarily hidden, so this is not needed for now. See https://lululemon.atlassian.net/browse/COCO-3291
    // getBioFieldClass(attributeId) {
    //   return this.showFormInput(attributeId)
    //     ? 'grid-2-col-responsive lll-form-field-bottom-margin'
    //     : '';
    // },

    getSocialLink(attributeId) {
      const { hrefDomain } = this.fieldText[attributeId];

      return `https://www.${hrefDomain}${this.ambassador[attributeId]}`;
    },

    // Bio fields are temporarily hidden, so this is not needed for now. See https://lululemon.atlassian.net/browse/COCO-3291
    // getWordCounterResults(attributeId) {
    //   const { hint, wordLimit } = this.fieldText[attributeId];
    //   const inputValue = this.section.formFields[attributeId];

    //   return `${hint} ${formHelpers.countWordsInString(inputValue)}/${wordLimit}`;
    // },

    showFormInput(attributeId) {
      if (this.userPermissions && !this.userPermissions.canEditRecords) {
        return false;
      }

      const inEditMode = this.section.editing;
      /**
       * If field is in 'edit mode', show input.
       * If data not yet saved with ambassador record, show input.
       */
      return inEditMode || formHelpers.fieldIsEmpty(attributeId, this.ambassador);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/forms-inputs.scss';

.social-link {
  position: relative;
  z-index: 200;
}

.product-categories-input {
  padding: 5px 0 0 10px;
}
</style>
