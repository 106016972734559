import _ from 'lodash';
import { Model } from '@itsfadnis/jsonapi-client';

const { cloneDeep, isArray } = _;

Model.deserializerOptions.keyForAttribute = 'snake_case';

class Base extends Model {
  constructor(args) {
    if (args === null) {
      args = {};
    }

    super(args);

    Object.keys(args).forEach(key => {
      const prop = args[key];
      if (isArray(prop)) {
        args[key] = prop.filter(item => item !== null);
      }
    });
  }

  _create() {
    return super._create();
  }

  serializerOptions() {
    const opts = super.serializerOptions();

    opts.typeForAttribute = (str, attrVal) => {
      if (attrVal.constructor._type) {
        return attrVal.constructor._type;
      }

      return `${attrVal.constructor.name}s`;
    };

    opts.keyForAttribute = 'snake_case';

    return opts;
  }

  static deserializerOptions = {
    keyForAttribute: 'snake_case',
  };

  setRelationship(propName, value) {
    this[propName] = value;
    this[propName][this.constructor.symbols.relationship] = value.constructor;
  }

  async save() {
    // need to make sure that if we assigned a new relationship that it has the
    // required symbol set in the right place
    const relationshipKeys = this.constructor.keysForRelationships();
    for (const key of relationshipKeys) {
      if (!this[key][this.constructor.symbols.relationship]) {
        this[key][this.constructor.symbols.relationship] = this[key].constructor;
      }
    }

    const object = await super.save();
    // update an original object
    this.id = object.id;
    for (const key in this) {
      if (this.isAttribute(key)) {
        if (object[key] !== this[key]) {
          this[key] = cloneDeep(object[key]);
        }
      }
    }
    this.persisted = true;
    return this;
  }

  static fetch(id, args = {}) {
    const include = args.include ? `?include=${args.include}` : '';

    if (this.adapter === undefined) {
      throw new Error('Adapter property on model not set');
    }

    return this.adapter
      .get(`${this.constructBaseURL(args)}/${id}${include}`)
      .then(({ data }) => this.deserialize(data))
      .then(object => object)
      .catch(err => {
        throw err;
      });
  }
}

export default Base;
