import PermissionsBase from '@/models/PermissionsBase';
import Claim from '@/models/Claim';

class Role extends PermissionsBase {
  static _type = 'roles';

  static name = 'role';

  static baseURL = '/roles';

  constructor(args = {}) {
    super(args);

    this.id = args.id;
    this.label = args.label;
    this.name = args.name;
    this.description = args.description;

    // Related Models
    this.claims = this.hasMany(Claim, args.claims);
  }
}

export default Role;
