<template>
  <div>
    <v-autocomplete
      :id="inputId"
      ref="addressSearchInput"
      v-model="selectedAddressId"
      :items="addressList"
      item-text="FullText"
      item-value="Id"
      :no-data-text="countryId ? noDataText.addressSearch : noDataText.addressMissingCountry"
      :search-input.sync="addressSearch"
      browser-autocomplete="off"
      solo
      flat
      :placeholder="fieldText.placeholder"
      :hint="fieldText.hint"
      persistent-hint
      prepend-inner-icon="search"
      clearable
      menu-props="offset-y"
      :disabled="disabled"
    />
  </div>
</template>

<script>
/**
 * This component uses the Canada Post AddressComplete service.
 * API documentation: https://www.canadapost.ca/pca/support/webservices/
 */

import axios from 'axios';
import get from '@/utils/config';
import { customMessages } from '@/utils/errorHandlers';
import content from '@/content/staticContentForUI';

export default {
  name: 'InputAddressSearch',

  props: {
    countryId: {
      type: String,
      required: true,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inputId: {
      type: String,
      default: 'address-search',
    },
  },

  data() {
    return {
      addressList: [],
      addressSearch: '',
      errorMessageCopy: customMessages.profileFormFields.addressSearch,
      fieldText: content.formFields.address_search,
      noDataText: content.noDataText,
      selectedAddressId: '',
      selectedAddressDetails: {},
    };
  },

  watch: {
    addressSearch(inputValue) {
      if (!inputValue) {
        return;
      }

      if (!this.countryId) {
        const basicConfig = this.$createErrorSnackbar(this.errorMessageCopy.countryMissing);

        this.$swal({
          ...basicConfig,
          confirmButtonText: content.formErrorSnackbar.confirmButton,
        });
      } else {
        this.getAddressSelections(inputValue);
      }
    },

    selectedAddressDetails(addressObj) {
      if (!addressObj) {
        return;
      }

      this.$emit('addressSelected', addressObj);
    },

    selectedAddressId(inputValue) {
      if (!inputValue) {
        return;
      }

      this.getAddressDetails(inputValue);
    },
  },

  methods: {
    async getAddressDetails(inputValue) {
      this.selectedAddressDetails = await this.getAddressById(inputValue);
    },

    async getAddressSelections(inputValue) {
      this.addressList = await this.getAddressList(inputValue);
    },

    async getAddressById(addressId) {
      const url =
        'https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Retrieve/v2.11/json3.ws';

      const paramsObj = {
        params: {
          // required for successfull request
          Key: get('VUE_APP_ADDRESS_COMPLETE_KEY'),
          Id: addressId,

          // not required but included here for better reporting
          Application: 'AmbassadorsInput',
        },
      };

      let response;

      try {
        response = await axios.get(url, paramsObj);
        return response.data.Items[0];
      } catch (error) {
        return [];
      }
    },

    async getAddressList(inputValue) {
      const url =
        'https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Find/v2.10/json3.ws';

      const paramsObj = {
        params: {
          // required for successfull request
          Key: get('VUE_APP_ADDRESS_COMPLETE_KEY'),
          SearchTerm: inputValue,

          // not required but included here for better performance
          Country: this.countryId,
          LanguagePreference: 'en',
          MaxSuggestions: 10,
          MaxResults: 10,
        },
      };

      let response;
      let errorInResponse;

      try {
        response = await axios.get(url, paramsObj);
        errorInResponse = Object.keys(response.data.Items[0]).includes('Error');

        if (errorInResponse) {
          const basicConfig = this.$createErrorSnackbar(this.errorMessageCopy.searchFailed);

          this.$swal({
            ...basicConfig,
            confirmButtonText: content.formErrorSnackbar.confirmButton,
          });

          // tell parent component to allow manual address entry
          this.$emit('allowManualAddressEntry');

          return [];
        }

        return response.data.Items.filter(item => {
          return item.Next === 'Retrieve';
        }).map(item => {
          const address = { ...item };
          address.FullText = `${item.Text}, ${item.Description}`;
          return address;
        });
      } catch (error) {
        return [];
      }
    },
  },
};
</script>
