<template>
  <div>
    <div v-if="thumbnail" class="image-container">
      <div
        :class="circle ? 'thumbnail-responsive circle' : 'thumbnail-responsive'"
        :style="backgroundImage"
        role="img"
        :aria-label="altText || ''"
      />
    </div>
    <div v-else :class="circle ? 'image-container circle' : 'image-container portrait'">
      <img
        :alt="altText || ''"
        :src="imageSrc"
        :class="circle ? 'image-responsive circle' : 'image-portrait'"
        @error="showFallbackImage"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AMBImage',

  props: {
    altText: {
      type: String,
      default: '',
    },
    circle: {
      type: Boolean,
      default: false,
    },
    /**
     * Object with photo attributes as delivered from service.
     * e.g.: {
     *         id: "string-with-unique-id-for-image-file",
     *         publish_url: "https://s7d2.scene7.com/is/image/...",
     *         url: "https://lll-nonprod-src-ambassadors-us-west-2.s3.amazonaws.com/...",
     *         added: "date-added",
     *         tags: [Array of PhotoTag elements],
     *         baseURL: "/photo_gallery/string-with-unique-id"
     *       }
     */
    imageObject: {
      type: Object,
      default: () => null,
    },
    imageUrl: {
      type: String,
      default: null,
    },
    imageViewAttributes: {
      type: String,
      default: '',
    },
    thumbnail: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      defaultImage: 'https://images.lululemon.com/is/image/lululemon/amb-default-image',
    };
  },

  computed: {
    backgroundImage() {
      return `background-image: url(${this.imageSrc}), url(${this.defaultImage}?${
        this.imageViewAttributes
      }); background-position: center center; background-size: cover;`;
    },

    imageSrc() {
      /**
       * Prefer using 'publish_url' to display profile photo. This is the public photo shown
       * on published profiles. Since published photo comes from Scene7, we can use image
       * view attributes to optimize photo for better performance. If scene7 'publish_url' is
       * missing, fallback to s3 'url' then to default image.
       *
       * Scene7 docs: https://marketing.adobe.com/resources/help/en_US/s7/is_ir_api/ir_api/http_protocol/r_ir_view_attributes.html
       */

      if (this.publishUrl) {
        return this.imageViewAttributes
          ? `${this.publishUrl}?${this.imageViewAttributes}`
          : this.publishUrl;
      }

      if (this.uploadUrl) {
        return this.uploadUrl;
      }

      if (this.imageUrl) {
        return this.imageUrl;
      }

      return this.defaultImage;
    },

    // photo URL from Scene7 that displays on published profile
    publishUrl() {
      return this.imageObject ? this.imageObject.publish_url : '';
    },

    // photo URL from s3 (not published)
    uploadUrl() {
      return this.imageObject ? this.imageObject.url : '';
    },
  },

  methods: {
    showFallbackImage(e) {
      e.target.src = this.defaultImage;
    },
  },
};
</script>

<style lang="scss" scoped>
.image-container {
  background-color: $lll-color-grey-light;
  height: 100%;
  width: 100%;

  &.portrait {
    height: 0;
    line-height: 0;
    overflow: hidden;
    padding-bottom: (375/312) * 100%; // set aspect ratio so image keeps "portrait" orientation
    position: relative;
  }
}

img {
  &.image-responsive {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &.image-portrait {
    display: block;
    left: 0;
    min-height: 100%;
    object-fit: cover;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
}

.thumbnail-responsive {
  width: 100%;
  padding-bottom: 100%;
}

.circle,
img.circle {
  border-radius: 50%;
}
</style>
