<template>
  <div class="container-users-main">
    <h1 class="h-page-title">
      {{ content.mainHeading }}
    </h1>
    <users-list-search class="container-search" @search="search" @clear="clearSearchResults" />
    <users-list-filters class="container-filters" @filter-by="filterUsers" />
    <div v-if="loading" class="container-list lll-padding-lg text-xs-center">
      <v-progress-circular :indeterminate="true" size="50" />
    </div>
    <div v-else-if="needsRole" class="container-list lll-padding-lg text-sm-center">
      <p>{{ content.userNeedsRole }}</p>
      <router-link
        :to="{
          name: 'userProfileCreate',
          query: { searched: 1 },
        }"
        class="btn btn-primary"
      >
        {{ content.addUserButton }}
      </router-link>
    </div>
    <div v-else-if="needsProfile" class="container-list lll-padding-lg">
      <p class="h-section-title">{{ content.userNeedsProfile.mainMessage }} "{{ needsProfile }}"</p>
      <p class="h-primary">
        {{ content.userNeedsProfile.helpHeading }}
      </p>
      <ul>
        <li>{{ content.userNeedsProfile.helpText1 }}</li>
        <li>{{ content.userNeedsProfile.helpText2 }}</li>
        <li>{{ content.userNeedsProfile.helpText3 }}</li>
        <li>{{ content.userNeedsProfile.helpText4 }}</li>
      </ul>
    </div>

    <div v-else class="container-list">
      <users-list-results
        :search-results="searchResults"
        :items="usersToDisplay"
        @clearSearchResults="clearSearchResults"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import content from '@/content/staticContentForUI';
import UsersListFilters from '@/components/UsersListFilters';
import UsersListSearch from '@/components/UsersListSearch';
import UsersListResults from '@/components/UsersListResults';

export default {
  name: 'UsersList',

  components: {
    'users-list-filters': UsersListFilters,
    'users-list-search': UsersListSearch,
    'users-list-results': UsersListResults,
  },

  data() {
    return {
      content: content.usersList,
      loading: false,
    };
  },

  computed: {
    ...mapGetters(['filterBy', 'needsProfile', 'needsRole', 'roles', 'searchResults', 'users']),

    usersToDisplay() {
      if (this.filterBy?.length) {
        return this.filterUsers(this.filterBy);
      }

      return this.users;
    },
  },

  created() {
    if (!this.searchResults.length) {
      this.prepListing();
    }
  },

  methods: {
    ...mapActions(['searchForUser', 'getUsers', 'getRoles']),

    filterUsers(rolesToFilterBy) {
      if (!rolesToFilterBy?.length) {
        return this.users;
      }

      return this.users.filter(user => rolesToFilterBy.includes(user.role.id));
    },

    prepListing() {
      this.loading = true;

      const users = this.getUsers();
      const roles = this.getRoles();

      Promise.all([users, roles])
        .then(() => {
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          this.$handleErrorMessage(error);
        });
    },

    search(email) {
      this.clearSearchResults();

      if (!email) {
        return;
      }

      this.loading = true;

      this.searchForUser(email)
        .then(() => {
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          this.$handleErrorMessage(error);
        });
    },

    clearSearchResults() {
      this.$store.commit('searchResults', []);
      this.$store.commit('needsRole', '');
      this.$store.commit('needsProfile', '');
    },
  },
};
</script>

<style>
/* These styles cannot be scoped, otherwise Vuetify styles will take precedence */
.v-data-iterator__actions {
  font-size: 16px;
}
</style>

<!-- Styles cannot be scoped as v-data-iterator does not read scoped styles -->
<style lang="scss">
.container-users-main {
  display: grid;
  grid-template:
    'title'
    'search'
    'filters'
    'list' / 1fr;
  grid-template-rows: min-content;
  grid-row-gap: 25px;
  padding: 0 5vw;

  @media (min-width: 900px) {
    grid-template:
      'title title search'
      'filters . list' / minmax(300px, min-content) 50px 1fr;
  }

  > .h-page-title {
    grid-area: 1 / 1 / 1 / 4;

    @media (max-width: 900px) {
      margin: 0;
    }
  }
}

.container-filters {
  grid-area: filters;
}

.container-list {
  grid-area: list;
}

.container-search {
  grid-area: search;

  @media (min-width: 900px) {
    justify-self: end;
  }
}

.user-card-grid {
  display: grid;
  grid-gap: 25px;
  grid-template-columns: 1fr;
  margin-bottom: 25px;

  @media (min-width: 475px) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}
</style>
