import axios from 'axios';
import AmbassadorsBase from '@/models/v2/AmbassadorsBase';

class Document extends AmbassadorsBase {
  static _type = 'documents';

  static name = 'document';

  static baseURL = '/documents/:ambassador_id';

  constructor(args = {}) {
    if (args === null) {
      args = {};
    }
    super(args);
    this.id = args.id;
    this.added = args.added;
    this.filename = args.filename;
    this.path = args.path;

    this.baseURL = this.constructor.constructBaseURL(args);
  }

  constructBaseURL() {
    return this.baseURL;
  }

  static async deleteDocument(args = {}) {
    return await this.adapter.delete(`/documents/${args.ambassador_id}/${args.document_id}`, args);
  }

  static async getSignedURL(ambassadorId, file) {
    const args = {
      params: {
        content_type: file.type,
        filename: file.name,
      },
    };

    return await this.adapter.get(`/signed_urls/${ambassadorId}/document`, args);
  }

  static async newDocument(ambassadorId, file) {
    const signedURL = await this.getSignedURL(ambassadorId, file)
      .then(response => response.data)
      .catch(error => {
        throw error;
      });

    await this.uploadFile(signedURL, file).catch(error => {
      throw error;
    });

    const args = {
      ambassador_id: ambassadorId,
      path: signedURL.data.attributes.fields.key,
      filename: file.name,
    };

    const doc = new Document(args);

    await doc.save();

    return doc;
  }

  // use signedURL to upload file to S3 bucket
  static async uploadFile(signedURL, file) {
    const formData = new FormData();

    // eslint-disable-next-line guard-for-in
    for (const key in signedURL.data.attributes.fields) {
      formData.append(key, signedURL.data.attributes.fields[key]);
    }

    formData.append('file', file);

    await axios.post(signedURL.data.attributes.url, formData);
  }
}

export default Document;
