import SubDiscipline from '@/models/v2/SubDiscipline';

const initialState = () => {
  return {
    subDisciplines: [],
  };
};

const actions = {
  async getSubDisciplines({ commit, state }) {
    // check whether disciplines have already been saved in state before making API call
    if (state.subDisciplines.length) {
      return state.subDisciplines;
    }

    const subDisciplines = await SubDiscipline.fetchAll();
    commit('subDisciplines', subDisciplines);
    return subDisciplines;
  },

  async getSubDisciplineById({ dispatch }, subDisciplineId) {
    const subDisciplineRegistry = await dispatch('loadSubDisciplineRegistry');
    let subDiscipline = subDisciplineRegistry[subDisciplineId];

    if (subDiscipline === undefined) {
      subDiscipline = {
        id: subDisciplineId,
        label: 'Unknown Sub-discipline',
      };
    }

    return subDiscipline;
  },

  async loadSubDisciplineRegistry({ dispatch, commit, state }) {
    if ('subDisciplineRegistry' in state) {
      return state.subDisciplineRegistry;
    }

    const subDisciplines = await dispatch('getSubDisciplines');
    const subDisciplineRegistry = {};

    for (const subDiscipline of subDisciplines) {
      subDisciplineRegistry[subDiscipline.id] = subDiscipline;
    }

    commit('subDisciplineRegistry', subDisciplineRegistry);
    return subDisciplineRegistry;
  },
};

const getters = {
  subDisciplines: state => state.subDisciplines,
};

const mutations = {
  subDisciplines(state, subDisciplines) {
    state.subDisciplines = subDisciplines;
  },
  subDisciplineRegistry(state, subDisciplineRegistry) {
    state.subDisciplineRegistry = subDisciplineRegistry;
  },
  resetState(state) {
    Object.assign(state, initialState);
  },
};

const state = initialState;

export default {
  actions,
  getters,
  mutations,
  state,
};
