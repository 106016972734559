import Country from '@/models/v2/Country';

const initialState = () => {
  return {
    ambassadorCountryIdSet: new Set(),
  };
};

const actions = {
  async getAmbassadorCountryIds({ commit, state }) {
    // check whether country ids are already saved in state
    if (state.ambassadorCountryIdSet.size !== 0) {
      return state.ambassadorCountryIdSet;
    }

    const ambassadorCountries = await Country.fetchAll();
    const ambassadorCountryIdSet = new Set();

    for (const item of ambassadorCountries) {
      ambassadorCountryIdSet.add(item.id);
    }

    commit('ambassadorCountryIdSet', ambassadorCountryIdSet);
    return ambassadorCountryIdSet;
  },
};

const getters = {
  ambassadorCountryIdSet: state => state.ambassadorCountryIdSet,
};

const mutations = {
  ambassadorCountryIdSet(state, ambassadorCountryIdSet) {
    state.ambassadorCountryIdSet = ambassadorCountryIdSet;
  },
  resetState(state) {
    Object.assign(state, initialState);
  },
};

const state = initialState;

export default {
  actions,
  getters,
  mutations,
  state,
};
