import AmbassadorsBase from '@/models/v2/AmbassadorsBase';

class PhotoTag extends AmbassadorsBase {
  static _type = 'photo_tags';

  static name = 'photo_tag';

  static baseURL = '/photo_tags';

  constructor(args = {}) {
    super(args);
    this.id = args.id;
    this.label = args.label;
  }
}

export default PhotoTag;
