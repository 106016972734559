<template>
  <div>
    <div class="menu-header menu-header-mobile flex-space-between lll-hr-grey">
      <div class="list-count mb-3">{{ listLength }} {{ usersFound }}</div>
      <button
        v-if="searchResults.length"
        class="btn-link"
        name="clear-search-results"
        @click="emitClearSearch"
      >
        <v-icon>clear</v-icon>
        {{ content.clearButton }}
      </button>
    </div>

    <!-- USER SEARCH RESULTS  -->
    <div v-if="searchResults.length">
      <v-data-iterator content-class="user-card-grid" :items="searchResults">
        <template #item="user">
          <users-list-card
            :user-id="user.item.custom_permission_id"
            :role="user.item.role"
            :store="user.item.store"
            :name="user.item.name"
            :email="user.item.email"
            :job-title="user.item.title"
          />
        </template>
      </v-data-iterator>
    </div>

    <!-- DEFAULT USERS LIST -->
    <div v-else-if="items.length">
      <v-data-iterator
        :items="items"
        content-class="user-card-grid"
        :pagination.sync="pagination"
        :rows-per-page-items="rowsPerPageItems"
      >
        <template #item="user">
          <users-list-card
            :user-id="user.item.id"
            :role="user.item.role.label"
            :name="user.item.name"
            :email="user.item.email"
            :job-title="user.item.job_title"
          />
        </template>
      </v-data-iterator>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import content from '@/content/staticContentForUI';
import UsersListCard from '@/components/UsersListCard';

export default {
  name: 'UsersListResults',
  components: {
    'users-list-card': UsersListCard,
  },
  props: {
    items: {
      default: () => [],
      type: Array,
    },
    searchResults: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    return {
      content: content.usersList,
      pagination: {
        page: 1,
        descending: false,
        rowsPerPage: 12,
        sortBy: '',
      },
      rowsPerPageItems: [8, 12, 24, { text: '$vuetify.dataIterator.rowsPerPageAll', value: -1 }],
    };
  },

  computed: {
    ...mapGetters(['paginationDescending', 'paginationPage', 'paginationRowsPerPage', 'sortBy']),

    listLength() {
      return this.searchResults.length || this.items.length;
    },
    usersFound() {
      const count = this.listLength;
      if (count) {
        return `User${count > 1 ? 's' : ''} Found`;
      }
      return '';
    },
  },

  watch: {
    pagination() {
      this.$store.commit('paginationPage', this.pagination.page);
      this.$store.commit('paginationRowsPerPage', this.pagination.rowsPerPage);
    },
    sortBy() {
      this.pagination.sortBy = this.sortBy;
      if (this.sortBy === 'last_authenticated_date') {
        this.pagination.descending = true;
        this.$store.commit('paginationDescending', true);
        return;
      }
      this.pagination.descending = false;
      this.$store.commit('paginationDescending', false);
    },
  },

  created() {
    if (this.sortBy.length) {
      this.pagination.sortBy = this.sortBy;
    }
    if (this.paginationDescending) {
      this.pagination.descending = this.paginationDescending;
    }
    if (this.paginationPage > 1) {
      this.pagination.page = this.paginationPage;
    }
    if (this.paginationRowsPerPage) {
      this.pagination.rowsPerPage = this.paginationRowsPerPage;
    }
  },

  methods: {
    emitClearSearch() {
      this.$emit('clearSearchResults');
    },
  },
};
</script>
