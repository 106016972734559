/**
 * These languages are currently supported by the Ambassadors Web App and Service.
 *
 * In language map below, each key is a locale ID (must be identical to locale ID stored in
 * the service), and each value is an Object containing the corresponding language codes
 * (as defined in BCP 47).
 *
 * Note:
 * Although there are multiple Chinese regions, the app does not explicitly support each one.
 * Instead, the regions are reduced to either simplified (zh-Hans) or traditional (zh-Hant).
 */
module.exports = {
  en: {
    primary: 'en',
    secondary: ['en-US', 'en-CA'],
  },
  ja: {
    primary: 'ja',
    secondary: [],
  },
  ko: {
    primary: 'ko',
    secondary: [],
  },
  zh_hans: {
    primary: 'zh-Hans',
    secondary: ['zh', 'zh-CN'],
  },
  zh_hant: {
    primary: 'zh-Hant',
    secondary: ['zh-TW', 'zh-HK'],
  },
};
