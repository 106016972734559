<template>
  <div class="user-profile-container">
    <div :class="loading ? 'lll-disabled lll-flash' : ''">
      <h1 class="user-profile-title">{{ currentUser.name }}</h1>
      <ul class="list-unstyled user-details">
        <li>
          <h2 class="h-primary">{{ content.jobTitle }}</h2>
          {{ currentUser.job_title }}
        </li>
        <li>
          <h2 class="h-primary">{{ content.email }}</h2>
          {{ currentUser.email }}
        </li>
        <li>
          <h2 class="h-primary">{{ content.lastActive }}</h2>
          {{ formatDateTime(currentUser.last_authenticated_date) }}
        </li>
      </ul>
      <user-profile-edit-form ref="editFormContainer" :user="currentUser" :loading.sync="loading" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import UserProfileEditForm from '@/components/UserProfileEditForm';
import content from '@/content/staticContentForUI';

export default {
  name: 'UserProfile',

  components: {
    'user-profile-edit-form': UserProfileEditForm,
  },

  beforeRouteLeave(routeTo, routeFrom, next) {
    if (this.$refs.editFormContainer.formIsInProgress) {
      // trigger process to show error message for unsaved changes
      this.$refs.editFormContainer.confirmRouteChange(routeTo);
      next(false);
    } else {
      next();
    }
  },

  props: {
    id: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      content: content.userProfile,
      loading: false,
    };
  },

  computed: {
    ...mapGetters(['currentUser']),
  },

  mounted() {
    // prep during 'mounted' lifecycle so this.$refs is defined (needed to access form methods)
    this.prepUserProfileEditForm();
  },

  methods: {
    ...mapActions(['getClaims', 'getRoles', 'updateCurrentUser']),

    formatDateTime(value) {
      if (!value) {
        return 'Not applicable';
      }

      // Adding a 'Z' to the date string allows JS to parse the string as needed and convert the time to the appropriate timezone
      const datetime = new Date(value.endsWith('Z') ? value : value.concat('Z'));
      const formattedTime = datetime.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        timeZoneName: 'short',
      });
      const formattedDate = datetime.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });

      return `${formattedTime} ${formattedDate}`;
    },

    async prepCurrentUser() {
      // if currentUser is missing from state (e.g., state is cleared during app refresh)
      if (this.currentUser.id !== this.id) {
        // make API call and update currentUser in state
        await this.updateCurrentUser(this.id).catch(error => {
          throw error;
        });
      }
    },

    /**
     * Dispatch async actions to get items for 'Role' drop-down list and claims list from the
     * Permissions Service. The actions don't make an API call if data is already saved in state.
     * Ensure these items are loaded BEFORE the form fields are populated to avoid empty fields
     * or errors.
     */
    prepUserProfileEditForm() {
      this.loading = true;

      const claims = this.getClaims();
      const roles = this.getRoles();

      Promise.all([claims, roles]).then(() => {
        this.prepCurrentUser()
          .then(() => {
            this.loading = false;
            this.$refs.editFormContainer.setInitialValues();
            this.$refs.editFormContainer.handleSavedFormData();
          })
          .catch(error => {
            this.loading = false;
            this.$handleErrorMessage(error);
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.user-profile-container {
  height: 100%;
  padding-left: 5vw;
  padding-right: 5vw;
}

.user-profile-title {
  font-size: $lll-font-size-large;
  font-weight: $lll-font-weight-semibold;
  line-height: 2.5rem;
  margin-bottom: 16px;
}

.user-details {
  column-gap: 48px;
  display: inline-flex;
  flex-wrap: wrap;
  margin-bottom: 48px;

  > * {
    align-items: baseline;
    column-gap: 16px;
    display: inline-flex;
    white-space: nowrap;
  }
}
</style>
