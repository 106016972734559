<template>
  <div :class="openModal ? 'amb-modal-open' : ''">
    <v-dialog v-model="openModal" persistent content-class="amb-modal">
      <div class="modal-layout lll-bg-black">
        <div class="modal-header">
          <button
            v-show="!hideCloseButton"
            class="btn btn-close-modal flex-centered-inline"
            @click.prevent="closeModal"
          >
            <span>{{ content.closeButton }}</span>
            <v-icon dark medium right>
              close
            </v-icon>
          </button>
        </div>
        <div class="modal-content">
          <slot />
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import content from '@/content/staticContentForUI';

export default {
  name: 'AMBModal',

  props: {
    hideCloseButton: {
      default: false,
      type: Boolean,
    },
    openModal: {
      default: false,
      type: Boolean,
    },
  },

  data() {
    return {
      content: content.ambModal,
    };
  },

  methods: {
    closeModal() {
      this.$emit('update:openModal', false);
    },
  },
};
</script>

<style lang="scss">
.amb-modal {
  &.v-dialog {
    margin: 0 3vmin;
    &:not(.v-dialog--fullscreen) {
      max-height: 100%;
    }
  }
}

.btn-close-modal {
  color: $lll-color-white;
}

.modal-content {
  grid-area: content;
  background-color: $lll-color-white;
}

.modal-header {
  grid-area: header;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 2vw 5vw;

  @media (min-width: 600px) {
    padding: 0 7vw;
  }
}

.modal-layout {
  display: grid;
  grid-template:
    'header header header' minmax(min-content, 5vw)
    '. content .' 1fr
    '. . .' 5vw
    / 5vw 1fr 5vw;

  @media (min-width: 600px) {
    grid-template:
      'header header header' minmax(min-content, 5vw)
      '. content .' 1fr
      '. . .' 5vw
      / 7vw 1fr 7vw;
  }
}
</style>
