<template>
  <v-form ref="form" v-model="validForm" class="lll-form-body lll-form-padding">
    <slot />
  </v-form>
</template>

<script>
import isEqual from 'lodash/isEqual';
import { customMessages } from '@/utils/errorHandlers';
import content from '@/content/staticContentForUI';

export default {
  name: 'AMBForm',

  props: {
    defaultValues: {
      required: true,
      type: Object,
      default: () => {},
    },
    fields: {
      required: true,
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      formFields: this.fields,
      formDataInitialValues: {},
      validForm: false,
    };
  },

  methods: {
    captureFormData() {
      const formData = {};

      Object.keys(this.formFields).forEach(field => {
        if (this.fieldIsDirty(field)) {
          let formFieldValue = this.formFields[field];

          if (formFieldValue === null || formFieldValue === undefined) {
            /**
             * This handles the case where a user intentionally clears a value (e.g., clicks the
             * clear button or deletes text from a field). When a Vuetify input is cleared, the
             * value may get set to null or undefined. If null/undefined is sent with the
             * POST/PATCH request, the backend marshmallow library ignores it and doesn't update
             * that field value. So if the field is null/undefined, send default value (e.g.,
             * emtpy string, empty array) in order to clear this attribute on the service side.
             */
            formFieldValue = this.defaultValues[field];
          }

          formData[field] = formFieldValue;
        }
      });

      return formData;
    },

    fieldIsDirty(field) {
      return !isEqual(this.formDataInitialValues[field], this.formFields[field]);
    },

    getValidatedFormData() {
      if (!this.isReadyToSubmit()) {
        return {};
      }

      return this.captureFormData();
    },

    isDirty() {
      let result = false;

      for (const field in this.formFields) {
        if (this.fieldIsDirty(field)) {
          result = true;
          break;
        }
      }

      return result;
    },

    isReadyToSubmit() {
      if (this.isValid()) {
        return true;
      }

      const basicConfig = this.$createErrorSnackbar(customMessages.formInvalid);

      this.$swal({
        ...basicConfig,
        confirmButtonText: content.formErrorSnackbar.backToForm,
      });

      return false;
    },

    isValid() {
      return this.$refs.form.validate();
    },

    resetValidation() {
      this.$refs.form.resetValidation();
    },

    setInitialValues() {
      this.formDataInitialValues = { ...this.formFields };
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/forms-inputs.scss';
</style>
