import AmbassadorType from '@/models/v2/AmbassadorType';

const initialState = () => {
  return {
    ambassadorTypes: [],
  };
};

const actions = {
  async getAmbassadorTypes({ commit, state }) {
    // check whether types have already been saved in state before making API call
    if (state.ambassadorTypes.length) {
      return state.ambassadorTypes;
    }

    const ambassadorTypes = await AmbassadorType.fetchAll();
    commit('ambassadorTypes', ambassadorTypes);
    return ambassadorTypes;
  },

  async getAmbassadorTypeById({ dispatch }, ambassadorTypeId) {
    const ambassadorTypeRegistry = await dispatch('loadAmbassadorTypeRegistry');
    let ambassadorType = ambassadorTypeRegistry[ambassadorTypeId];

    if (ambassadorType === undefined) {
      ambassadorType = {
        id: ambassadorTypeId,
        label: 'Unknown Ambassador Type',
      };
    }

    return ambassadorType;
  },

  async loadAmbassadorTypeRegistry({ dispatch, commit, state }) {
    if ('ambassadorTypeRegistry' in state) {
      return state.ambassadorTypeRegistry;
    }

    const ambassadorTypes = await dispatch('getAmbassadorTypes');
    const ambassadorTypeRegistry = {};

    for (const ambassadorType of ambassadorTypes) {
      ambassadorTypeRegistry[ambassadorType.id] = ambassadorType;
    }

    commit('ambassadorTypeRegistry', ambassadorTypeRegistry);
    return ambassadorTypeRegistry;
  },
};

const getters = {
  ambassadorTypes: state => state.ambassadorTypes,
};

const mutations = {
  ambassadorTypes(state, types) {
    state.ambassadorTypes = types;
  },
  ambassadorTypeRegistry(state, ambassadorTypeRegistry) {
    state.ambassadorTypeRegistry = ambassadorTypeRegistry;
  },
  resetState(state) {
    Object.assign(state, initialState);
  },
};

const state = initialState;

export default {
  actions,
  getters,
  mutations,
  state,
};
