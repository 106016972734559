<template>
  <div>
    <div class="gallery-container">
      <div class="gallery-main-layout">
        <div v-if="activeImage" class="gallery-active-img">
          <div v-if="loading || galleryLoading" class="gallery-loading-spinner">
            <v-progress-circular :indeterminate="true" size="50" />
          </div>
          <a :href="activeImageHref" target="_blank" class="gallery-active-img-link">
            <v-img alt="" contain height="100%" class="lll-bg-grey" :src="activeImageSrc">
              <template #placeholder>
                <v-layout fill-height align-center justify-center ma-0>
                  <v-icon large>
                    image_not_supported
                  </v-icon>
                </v-layout>
              </template>
            </v-img>
          </a>
        </div>
        <div v-else class="active-img-placeholder" />
        <div class="gallery-pagination flex-centered-inline">
          <button name="previous-image" class="lll-link" @click="showPrevImage">
            <v-icon large>
              chevron_left
            </v-icon>
          </button>
          <span>{{ paginationLabel }}</span>
          <button name="next-image" class="lll-link" @click="showNextImage">
            <v-icon large>
              chevron_right
            </v-icon>
          </button>
        </div>
        <photo-gallery-thumbnails
          ref="galleryThumbnails"
          class="gallery-thumbnail-grid"
          :active-image-id="activeImageId"
          :photos-list="photosList"
          @selectedPhoto="updateActiveImage"
        />
        <div v-show="userPermissions.canCreateRecords" class="gallery-active-img-btns">
          <div v-if="activeImageIsProfilePhoto" class="hint-text font-italic">
            {{ content.profilePhotoLabel }}
          </div>
          <button
            v-else
            name="save-as-profile-photo"
            class="btn-link lll-text-red"
            :disabled="loading || galleryLoading || editsDisabled"
            @click="saveAsProfilePhoto"
          >
            {{ content.profilePhotoButton }}
          </button>
          <div class="lll-hr-grey" />
          <button
            name="delete-image"
            class="btn-link"
            :disabled="loading || galleryLoading || editsDisabled"
            @click="confirmDelete"
          >
            {{ content.deleteButton }}
          </button>
        </div>
      </div>
    </div>
    <amb-modal hide-close-button :open-modal.sync="imageCropperIsOpen">
      <photo-gallery-cropper
        ref="imageCropper"
        :ambassador-id="ambassadorId"
        :current-profile-photo="currentProfilePhoto"
        :gallery-photo="activeImage"
        @closeImageCropper="closeImageCropper"
        @photoSaved="updatePhotosList"
      />
    </amb-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AMBModal from '@/components/AMBModal';
import PhotoGalleryCropper from '@/components/PhotoGalleryCropper';
import PhotoGalleryThumbnails from '@/components/PhotoGalleryThumbnails';
import content from '@/content/staticContentForUI';

export default {
  name: 'PhotoGalleryMain',

  components: {
    'amb-modal': AMBModal,
    'photo-gallery-cropper': PhotoGalleryCropper,
    'photo-gallery-thumbnails': PhotoGalleryThumbnails,
  },

  props: {
    ambassadorId: {
      type: String,
      default: '',
    },
    currentProfilePhoto: {
      type: Object,
      default: () => {},
    },
    editsDisabled: {
      type: Boolean,
      default: false,
    },
    galleryLoading: {
      type: Boolean,
      default: false,
    },
    openModal: {
      type: Boolean,
      default: false,
    },
    photosList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      activeImage: this.photosList[0] || '',
      loading: false,
      imageCropperIsOpen: false,
      content: content.photoGalleryMain,
    };
  },

  computed: {
    ...mapGetters(['userPermissions']),

    activeImageSrc() {
      if (!this.activeImage) {
        return '';
      }

      const { publish_url, url } = this.activeImage;

      return publish_url ? `${publish_url}?&qlt=85` : url;
    },

    activeImageHref() {
      if (!this.activeImage) {
        return '';
      }

      return this.activeImage.publish_url || this.activeImage.url;
    },

    activeImageId() {
      return this.activeImage ? this.activeImage.id : '';
    },

    activeImageIndex() {
      let index = 0;

      for (let idx = 0; idx < this.photosList.length; idx++) {
        const photo = this.photosList[idx];
        if (photo.id === this.activeImage.id) {
          index = idx;
          break;
        }
      }

      return index;
    },

    activeImageIsProfilePhoto() {
      return this.activeImage ? this.activeImage.id === this.currentProfilePhoto.id : false;
    },

    nextImageIndex() {
      let nextIndex = this.activeImageIndex + 1;
      if (nextIndex >= this.photosList.length) {
        nextIndex = 0;
      }

      return nextIndex;
    },

    prevImageIndex() {
      let prevIndex = this.activeImageIndex - 1;
      if (prevIndex < 0) {
        prevIndex = this.photosList.length - 1;
      }

      return prevIndex;
    },

    photosCount() {
      return this.photosList.length;
    },

    paginationLabel() {
      const { part1, part2 } = this.content.paginationLabel;

      return `${this.activeImageIndex + 1} ${part1} ${this.photosCount} ${part2}`;
    },
  },

  watch: {
    // ensures activeImage is updated when new profile is loaded
    photosList(list) {
      if (list.length) {
        this.activeImage = list[0];
      }
    },
  },

  methods: {
    ...mapActions(['updateProfilePhoto']),

    closeImageCropper() {
      this.imageCropperIsOpen = false;
    },

    closeModal() {
      this.$emit('update:openModal', false);
    },

    confirmDelete() {
      const {
        heading,
        confirm,
        warning,
        cancelButton,
        confirmButton,
      } = this.content.confirmDeleteAlert;

      const confirmDeleteAlert = this.$createAlert(heading, `<p>${confirm}</p><p>${warning}</p>`);

      this.$swal({
        ...confirmDeleteAlert,
        showCancelButton: true,
        cancelButtonText: cancelButton,
        confirmButtonText: confirmButton,
      }).then(result => {
        if (result.value) {
          this.deleteActiveImage();
        }
      });
    },

    deleteActiveImage() {
      this.loading = true;

      const nextImageIndex = this.nextImageIndex;
      const payload = {
        ambassador_id: this.ambassadorId,
        photo_id: this.activeImage.id,
      };

      this.$store
        .dispatch('deleteAmbassadorPhoto', payload)
        .then(() => {
          if (this.photosList.length === 1) {
            this.activeImage = '';
            this.loading = false;
            this.closeModal();
          } else {
            this.loading = false;
            this.updateActiveImage(this.photosList[nextImageIndex]);
          }
          this.updatePhotosList();
        })
        .catch(error => {
          this.$handleErrorMessage(error);
        });
    },

    openGalleryUploader() {
      this.$emit('openGalleryUploader');
    },

    cropAndSaveProfilePhoto() {
      this.$refs.imageCropper.setupImageCropper();
      this.imageCropperIsOpen = true;
    },

    saveAsProfilePhoto() {
      this.cropAndSaveProfilePhoto();

      // TODO: NOTICKET - After UAT is done for photo cropper, remove this if it's not needed. Also remove "mapActions" above.
      // this.loading = true;

      // let payload = {
      //   newProfilePhoto: this.activeImage,
      //   currentProfilePhoto: this.currentProfilePhoto,
      // };

      // this.updateProfilePhoto(payload)
      //   .then(() => {
      //     this.$emit('updatePhotosList');
      //     this.loading = false;
      //   })
      //   .catch(error => {
      //     this.loading = false;
      //     this.$handleErrorMessage(error);
      //   });
    },

    showNextImage() {
      this.updateActiveImage(this.photosList[this.nextImageIndex]);
    },

    showPrevImage() {
      this.updateActiveImage(this.photosList[this.prevImageIndex]);
    },

    updateActiveImage(photo) {
      this.activeImage = photo;
    },

    updatePhotosList() {
      this.$emit('updatePhotosList');
    },
  },
};
</script>

<style lang="scss" scoped>
$breakpoint-sm: 600px;
$breakpoint-med: 800px;
$breakpoint-lg: 1600px;

.gallery-active-img {
  grid-area: main;
  position: relative;
}

.gallery-loading-spinner {
  position: absolute;
  grid-area: main;
  display: grid;
  place-items: center;
  place-content: center;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
}

.image-cropper-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.active-img-placeholder {
  // grid-area: main;
  background-color: $lll-color-background;
  width: 100%;
  padding-top: 100%;
}

.gallery-active-img-link {
  cursor: zoom-in;
}

.gallery-active-img-btns {
  grid-area: buttons;
  display: grid;
  grid-gap: 20px;
  font-size: $lll-font-size-small;
  align-content: end;
  justify-content: center;
  text-align: center;
  padding: 0 0 25px 0;
}

.gallery-add-photos-btn-mobile {
  @media (max-width: $breakpoint-sm) {
    width: 100%;
    margin: 5vw 0 5vw 0;
  }
}

.gallery-container {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
}

.gallery-main-layout {
  display: grid;
  grid-template:
    '. buttons .' min-content
    '. pagination .' min-content
    '. main .' min-content
    '. thumbnails .' min-content
    /
    1fr
    minmax(200px, 500px)
    1fr;
  grid-row-gap: 25px;
  grid-column-gap: 0;

  @media (min-width: $breakpoint-med) {
    grid-template:
      'main buttons' minmax(275px, 560px)
      'pagination .' min-content
      'thumbnails thumbnails' min-content
      /
      minmax(275px, 500px)
      160px;
    grid-column-gap: 50px;
  }

  @media (min-width: $breakpoint-lg) {
    display: grid;
    grid-template:
      'thumbnails main buttons' 560px
      '. pagination .' min-content
      /
      max-content
      minmax(275px, 500px)
      160px;
  }
}

.gallery-pagination {
  grid-area: pagination;

  > .lll-link {
    margin: 0 10px;
    padding: 0;
  }
}

.gallery-thumbnail-grid {
  grid-area: thumbnails;
  display: grid;
  grid-template-columns: repeat(auto-fit, 75px);
  grid-template-rows: repeat(auto-fit, 75px);
  grid-auto-flow: row;
  grid-gap: 15px;
  justify-content: start;

  @media (min-width: $breakpoint-med) {
    grid-template-columns: repeat(auto-fit, 100px);
    grid-template-rows: repeat(auto-fit, 100px);
  }

  @media (min-width: $breakpoint-lg) {
    grid-template-columns: repeat(3, 100px);
    grid-template-rows: repeat(5, 100px);
  }
}
</style>
