<template>
  <div class="lll-input">
    <v-select
      id="select-app-language"
      v-model="selectedLocale"
      :items="ambassadorLocales"
      item-text="label"
      item-value="id"
      return-object
      solo
      flat
      menu-props="offset-y"
      hide-details
      class="nav-select-locale-input"
      @input="setLocale"
    >
      <!-- eslint-disable-next-line vue/no-unused-vars -->
      <template #selection="item">
        <v-icon class="select-locale-icon">language</v-icon>
        <v-btn flat class="select-locale-btn">
          {{ selectedLocale.label }}
        </v-btn>
      </template>
    </v-select>
  </div>
</template>

<script>
/**
 * This component allows the user to select which language the app text is displayed in.
 * Initial selection is based on user's browser settings. If user changes from the default,
 * that choice is remembered and takes precedence over the browser's preferred language.
 * If the user's preferred language is not supported, default is English.
 */
import { mapActions, mapGetters } from 'vuex';
import LANGUAGE_MAP from '../../supportedLanguages';
import { getPreferredLocale, setPreferredLocale } from '@/utils/preferredLocale';

const DEFAULT_LOCALE = { id: 'en', label: 'English' };

export default {
  name: 'NavSelectLocale',

  data() {
    return {
      selectedLocale: DEFAULT_LOCALE,
    };
  },

  computed: {
    // Get list of supported languages from the service.
    ...mapGetters(['ambassadorLocales']),
  },

  created() {
    this.populateLocale();
  },

  methods: {
    ...mapActions(['getAmbassadorLocales']),

    populateLocale() {
      this.getAmbassadorLocales()
        .then(() => {
          const preferredLocale = getPreferredLocale();

          this.selectedLocale = this.ambassadorLocales.find(
            item => item.id === preferredLocale.localeId
          );
        })
        .catch(error => {
          this.$handleErrorMessage(error);
          this.selectedLocale = DEFAULT_LOCALE;
        });
    },

    setLocale() {
      const { id } = this.selectedLocale;

      const localeDetails = {
        localeId: id,
        languageCode: LANGUAGE_MAP[id].primary,
      };

      setPreferredLocale(localeDetails);
      // Refresh the page after changing locale.
      this.$router.go();
    },
  },
};
</script>

<style>
/* These styles cannot be scoped, otherwise Vuetify styles will take precedence. */
.nav-select-locale-input.v-text-field.v-text-field--enclosed > .v-input__control > .v-input__slot {
  padding: 0;
}

.nav-select-locale-input.v-text-field.v-text-field--solo > .v-input__control {
  min-height: 0;
}
</style>

<style lang="scss" scoped>
@import '@/styles/forms-inputs.scss';

.nav-select-locale-input {
  width: min-content;
  text-transform: none;
}

/* On large screens, display selected language. */
.select-locale-btn {
  @include lll-font-body();
  text-transform: none;
  font-weight: $lll-font-weight-medium;
  padding: 0 5px;
  margin: 0;

  &.v-btn:before {
    opacity: 0;
  }

  @media (max-width: 599px) {
    display: none;
  }
}

/* On small screens, display icon instead of language name. */
.select-locale-icon {
  padding: 0;
  margin: 0;

  @media (min-width: 600px) {
    display: none;
  }
}
</style>
