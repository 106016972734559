<template>
  <section :id="`${sectionName}Accordion`" class="lll-bg-white" :class="accordionClasses">
    <div class="accordion-header">
      <button
        v-if="!isOpen"
        name="expand-section"
        type="button"
        class="btn-open-accordion"
        @click="openAccordion"
      />
      <div class="accordion-header-content lll-padding-med">
        <h2 class="accordion-header-title">
          <slot name="title"></slot>
        </h2>
        <div v-if="showHeaderSubtitle" class="accordion-header-subtitle">
          <span v-show="isComplete" class="icon-with-text h-label lll-text-success">
            <v-icon class="mr-1">check_circle_outline</v-icon>
            {{ subtitle.complete }}
          </span>
          <span v-show="inProgress && !requiresPhoto" class="h-label lll-text-red">
            {{ subtitleIncomplete }}
          </span>
          <span v-show="inProgress && requiresPhoto" class="h-label lll-text-red">
            {{ photoMissing }}
          </span>
        </div>
        <div v-if="isOpen && customCta" class="accordion-header-buttons">
          <slot name="ctaButton"></slot>
          <button
            name="close"
            type="button"
            :disabled="loading"
            class="btn btn-last text-no-wrap"
            :class="[!userPermissions.canEditRecords ? 'btn-single' : '']"
            @click="closeAccordion"
          >
            {{ content.closeButton }}
          </button>
        </div>
        <div v-else-if="isOpen && !customCta" class="accordion-header-buttons">
          <button
            v-if="inEditMode && userPermissions.canEditRecords"
            name="save"
            type="button"
            :disabled="loading"
            class="btn btn-cta text-no-wrap"
            @click="save"
          >
            {{ content.saveButton }}
          </button>
          <button
            v-else-if="userPermissions.canEditRecords"
            name="edit"
            type="button"
            :disabled="loading || editsDisabled"
            class="btn btn-primary text-no-wrap"
            @click="edit"
          >
            {{ content.editButton }}
          </button>
          <button
            v-if="inEditMode && userPermissions.canEditRecords"
            name="cancel"
            type="button"
            :disabled="loading"
            class="btn btn-last text-no-wrap"
            @click="cancel"
          >
            {{ content.cancelButton }}
          </button>
          <button
            v-else
            name="close"
            type="button"
            :disabled="loading"
            class="btn btn-last text-no-wrap"
            :class="[!userPermissions.canEditRecords ? 'btn-single' : '']"
            @click="closeAccordion"
          >
            {{ content.closeButton }}
          </button>
        </div>
        <div v-else class="accordion-header-icon">
          <span v-if="isNewProfile && hasOnlyMinFields && showOnboardingEditBtn">
            <button
              name="onboarding-edit"
              type="button"
              class="btn btn-cta btn-onboarding-edit text-no-wrap"
              @click="onboardingEdit"
            >
              {{ content.onboardingEditButton }}
            </button>
          </span>
          <v-icon v-else color="primary" medium>keyboard_arrow_down</v-icon>
        </div>
      </div>
    </div>
    <div class="accordion-panel ambassadors-base">
      <slot name="message"></slot>
      <slot name="panel"></slot>
      <div v-if="inEditMode" class="btn-panel-footer">
        <button name="next" type="button" class="btn btn-cta text-no-wrap" @click="next">
          {{ content.nextButton }}
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import content from '@/content/staticContentForUI';

export default {
  name: 'ProfileDetailsAccordion',

  props: {
    customCta: {
      default: false,
      type: Boolean,
    },
    editsDisabled: {
      default: false,
      type: Boolean,
    },
    emptyRequiredFields: {
      default: () => [],
      type: Array,
    },
    inEditMode: {
      default: false,
      type: Boolean,
    },
    isOpen: {
      default: false,
      type: Boolean,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    sectionName: {
      required: true,
      default: '',
      type: String,
    },
    isNewProfile: {
      type: Boolean,
      default: false,
    },
    hasOnlyMinFields: {
      type: Boolean,
      default: false,
    },
    showOnboardingEditBtn: {
      type: Boolean,
      default: false,
    },
    trackRequiredFields: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      content: content.profileDetailsAccordion,
      subtitle: content.profileDetailsAccordion.subtitle,
    };
  },

  computed: {
    ...mapGetters(['userPermissions']),

    accordionClasses() {
      return {
        'is-closed': !this.isOpen,
      };
    },

    isComplete() {
      return this.emptyRequiredFields.length === 0;
    },

    inProgress() {
      if (this.isNewProfile) {
        return !this.hasOnlyMinFields && !this.isComplete;
      }

      return !this.isComplete;
    },

    photoMissing() {
      if (!this.userPermissions.canEditRecords) {
        return this.subtitleIncomplete;
      }

      return this.subtitle.photo;
    },

    requiresPhoto() {
      return this.sectionName === 'photos';
    },

    fieldsRemaining() {
      return this.emptyRequiredFields.length > 1
        ? this.subtitle.incompletePlural
        : this.subtitle.incompleteSingle;
    },

    showHeaderSubtitle() {
      if (!this.trackRequiredFields) {
        return false;
      }

      return !this.isOpen && !this.loading;
    },

    subtitleIncomplete() {
      return `${this.emptyRequiredFields.length} ${this.fieldsRemaining}`;
    },
  },

  methods: {
    cancel() {
      this.$emit('cancel', this.sectionName);
    },

    closeAccordion() {
      this.$emit('close', this.sectionName);
    },

    edit() {
      this.$emit('updateEditMode', true, this.sectionName);
    },

    next() {
      this.$emit('saveAndContinue');
    },

    onboardingEdit() {
      this.openAccordion();
    },

    openAccordion() {
      this.$emit('update:isOpen', true);

      this.$nextTick(() => {
        this.scrollToTop();
      });
    },

    save() {
      this.$emit('save', this.sectionName);
    },

    scrollToTop() {
      this.$scrollTo(this.$el);
    },
  },
};
</script>

<style lang="scss" scoped>
$breakpoint: 900px;

.accordion-header {
  position: relative;
  display: grid;
  align-items: center;
}

.accordion-header-content {
  display: grid;
  grid-template:
    'title icon'
    'subtitle subtitle'
    'buttons buttons' / 1fr min-content;
  grid-gap: 10px;
  align-items: baseline;

  @media (min-width: $breakpoint) {
    grid-template: 'title subtitle buttons icon' / 1fr max-content min-content;
    align-items: center;
  }
}

.accordion-header-title {
  grid-area: title;
  display: flex;
  align-items: baseline;
  font-size: $lll-font-size-medium;
  font-weight: $lll-font-weight-medium;

  @media (min-width: $breakpoint) {
    font-size: $lll-font-size-large;
    line-height: 2.5rem;
    font-weight: $lll-font-weight-regular;
  }
}

.accordion-header-subtitle {
  grid-area: subtitle;
}

.accordion-header-buttons {
  grid-area: buttons;
  display: inline-flex;

  @media (min-width: $breakpoint) {
    align-items: center;
    justify-content: center;
  }
}

.accordion-header-icon {
  grid-area: icon;
}

.accordion-panel {
  overflow: hidden;
  transition: 0.3s ease all;
}

.btn-last {
  margin-left: 15px;

  &.btn-single {
    margin-left: 0;
  }
}

.btn-open-accordion {
  position: absolute;
  padding: 0;
  margin: 0;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.btn-onboarding-edit {
  position: relative;
  z-index: 2;
}

.is-closed {
  ::v-deep .accordion-header {
    cursor: pointer;
  }

  ::v-deep .accordion-panel {
    max-height: 0;
    padding: 0 25px;
  }
}

.icon-with-text {
  > .v-icon {
    font-size: $lll-font-size-medium;
  }
}

.btn-panel-footer {
  padding: 0 25px 25px 25px;
  display: flex;
  justify-content: flex-end;
}
</style>
