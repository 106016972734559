<template>
  <div>
    <button
      v-if="userPermissions.canExportRecords"
      type="button"
      name="export-results"
      class="btn-link"
      :disabled="loading"
      @click="exportResults"
    >
      <v-progress-circular v-if="loading" :indeterminate="true" size="25" width="3" />
      <v-icon v-else class="pr-1">save_alt</v-icon>
      {{ content.exportButton }}
    </button>
  </div>
</template>

<script>
import axios from 'axios';
import fileDownload from 'js-file-download';
import { mapGetters } from 'vuex';
import get from '@/utils/config';
import { getAuthToken } from '@/vuex/modules/auth';
import { customMessages, formatResponseError } from '@/utils/errorHandlers';
import content from '@/content/staticContentForUI';

export default {
  name: 'AmbassadorsListExport',

  data() {
    return {
      content: content.ambassadorsListExport,
      loading: false,
    };
  },

  computed: {
    ...mapGetters(['ambassadorsList', 'currentFilterString', 'userPermissions']),
  },

  methods: {
    exportResults() {
      this.loading = true;

      const filterString = this.currentFilterString;
      let exportURL = `${get('VUE_APP_AMBASSADORS_HOST')}/v2/ambassadors/export`;

      if (filterString) {
        exportURL += `?filter=${encodeURIComponent(filterString)}`;
      }

      axios
        .create({
          headers: {
            Authorization: getAuthToken(),
          },
        })
        .get(exportURL)
        .then(response => {
          let contentType;
          const filename = 'ambassadors_export.csv';

          try {
            // eslint-disable-next-line prefer-destructuring
            contentType = response.headers['content-type'].split(';')[0];
          } catch (err) {
            contentType = 'application/octet-stream';
          }

          fileDownload(response.data, filename, contentType);
          this.loading = false;
        })
        .catch(error => {
          const formattedError = formatResponseError(error);

          if (formattedError.status === 401) {
            formattedError.customMessage = customMessages.sessionExpired.exportResultsFailed;
          }

          this.$handleErrorMessage(formattedError);
          this.loading = false;
        });
    },
  },
};
</script>
