/* eslint-disable no-shadow-restricted-names */
import Photo from '@/models/v2/Photo';
import PhotoTag from '@/models/v2/PhotoTag';

const initialState = () => {
  return {
    currentAmbassadorPhotos: {
      ambassadorId: null,
      photosList: null,
    },
  };
};

const actions = {
  /**
   * Fetches list of photos assigned to an ambassador gallery and saves data in app state.
   * This ensures all components have access to the latest version of ambassador gallery.
   *
   * @param {Object} context - First param is reserved for context object
   * @param {String} ambassadorId - 'id' attribute from Ambassador model
   * @return - Array of Photo models
   */
  async getAmbassadorPhotos({ commit }, ambassadorId) {
    const query = { include: 'tags', sort: 'added' };
    const args = { ambassador_id: ambassadorId };

    const photosList = await Photo.query(query, args);

    commit('currentAmbassadorPhotos', {
      ambassadorId,
      photosList,
    });

    return photosList;
  },

  /**
   * Deletes photo from ambassador's gallery.
   *
   * @param {undefined} - Pass 'undefined' since context (e.g., { dispatch }) is not used
   * @param {Object} - Payload contains 'ambassador_id' and 'photo_id'
   *
   * Example of valid payload:
   *    {
   *      ambassador_id: this.ambassador.id,
   *      photo_id: this.activeImage.id,
   *    };
   */
  async deleteAmbassadorPhoto(undefined, payload) {
    return await Photo.deletePhoto(payload);
  },

  /**
   * Generates new Photo model using data passed in and saves photo to ambassador's gallery.
   *
   * @param {undefined} - Pass 'undefined' since context (e.g., { dispatch }) is not used
   * @param {Object} - Ambassador ID, photo data to be saved, tags to assign to Photo model
   * @return - Newly saved Photo model
   */
  async saveNewGalleryPhoto(undefined, { ambassadorId = '', photoData = {}, tags = [] } = {}) {
    return await Photo.newPhoto(ambassadorId, photoData, tags);
  },

  /**
   * Generates new Photo model using data passed in and saves photo to ambassador's gallery with
   * 'profile_photo' tag. The difference between this and 'saveNewGalleryPhoto' action is the step
   * to add/remove tag.
   *
   * @param {Object} context - First param is reserved for context object
   * @param {Object} - Contains ambassador ID, photo data to be saved, current Photo model tagged as
   * 'profile_photo', and tags to assign to new profile photo
   * @return - Newly saved Photo model
   */
  async saveNewProfilePhoto(
    { dispatch },
    { ambassadorId = '', newProfilePhoto = {}, currentProfilePhoto = {}, tags = [] } = {}
  ) {
    const profilePhotoTag = await getProfilePhotoTag();
    tags.push(profilePhotoTag);

    if (currentProfilePhoto.id) {
      // remove existing 'profile_photo' tag to avoid saving duplicate profile photos
      await removeProfilePhotoTag(currentProfilePhoto, profilePhotoTag);
    }

    return await dispatch('saveNewGalleryPhoto', {
      ambassadorId,
      photoData: newProfilePhoto,
      tags,
    });
  },

  /**
   * Removes 'profile_photo' tag from current photo and adds tag to new profile. Since both photos
   * are already saved to the ambassador gallery, no need to generate new Photo models.
   *
   * @param {undefined} - Pass 'undefined' since context (e.g., { dispatch }) is not used
   * @param {Object} - Contains Photo model from gallery to be saved and Photo model from gallery
   * currently tagged as 'profile_photo'
   * @return - Newly saved Photo model
   */
  async updateProfilePhoto(undefined, { newProfilePhoto = {}, currentProfilePhoto = {} } = {}) {
    const profilePhotoTag = await getProfilePhotoTag();

    if (currentProfilePhoto.id) {
      // remove existing 'profile_photo' tag to avoid saving duplicate profile photos
      await removeProfilePhotoTag(currentProfilePhoto, profilePhotoTag);
    }

    newProfilePhoto.tags.push(profilePhotoTag);

    return await newProfilePhoto.save();
  },
};

const getters = {
  currentAmbassadorPhotos: state => state.currentAmbassadorPhotos,
};

const mutations = {
  currentAmbassadorPhotos(state, payload) {
    state.currentAmbassadorPhotos = {
      ambassadorId: payload.ambassadorId,
      photosList: payload.photosList,
    };
  },
  resetState(state) {
    Object.assign(state, initialState);
  },
};

const state = initialState;

// HELPER FUNCTIONS

/**
 * Fetches tag for profile photo from the service.
 *
 * @return - PhotoTag model that will be assigned to profile photo
 */
async function getProfilePhotoTag() {
  const tags = await PhotoTag.fetchAll();

  return tags.find(tag => tag.id === 'profile_photo');
}

/**
 * Removes 'profile_photo' tag from 'tags' array on Photo model.
 *
 * @param {Object} profilePhoto - Photo model currently saved as profile photo
 * @param {Object} tag - PhotoTag model to be removed
 * @return - Updated Photo model
 */
async function removeProfilePhotoTag(profilePhoto, tag = undefined) {
  let profilePhotoTag = tag;

  if (!tag) {
    profilePhotoTag = await getProfilePhotoTag();
  }

  const indexToRemove = profilePhoto.tags.map(tag => tag.id).indexOf(profilePhotoTag.id);

  profilePhoto.tags.splice(indexToRemove, 1);
  // save photo with filtered 'tags' list
  return await profilePhoto.save();
}

export default {
  actions,
  getters,
  mutations,
  state,
};
