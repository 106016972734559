<template>
  <maintenance-message :body="body" :heading="heading" />
</template>

<script>
import maintenanceModeIsOn from '@/utils/checkMaintenanceMode';
import AMBMessageFullPage from '@/components/AMBMessageFullPage.vue';

export default {
  name: 'MaintenancePage',

  components: {
    'maintenance-message': AMBMessageFullPage,
  },

  data() {
    return {
      body:
        "The ambassador web application is undergoing a 2-hour maintenance period today, with no access for users. We are preparing to make some optimization updates and can't wait for you to see! Please check back after 12pm PST to access the application. We appreciate your patience.",
      heading: 'Maintenance Alert',
    };
  },

  created() {
    if (!maintenanceModeIsOn()) {
      this.$router.push({ name: 'ambassadors' }).catch(error => {
        console.warn('vue-router error:', error);
      });
    }
  },
};
</script>
