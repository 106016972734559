import Vue from 'vue';
import Vuex from 'vuex';
import state from '@/vuex/state';
import mutations from '@/vuex/mutations';
import getters from '@/vuex/getters';

// modules
import ambassador from '@/vuex/modules/ambassador';
import ambassadorCountry from '@/vuex/modules/ambassadorCountry';
import ambassadorDiscipline from '@/vuex/modules/ambassadorDiscipline';
import ambassadorDocument from '@/vuex/modules/ambassadorDocument';
import ambassadorExperience from '@/vuex/modules/ambassadorExperience';
import ambassadorListing from '@/vuex/modules/ambassadorListing';
import ambassadorLocale from '@/vuex/modules/ambassadorLocale';
import ambassadorPhoto from '@/vuex/modules/ambassadorPhoto';
import ambassadorProductCategory from '@/vuex/modules/ambassadorProductCategory';
import ambassadorStatus from '@/vuex/modules/ambassadorStatus';
import ambassadorStore from '@/vuex/modules/ambassadorStore';
import ambassadorSubDiscipline from '@/vuex/modules/ambassadorSubDiscipline';
import ambassadorType from '@/vuex/modules/ambassadorType';
import auth from '@/vuex/modules/auth';
import permissions from '@/vuex/modules/permissions';

Vue.use(Vuex);

export default new Vuex.Store({
  state,
  mutations,
  getters,
  modules: {
    ambassador,
    ambassadorCountry,
    ambassadorDiscipline,
    ambassadorDocument,
    ambassadorExperience,
    ambassadorListing,
    ambassadorLocale,
    ambassadorPhoto,
    ambassadorProductCategory,
    ambassadorStatus,
    ambassadorStore,
    ambassadorSubDiscipline,
    ambassadorType,
    auth,
    permissions,
  },
});
