import AmbassadorsBase from '@/models/v2/AmbassadorsBase';
import Discipline from '@/models/v2/Discipline';

class SubDiscipline extends AmbassadorsBase {
  static _type = 'disciplines';

  static name = 'sub_discipline';

  static baseURL = '/sub_disciplines';

  constructor(args = {}) {
    if (args === null) {
      args = {};
    }
    super(args);
    this.id = args.id;
    this.label = args.label;

    this.parent_discipline = this.belongsTo(Discipline, args.parent_discipline);
  }
}

export default SubDiscipline;
