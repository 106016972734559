import get from '@/utils/config';

function maintenanceModeIsOn() {
  const envVariable = get('VUE_APP_MAINTENANCE_MODE');

  if (envVariable === 'on') {
    return true;
  }

  if (envVariable === 'off') {
    return false;
  }

  console.error(
    `Error checking Maintenance Mode. Make sure environment variable is set to String "on" or "off". Current value: ${envVariable}`
  );

  return false;
}

export default maintenanceModeIsOn;
