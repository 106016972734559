<template>
  <div class="hint-text font-italic">{{ text }}</div>
</template>

<script>
import content from '@/content/staticContentForUI';

export default {
  name: 'AMBPendingLabel',

  data() {
    return {
      text: content.ambPendingLabel.defaultText,
    };
  },
};
</script>
