<template>
  <div class="lll-form-layout update-status-form-layout">
    <h1 class="h-page-title text-xs-center">
      {{ content.mainHeading }}
    </h1>
    <div
      class="lll-form-container update-status-form"
      :class="loading ? 'lll-disabled lll-flash' : ''"
    >
      <amb-form
        :ref="refName"
        :default-values="formFieldsDefaultValues"
        :fields="formFields"
        class="lll-bg-white"
      >
        <p class="row">
          <abbr title="required" class="lll-asterisk">*</abbr>
          <span class="h-label">{{ content.required }}</span>
        </p>
        <amb-form-field-label attribute-id="ambassador_status" show-asterisk />
        <v-select
          :id="getFieldId('ambassador_status')"
          v-model="formFields.ambassador_status"
          :rules="formFieldDetails.ambassador_status.validation"
          validate-on-blur
          :items="statusOptions"
          item-text="label"
          item-value="id"
          return-object
          solo
          flat
          :placeholder="formFieldDetails.ambassador_status.fieldText.placeholder"
          menu-props="offsetY"
        />
        <amb-form-field-label attribute-id="termination_reason" show-asterisk />
        <v-textarea
          :id="getFieldId('termination_reason')"
          v-model.trim="formFields.termination_reason"
          :rules="formFieldDetails.termination_reason.validation"
          validate-on-blur
          browser-autocomplete="off"
          auto-grow
          solo
          flat
        />
        <div class="btn-group-2-col">
          <button name="update-status" class="btn btn-primary" @click.prevent="confirmUpdate">
            {{ content.updateButton }}
          </button>
          <button name="cancel" class="btn" @click.prevent="cancelSubmit">
            {{ content.cancelButton }}
          </button>
        </div>
      </amb-form>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import Ambassador from '@/models/v2/Ambassador';
import { customMessages } from '@/utils/errorHandlers';
import content from '@/content/staticContentForUI';
import * as formHelpers from '@/utils/formHelpers';
import AMBForm from '@/components/AMBForm';
import AMBFormFieldLabel from '@/components/AMBFormFieldLabel';
import { userCanEditThisPublishStatus } from '@/vuex/modules/auth';

/**
 * Form name is imported from formHelpers in order to use consistent labelling,
 * which ensures helper functions can work properly.
 */
const FORM_NAME = formHelpers.PROFILE_STATUS_FORM;
const PROFILE_STATUS_FORM_FIELDS_DEFAULT = formHelpers.getFormFields('tags', FORM_NAME);

export default {
  name: 'ProfileUpdateStatus',

  components: {
    'amb-form': AMBForm,
    'amb-form-field-label': AMBFormFieldLabel,
  },

  beforeRouteLeave(routeTo, routeFrom, next) {
    if (this.$refs[this.refName].isDirty() && !this.allowRouteChange) {
      // trigger process to show error message for unsaved changes
      this.cancelSubmit(routeTo);
      next(false);
    } else {
      next();
    }
  },

  props: {
    id: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      content: content.profileUpdateStatus,
      allowRouteChange: false,
      formFieldDetails: formHelpers.getFieldDetails('tags', FORM_NAME),
      formFields: { ...PROFILE_STATUS_FORM_FIELDS_DEFAULT },
      formFieldsDefaultValues: { ...PROFILE_STATUS_FORM_FIELDS_DEFAULT },
      loading: false,
      refName: FORM_NAME,
      validationRules: formHelpers.VALIDATION_RULES,
    };
  },

  computed: {
    ...mapGetters(['ambassadorStatuses', 'currentAmbassador', 'userClaims']),

    statusOptions() {
      const { ambassador_status, end_date, start_date } = this.currentAmbassador;
      const today = new Date();
      const startDate = new Date(start_date);
      const endDate = new Date(end_date);

      if (ambassador_status.id === 'terminated') {
        // today is still within the contract period
        if (startDate.getTime() <= today.getTime() && endDate.getTime() > today.getTime()) {
          return this.ambassadorStatuses.filter(status => status.id === 'ambassador');
        }

        // today is after the contract period
        if (endDate.getTime() <= today.getTime()) {
          return this.ambassadorStatuses.filter(status => status.id === 'former');
        }
      }

      return this.ambassadorStatuses.filter(status => status.id === 'terminated');
    },

    userClaimsAreValid() {
      const { publish_status } = this.currentAmbassador;

      if (
        publish_status === 'published' &&
        !userCanEditThisPublishStatus(publish_status, this.userClaims)
      ) {
        const basicConfig = this.$createErrorSnackbar(customMessages.statusUpdateRestricted);

        this.$swal({
          ...basicConfig,
          confirmButtonText: content.formErrorSnackbar.backToForm,
        });

        return false;
      }

      return true;
    },
  },

  created() {
    this.prepCurrentAmbassador();
  },

  mounted() {
    this.prepForm();
  },

  methods: {
    ...mapActions(['getAmbassadorStatuses', 'updateCurrentAmbassador']),

    cancelSubmit(routeTo) {
      if (this.$refs[this.refName].isDirty()) {
        const basicConfig = this.$createErrorSnackbar(customMessages.formIncomplete);

        this.$swal({
          ...basicConfig,
          showCancelButton: true,
          cancelButtonText: content.formErrorSnackbar.backToForm,
          confirmButtonText: content.formErrorSnackbar.confirmButton,
        }).then(result => {
          if (result.value) {
            this.forceCancelSubmit(routeTo);
          }
        });
      } else {
        this.$router.go(-1);
      }
    },

    forceCancelSubmit(routeTo) {
      this.resetFormFields();
      this.$refs[this.refName].resetValidation();
      this.allowRouteChange = true;

      if (routeTo.name) {
        // handle click on router-link
        this.$router.push(routeTo.path).catch(error => {
          console.warn('vue-router error:', error);
        });
      } else {
        // handle click on 'Cancel' button, in which case,
        // there is no route object to pass in
        this.$router.go(-1);
      }
    },

    getFieldId(attributeId) {
      return formHelpers.convertSnakeToKebab(attributeId);
    },

    getValidatedFormData() {
      return this.$refs[this.refName].getValidatedFormData();
    },

    async loadStatusList() {
      await this.getAmbassadorStatuses();
    },

    populateSavedFormData() {
      const savedForm = formHelpers.getSavedFormData(this.refName);

      if (!savedForm) {
        return;
      }

      Object.keys(this.formFields).forEach(field => {
        this.formFields[field] = savedForm.data[field] || '';
      });
    },

    prepCurrentAmbassador() {
      // if the current Ambassador is missing from state (e.g., state is cleared during app refresh)
      if (this.currentAmbassador.id !== this.id) {
        this.loading = true;
        // make API call, populate attributes, and update currentAmbassador in state
        this.updateCurrentAmbassador(this.id)
          .then(() => {
            this.loading = false;
          })
          .catch(error => {
            this.loading = false;
            this.$handleErrorMessage(error);
          });
      }
    },

    prepForm() {
      this.loadStatusList().catch(error => {
        this.$handleErrorMessage(error);
      });

      this.populateSavedFormData();
      this.$refs[this.refName].setInitialValues();
    },

    resetFormFields() {
      Object.keys(this.formFields).forEach(field => {
        this.formFields[field] = this.$refs[this.refName].formDataInitialValues[field];
      });
    },

    submitForm() {
      if (!this.userClaimsAreValid) {
        return;
      }

      const formData = this.getValidatedFormData();

      if (isEmpty(formData)) {
        return;
      }

      formHelpers.saveFormInProgress(this.refName, formData);

      this.updateAmbassadorStatus(formData);
    },

    confirmUpdate() {
      if (this.formFields.ambassador_status.label === 'Terminated') {
        const { heading, terminated, confirmButton, cancelButton } = this.content.confirmationAlert;

        const basicAlertConfig = this.$createAlert(heading, terminated);

        this.$swal({
          ...basicAlertConfig,
          showCancelButton: true,
          cancelButtonText: cancelButton,
          confirmButtonText: confirmButton,
        }).then(result => {
          if (result.value) {
            this.submitForm();
          } else {
            this.$router.go(-1);
          }
        });
      } else {
        this.submitForm();
      }
    },

    updateAmbassadorStatus(formData) {
      const updatedAmbassador = new Ambassador(formData);

      for (const field in formData) {
        if (Object.prototype.hasOwnProperty.call(formData, field)) {
          this.currentAmbassador[field] = updatedAmbassador[field];
        }
      }

      this.currentAmbassador
        .save()
        .then(ambassador => {
          const routeInfo = { name: 'ambassadorProfile', params: { id: ambassador.id } };

          // make API call, populate attributes, and update currentAmbassador in state
          this.updateCurrentAmbassador(ambassador.id);
          this.allowRouteChange = true;
          this.$router.push(routeInfo).catch(error => {
            console.warn('vue-router error:', error);
          });
        })
        .then(() => {
          formHelpers.removeSavedFormData(this.refName);
        })
        .catch(error => {
          this.$handleErrorMessage(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.update-status-form-layout {
  grid-template:
    '. title .' min-content
    '. form .' min-content
    / minmax(5vw, 1fr) minmax(275px, 600px) minmax(5vw, 1fr);
}

.update-status-form {
  width: 100%;
}
</style>
