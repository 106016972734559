<template>
  <amb-form
    :ref="formName"
    :default-values="formFieldsDefault"
    :fields="formFields"
    class="user-edit-form"
  >
    <div v-if="inEditMode" class="submit-buttons">
      <button name="save" type="button" class="btn btn-cta text-no-wrap" @click="submitForm">
        {{ content.saveButton }}
      </button>
      <button name="cancel" type="button" class="btn text-no-wrap" @click="cancelEdit">
        {{ content.cancelButton }}
      </button>
    </div>
    <div class="user-role-field">
      <label for="update-user-role" class="h-primary">
        {{ content.role }}
      </label>
      <v-select
        id="update-user-role"
        v-model="formFields.role"
        :items="roles"
        item-text="label"
        item-value="id"
        return-object
        solo
        flat
        hide-details
        hide-selected
        menu-props="offset-y"
        class="user-role-input"
        @input="handleRoleInput"
      />
    </div>
    <v-expansion-panel class="role-claims-section">
      <v-expansion-panel-content>
        <v-icon slot="actions" color="primary" medium>
          $vuetify.icons.expand
        </v-icon>
        <template #header>
          <div class="h-primary">{{ content.roleClaims }}</div>
        </template>
        <ul class="list-unstyled user-claims">
          <li v-for="claim in claimsInCurrentRole" :key="claim.id">
            {{ claim.description }}
          </li>
        </ul>
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel class="custom-claims-section">
      <v-expansion-panel-content>
        <v-icon slot="actions" color="primary" medium>
          $vuetify.icons.expand
        </v-icon>
        <template #header>
          <div class="h-primary">{{ content.customClaims }}</div>
        </template>
        <ul class="list-unstyled user-claims">
          <li v-for="claim in claimsNotInCurrentRole" :key="claim.id">
            <div class="custom-claim">
              <span class="custom-claim-enabled-icon">
                <v-icon v-if="claimIsEnabled(claim.id)" color="success"
                  >check_circle_outline</v-icon
                >
                <v-icon v-else color="error">cancel</v-icon>
              </span>
              <v-switch
                v-model="formFields.custom_claims"
                :value="claim"
                :label="claim.description"
                return-object
                hide-details
                class="custom-claim-switch"
                @change="updateEditMode(true)"
              />
            </div>
          </li>
        </ul>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </amb-form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import User from '@/models/User';
import AMBForm from '@/components/AMBForm';
import * as formHelpers from '@/utils/formHelpers';
import content from '@/content/staticContentForUI';
import { customMessages } from '@/utils/errorHandlers';

const USER_EDIT_FORM_FIELDS_DEFAULT = {
  role: '',
  custom_claims: [],
};

export default {
  name: 'UserProfileEditForm',

  components: {
    'amb-form': AMBForm,
  },

  props: {
    user: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      allowRouteChange: false,
      content: content.userProfile,
      formName: 'userProfileEdit',
      formFields: { ...USER_EDIT_FORM_FIELDS_DEFAULT },
      formFieldsDefault: { ...USER_EDIT_FORM_FIELDS_DEFAULT },
      inEditMode: false,
    };
  },

  computed: {
    ...mapGetters(['claims', 'roles']),

    claimsInCurrentRole() {
      const currentRole = this.roles.find(role => role.id === this.formFields.role?.id);
      return currentRole?.claims || [];
    },

    claimsNotInCurrentRole() {
      const currentRoleClaimsIdSet = new Set();

      for (const claim of this.claimsInCurrentRole) {
        currentRoleClaimsIdSet.add(claim.id);
      }

      return this.claims.filter(
        claim => claim.name !== 'ambassador_assume_role' && !currentRoleClaimsIdSet.has(claim.id)
      );
    },

    formIsInProgress() {
      return !this.allowRouteChange && this.inEditMode && this.$refs[this.formName].isDirty();
    },
  },

  watch: {
    user(data) {
      this.setFormFieldValues(data);
    },
  },

  mounted() {
    this.setFormFieldValues(this.user);
  },

  methods: {
    ...mapActions(['updateCurrentUser']),

    cancelEdit() {
      this.setFormFieldValues(this.user);
      formHelpers.removeSavedFormData(this.formName);
      this.updateEditMode(false);
    },

    claimIsEnabled(value) {
      return this.formFields.custom_claims?.find(claim => claim.id === value);
    },

    confirmRouteChange(routeTo) {
      const basicConfig = this.$createErrorSnackbar(customMessages.formIncomplete);

      this.$swal({
        ...basicConfig,
        showCancelButton: true,
        cancelButtonText: content.formErrorSnackbar.backToForm,
        confirmButtonText: content.formErrorSnackbar.confirmButton,
      }).then(result => {
        if (result.value) {
          this.enableRouteChange(routeTo);
        }
      });
    },

    enableRouteChange(routeTo) {
      this.allowRouteChange = true;
      formHelpers.removeSavedFormData(this.formName);
      this.$router.push(routeTo.path).catch(error => {
        console.warn('vue-router error:', error);
      });
    },

    handleRoleInput() {
      this.updateEditMode(true);
      // reset custom_claims after Role is changed
      this.formFields.custom_claims = this.user.custom_claims;
    },

    handleSavedFormData() {
      const savedForm = formHelpers.getSavedFormData(this.formName);

      if (!savedForm) {
        return;
      }

      Object.keys(savedForm.data).forEach(field => {
        this.formFields[field] = savedForm.data[field];
      });
      this.inEditMode = true;
    },

    setFormFieldValues(data) {
      this.formFields.role = data.role;
      this.formFields.custom_claims = data.custom_claims;
    },

    setInitialValues() {
      this.$refs[this.formName].setInitialValues();
    },

    /**
     * Reset these values in state, so correct user details will show and search results
     * won't be outdated when the UsersList component is mounted again.
     */
    resetUsersList() {
      this.$store.commit('searchResults', []);
      this.$store.commit('updateSearchInput', '');
      this.$store.commit('users', []);
    },

    submitForm() {
      const formData = this.$refs[this.formName].captureFormData();
      this.updateLoading(true);
      formHelpers.saveFormInProgress(this.formName, formData);

      /**
       * This step creating a new User from formData allows the jsonapi-client library to populate
       * the User model with correct attribute values. If you simply assign all form field values
       * to currentUser (e.g., this.user[field] = formData[field]), the model you submit to
       * the 'save' method (this.user.save()) will be missing relationship/type data needed
       * for proper serialization.
       */
      const updatedUser = new User(formData);
      // eslint-disable-next-line guard-for-in
      for (const field in formData) {
        this.user[field] = updatedUser[field];
      }

      this.user
        .save()
        .then(savedUser => {
          formHelpers.removeSavedFormData(this.formName);
          this.updateEditMode(false);
          this.resetUsersList();
          // reset currentUser in state, so profile will show updated user details after saving
          this.updateCurrentUser(savedUser.id)
            .then(() => {
              this.updateLoading(false);
              // reset form field initial values, since user data has changed
              this.setInitialValues();
            })
            .catch(error => {
              this.updateLoading(false);
              this.$handleErrorMessage(error);
            });
        })
        .catch(error => {
          this.updateLoading(false);
          this.$handleErrorMessage(error);
        });
    },

    updateEditMode(boolean) {
      this.inEditMode = boolean;
    },

    updateLoading(boolean) {
      this.$emit('update:loading', boolean);
    },
  },
};
</script>

<style>
/* These styles cannot be scoped, otherwise Vuetify styles will take precedence */
.user-role-input.v-text-field.v-text-field--solo > .v-input__control {
  min-height: 0;
}

.custom-claim-switch.v-input--selection-controls.v-input--switch {
  margin: 0;
  padding: 0;
}

label.theme--light.v-label {
  color: #000;
}
</style>

<style lang="scss" scoped>
.user-edit-form {
  display: grid;
  grid-gap: 16px;
  grid-template:
    'buttons'
    'role'
    'currentClaims'
    'customClaims' / 1fr;
  padding: 0;

  @media (min-width: 900px) {
    grid-template:
      'role . buttons'
      'currentClaims currentClaims currentClaims'
      'customClaims customClaims customClaims' / 1fr 1fr 1fr;
  }

  .v-expansion-panel {
    box-shadow: none;
  }

  .user-role-field {
    align-items: center;
    column-gap: 12px;
    display: inline-flex;
    grid-area: role;
  }

  .role-claims-section {
    grid-area: currentClaims;
  }

  .custom-claims-section {
    grid-area: customClaims;
  }

  .submit-buttons {
    grid-area: buttons;

    @media (min-width: 900px) {
      justify-self: end;
    }

    > * + * {
      margin-left: 16px;
    }
  }
}

.user-claims {
  padding: 0 24px 24px;

  > * + * {
    margin-top: 8px;
  }
}

.custom-claim {
  align-items: center;
  display: inline-flex;

  .custom-claim-enabled-icon {
    margin-right: 4px;
  }
}
</style>
