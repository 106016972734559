<template>
  <div v-if="photosList.length">
    <div
      v-for="photo in photosList"
      :key="photo.id"
      v-ripple
      class="gallery-thumbnail"
      @click="selectPhoto(photo)"
    >
      <amb-image
        thumbnail
        :image-object="photo"
        :image-view-attributes="`&hei=150&qlt=85`"
        :class="getActiveImageClass(photo.id)"
      />
    </div>
  </div>
</template>

<script>
import AMBImage from '@/components/AMBImage';

export default {
  name: 'PhotoGalleryThumbnails',

  components: {
    'amb-image': AMBImage,
  },

  props: {
    activeImageId: {
      type: String,
      default: '',
    },
    photosList: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    getActiveImageClass(photoId) {
      return photoId === this.activeImageId ? 'active-image' : '';
    },

    selectPhoto(photo) {
      this.$emit('selectedPhoto', photo);
    },
  },
};
</script>

<style lang="scss">
.active-image {
  opacity: 0.5;
}

.gallery-thumbnail {
  cursor: pointer;
}
</style>
