import isEmpty from 'lodash/isEmpty';
import LANGUAGE_MAP from '../../supportedLanguages';

/**
 * Array containing all language codes supported by the app (primary and secondary).
 */
const appLanguageCodes = getAllLanguageCodes(LANGUAGE_MAP);

function getAllLanguageCodes(languageMap) {
  const list = [];

  // eslint-disable-next-line guard-for-in
  for (const locale in languageMap) {
    list.push(languageMap[locale].primary);
    languageMap[locale].secondary.forEach(code => list.push(code));
  }

  return list;
}

/**
 * Isolates the two-letter ISO abbreviation of the primary language from the full language code.
 *
 * @param {String} languageCode - Language code as defined in BCP 47 (e.g., 'en', 'zh-HK').
 * @return {String} - Two-letter code of primary language (e.g., 'en-CA' => 'en').
 */
function getTwoLetterISO(languageCode) {
  return languageCode.toLowerCase().split('-')[0];
}

/**
 * Determines whether a language is supported by the app. Compares code in parameter to list of
 * codes in 'appLanguageCodes' array.
 *
 * @param {String} languageCode - Language code as defined in BCP 47 (e.g., 'en', 'zh-HK').
 * @return {Boolean} - Indicates if language is supported.
 */
function languageIsSupported(languageCode) {
  return appLanguageCodes.includes(languageCode);
}

/**
 * Detects user's browser language and returns an acceptable language code. If browser language
 * is not supported by the app, fallback language is returned. The Navigator interface is used:
 * https://developer.mozilla.org/en-US/docs/Web/API/NavigatorLanguage/language
 *
 * @return {String} - Language code representing the preferred language of the user.
 *
 * Example: If browser language is 'zh-CN', this function returns 'zh-CN'. If browser language
 * is 'en-CA' or 'en-US', this function returns 'en'. If browser language is 'ar', 'ru', or some
 * language code not defined in the 'appLanguageCodes' array, fallback language ('en') is returned.
 */
function getPreferredLanguageCode() {
  const browserLang = navigator.language;
  const twoLetterISO = getTwoLetterISO(browserLang);
  const fallbackLang = 'en';

  if (languageIsSupported(browserLang)) {
    return browserLang;
  }

  if (languageIsSupported(twoLetterISO)) {
    return twoLetterISO;
  }

  return fallbackLang;
}

/**
 * Converts language code into a locale ID that's compatible with the Ambassadors Service.
 * The service delivers a list of available locales (languages supported by the app), which are
 * used to populate select inputs (e.g., drop-down menu of options for Profile Language field).
 * If user's browser language is not supported, fallback locale ID is returned.
 *
 * Note: The main difference between 'language code' and 'locale ID' is that a language code is
 * kebab-case and may contain uppercase letters, while its corresponding locale ID is snake_case
 * and all lowercase. Also, the locale ID most likely covers a range of language codes. For
 * example, 'en-US' and 'en-CA' are both covered by 'en', and Chinese regions are covered by either
 * 'zh_hans' (Chinese Simplified) or 'zh_hant' (Chinese Traditional).
 *
 * @param {String} languageCode - (Optional) Language code to be converted.
 * @return {String} - Locale ID that matches one of the supported locale IDs in the service.
 *
 * Example: If browser language is 'zh-CN', this function returns 'zh_hans'. If browser language
 * is 'en-CA', 'ar', 'ru', or some locale not defined in the service, this function returns 'en'.
 */
function parsePreferredLocaleId(languageCode = '') {
  let preferredLang = languageCode;

  if (!languageCode) {
    preferredLang = getPreferredLanguageCode();
  }

  let localeId = preferredLang;

  // eslint-disable-next-line guard-for-in
  for (const key in LANGUAGE_MAP) {
    if (LANGUAGE_MAP[key].primary.includes(preferredLang)) {
      localeId = key;
    }

    if (LANGUAGE_MAP[key].secondary.includes(preferredLang)) {
      localeId = key;
    }
  }

  return localeId;
}

/**
 * Saves preferred locale (localeId and corresponding languageCode) in localStoage.
 *
 * @param {Object} localeDetails - (Optional) Object containing localeId and languageCode.
 */
function setPreferredLocale(localeDetails = {}) {
  let preferredLocale = localeDetails;

  if (!localeDetails || isEmpty(localeDetails)) {
    preferredLocale = {
      localeId: parsePreferredLocaleId(),
      languageCode: getPreferredLanguageCode(),
    };
  }

  localStorage.setItem('preferredLocale', JSON.stringify(preferredLocale));
}

/**
 * Checks for preferred locale details saved in localStorage. If no details saved, preferred
 * locale is defined using browser's language settings. If browser's language is not supported,
 * fallback is used.
 *
 * @return {Object} - Object containing localeId and languageCode.
 */
function getPreferredLocale() {
  const localeDetailsFromBrowser = {
    localeId: parsePreferredLocaleId(),
    languageCode: getPreferredLanguageCode(),
  };

  const localeDetailsFromStorage = localStorage.getItem('preferredLocale');

  return localeDetailsFromStorage ? JSON.parse(localeDetailsFromStorage) : localeDetailsFromBrowser;
}

export { getPreferredLocale, setPreferredLocale };
