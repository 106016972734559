function getInitialState() {
  return {
    emptyRequiredFields: {
      personal: [],
      public: [],
      photos: [],
    },
  };
}

const state = getInitialState();

export { getInitialState };

export default state;
