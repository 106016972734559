import AmbassadorsBase from '@/models/v2/AmbassadorsBase';

class Locale extends AmbassadorsBase {
  static _type = 'locales';

  static name = 'locale';

  static baseURL = '/locales';

  constructor(args = {}) {
    super(args);
    this.id = args.id;
    this.label = args.label;
  }
}

export default Locale;
