import { render, staticRenderFns } from "./AdminPanel.vue?vue&type=template&id=3396233b&scoped=true"
import script from "./AdminPanel.vue?vue&type=script&lang=js"
export * from "./AdminPanel.vue?vue&type=script&lang=js"
import style0 from "./AdminPanel.vue?vue&type=style&index=0&id=3396233b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3396233b",
  null
  
)

export default component.exports