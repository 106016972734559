import AmbassadorsBase from '@/models/v2/AmbassadorsBase';

class AmbassadorStatus extends AmbassadorsBase {
  static _type = 'ambassador_statuses';

  static name = 'ambassador_status';

  static baseURL = '/ambassador_statuses';

  constructor(args = {}) {
    super(args);
    this.id = args.id;
    this.label = args.label;
    this.added = args.added;
  }
}

export default AmbassadorStatus;
