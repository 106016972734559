import AmbassadorStatus from '@/models/v2/AmbassadorStatus';

const initialState = () => {
  return {
    ambassadorStatuses: [],
  };
};

const actions = {
  async getAmbassadorStatuses({ commit, state }) {
    // check whether statuses have already been saved in state before making API call
    if (state.ambassadorStatuses.length) {
      return state.ambassadorStatuses.length;
    }

    const ambassadorStatuses = await AmbassadorStatus.fetchAll();
    commit('ambassadorStatuses', ambassadorStatuses);
    return ambassadorStatuses;
  },

  async getAmbassadorStatusById({ dispatch }, ambassadorStatusId) {
    const ambassadorStatusRegistry = await dispatch('loadAmbassadorStatusRegistry');
    let ambassadorStatus = ambassadorStatusRegistry[ambassadorStatusId];

    if (ambassadorStatus === undefined) {
      ambassadorStatus = {
        id: ambassadorStatusId,
        label: 'PENDING',
      };
    }

    return ambassadorStatus;
  },

  async loadAmbassadorStatusRegistry({ dispatch, commit, state }) {
    if ('ambassadorStatusRegistry' in state) {
      return state.ambassadorStatusRegistry;
    }

    const ambassadorStatuses = await dispatch('getAmbassadorStatuses');
    const ambassadorStatusRegistry = {};

    for (const ambassadorStatus of ambassadorStatuses) {
      ambassadorStatusRegistry[ambassadorStatus.id] = ambassadorStatus;
    }

    commit('ambassadorStatusRegistry', ambassadorStatusRegistry);
    return ambassadorStatusRegistry;
  },
};

const getters = {
  ambassadorStatuses: state => state.ambassadorStatuses,
};

const mutations = {
  ambassadorStatuses(state, statuses) {
    state.ambassadorStatuses = statuses;
  },
  ambassadorStatusRegistry(state, ambassadorStatusRegistry) {
    state.ambassadorStatusRegistry = ambassadorStatusRegistry;
  },
  resetState(state) {
    Object.assign(state, initialState);
  },
};

const state = initialState;

export default {
  actions,
  getters,
  mutations,
  state,
};
