/* eslint-disable no-shadow-restricted-names */
import Document from '@/models/v2/Document';

const initialState = () => {
  return {
    currentAmbassadorDocuments: {
      ambassadorId: null,
      docsList: null,
    },
  };
};

const actions = {
  async deleteAmbassadorDocument(undefined, payload) {
    return await Document.deleteDocument(payload);
  },

  async getAmbassadorDocuments({ commit }, ambassadorId) {
    const query = { sort: 'added' };
    const args = { ambassador_id: ambassadorId };

    const docsList = await Document.query(query, args);

    commit('currentAmbassadorDocuments', {
      ambassadorId,
      docsList: docsList.reverse(),
    });

    return docsList;
  },

  async saveNewDocument(undefined, { ambassadorId = '', file = {} } = {}) {
    return await Document.newDocument(ambassadorId, file);
  },
};

const getters = {
  currentAmbassadorDocuments: state => state.currentAmbassadorDocuments,
};

const mutations = {
  currentAmbassadorDocuments(state, payload) {
    state.currentAmbassadorDocuments = {
      ambassadorId: payload.ambassadorId,
      docsList: payload.docsList,
    };
  },
  resetState(state) {
    Object.assign(state, initialState);
  },
};

const state = initialState;

export default {
  actions,
  getters,
  mutations,
  state,
};
