<template>
  <component
    :is="userId ? 'router-link' : 'div'"
    class="user-card"
    :class="userId ? 'router-link' : 'disabled'"
    :to="userId ? { name: 'userProfile', params: { id: userId } } : null"
  >
    <div v-if="!userId" class="lock-label flex-space-between">
      {{ content.lockLabel }}<v-icon class="lock-icon" small>lock</v-icon>
    </div>
    <div class="user-role lll-hr-grey">
      <div>{{ content.role }}: {{ role }}</div>
      <div v-if="store">{{ content.store }}: {{ store }}</div>
    </div>
    <div class="h-primary">{{ name }}</div>
    <div class="h-label">{{ jobTitle }}</div>
    <div class="break-word">{{ email }}</div>
  </component>
</template>

<script>
import content from '@/content/staticContentForUI';

export default {
  name: 'UsersListCard',

  props: {
    userId: {
      type: String,
      default: '',
    },
    role: {
      type: String,
      default: '',
    },
    store: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    email: {
      type: String,
      default: '',
    },
    jobTitle: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      content: content.usersListCard,
    };
  },
};
</script>

<style lang="scss" scoped>
.user-card {
  background-color: $lll-color-white;
  padding: 15px;
}

.user-role {
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.lock-icon {
  margin-left: 15px;
}

.lock-label {
  margin-bottom: 10px;
}

.disabled {
  opacity: 0.5;
}
</style>
