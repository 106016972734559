import PermissionsBase from '@/models/PermissionsBase';
import Role from '@/models/Role';
import Claim from '@/models/Claim';

class User extends PermissionsBase {
  static _type = 'custom_permissions';

  static name = 'custom_permission';

  static baseURL = '/custom_permissions';

  constructor(args = {}) {
    super(args);

    this.id = args.id;
    this.email = args.email;
    this.name = args.name;
    this.job_title = args.job_title;
    this.last_authenticated_date = args.last_authenticated_date;

    // Related Models
    this.role = this.belongsTo(Role, args.role);
    this.custom_claims = this.hasMany(Claim, args.custom_claims);
  }
}

export default User;
