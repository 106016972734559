<template>
  <div :class="[showAddressSearch ? 'row' : '']">
    <div v-if="showAddressSearch" class="lll-form-field-bottom-margin">
      <amb-form-field-label
        :attribute-id="addressType"
        :custom-field-id="`update-${addressType}`"
        :show-asterisk="showAsterisk"
      />
      <amb-pending-label
        v-if="!userPermissions.canEditRecords"
        class="lll-form-field-display-data"
      />
      <div v-else class="address-section grid-3-col-responsive">
        <div class="row address-search-section">
          <profile-details-form-field
            show-form-input
            :section="section"
            field-type="autocomplete"
            :attribute-id="formatAttributeId('country')"
            :items="inputSelectionItems.countries"
            :no-data-text="noDataText.default"
            @edit="enableEdit"
          />
          <profile-details-form-field
            show-form-input
            custom-label
            :section="section"
            attribute-id="address_search"
            class="address-search-form-field"
            @edit="enableEdit"
          >
            <template #label>
              <amb-form-field-label
                attribute-id="address_search"
                :custom-field-id="addressSearchInputId"
              />
            </template>
            <template #input>
              <input-address-search
                :input-id="addressSearchInputId"
                :country-id="countryId"
                :disabled="!section.editing"
                @addressSelected="populateSelectedAddress"
                @allowManualAddressEntry="editingAddressFields = true"
              />
            </template>
          </profile-details-form-field>
        </div>
        <div v-show="!showAddressFormFields" class="row mt-2">
          <div class="hint-text">{{ fieldText.address_search.note }}</div>
          <button
            type="button"
            class="btn-link"
            :disabled="!section.editing"
            @click.prevent="editAddressManually"
          >
            <v-icon class="pr-1">
              edit
            </v-icon>
            {{ fieldText.address_search.noteCTA }}
          </button>
        </div>
        <div v-show="showAddressFormFields" class="row grid-3-col-responsive">
          <profile-details-form-field
            show-form-input
            :section="section"
            field-type="text"
            :attribute-id="formatAttributeId('address_line_1')"
            class="row"
          />
          <profile-details-form-field
            show-form-input
            :section="section"
            field-type="text"
            :attribute-id="formatAttributeId('address_line_2')"
            class="row"
          />
          <profile-details-form-field
            show-form-input
            :section="section"
            field-type="text"
            :attribute-id="formatAttributeId('city')"
          />
          <profile-details-form-field
            show-form-input
            :section="section"
            field-type="text"
            :attribute-id="formatAttributeId('state')"
          />
          <profile-details-form-field
            show-form-input
            :section="section"
            field-type="text"
            :attribute-id="formatAttributeId('zip_code')"
          />
        </div>
      </div>
    </div>
    <div v-else>
      <profile-details-form-field
        :attribute-id="addressType"
        custom-label
        custom-display-data
        :edits-disabled="editsDisabled"
        :section="section"
        @edit="enableEdit"
      >
        <template #label>
          <amb-form-field-label :attribute-id="addressType" :show-asterisk="!addressComplete" />
        </template>
        <template #display-data>
          <div class="lll-form-field-display-data">
            <div class="mb-1">{{ displayData.line1 }}</div>
            <div v-show="displayData.line2" class="mb-1">
              {{ displayData.line2 }}
            </div>
            <div class="mb-1">{{ displayData.cityState }}</div>
            <div class="mb-1">
              {{ displayData.country }}
            </div>
            <div>{{ displayData.zip }}</div>
          </div>
        </template>
      </profile-details-form-field>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import content from '@/content/staticContentForUI';
import * as formHelpers from '@/utils/formHelpers';
import AMBFormFieldLabel from '@/components/AMBFormFieldLabel';
import AMBPendingLabel from '@/components/AMBPendingLabel';
import InputAddressSearch from '@/components/InputAddressSearch';
import ProfileDetailsFormField from '@/components/ProfileDetailsFormField';

export default {
  name: 'ProfileDetailsAddressBlock',

  components: {
    'amb-form-field-label': AMBFormFieldLabel,
    'amb-pending-label': AMBPendingLabel,
    'input-address-search': InputAddressSearch,
    'profile-details-form-field': ProfileDetailsFormField,
  },

  props: {
    addressType: {
      required: true,
      type: String,
      default: 'home_address',
    },
    ambassador: {
      required: true,
      type: Object,
      default: () => {},
    },
    editsDisabled: {
      type: Boolean,
      default: false,
    },
    section: {
      required: true,
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      editingAddressFields: false,
      fieldText: content.formFields,
      inputSelectionItems: content.inputSelectionItems,
      noDataText: content.noDataText,
    };
  },

  computed: {
    ...mapGetters(['userPermissions']),

    addressSearchInputId() {
      // convert addressType to kebab case (e.g., 'home_address' => 'home-address')
      return `${formHelpers.convertSnakeToKebab(this.addressType)}-search`;
    },

    displayData() {
      return {
        country: this.getItemLabel('countries', this.formatAttributeId('country')),
        line1: this.getAttributeValue('address_line_1'),
        line2: this.getAttributeValue('address_line_2'),
        cityState: this.ambassadorCityAndState,
        zip: this.getAttributeValue('zip_code'),
      };
    },

    ambassadorCityAndState() {
      let state = `, ${this.getAttributeValue('state')}`;

      if (this.getAttributeValue('state') === 'not applicable') {
        state = '';
      }

      return `${this.getAttributeValue('city')}${state}`;
    },

    countryId() {
      const country = this.section.formFields[this.formatAttributeId('country')];

      return country?.id || country;
    },

    addressComplete() {
      const addressFieldsToComplete = [
        this.formatAttributeId('address_line_1'),
        this.formatAttributeId('city'),
        this.formatAttributeId('state'),
        this.formatAttributeId('zip_code'),
      ];
      let emptyFields = 0;

      addressFieldsToComplete.forEach(field => {
        if (!this.ambassador[field]) {
          emptyFields += 1;
        }
      });

      return emptyFields === 0;
    },

    homeAddressObject: {
      get() {
        const addressObj = {
          address_line_1: this.getFieldValue('address_line_1'),
          address_line_2: this.getFieldValue('address_line_2'),
          city: this.getFieldValue('city'),
          state: this.getFieldValue('state'),
          zip_code: this.getFieldValue('zip_code'),
          country: this.getFieldValue('country'),
        };

        return addressObj;
      },
      set(addressObj) {
        this.section.formFields[this.addressType] = addressObj;
      },
    },

    showAsterisk() {
      if (this.addressType === 'emergency_address') {
        return false;
      }

      return !this.addressComplete && this.userPermissions.canEditRecords;
    },

    showAddressSearch() {
      return this.section.editing || !this.addressComplete;
    },

    showAddressFormFields() {
      return this.editingAddressFields || this.addressComplete;
    },
  },

  methods: {
    editAddressManually() {
      if (!this.section.editing) {
        this.enableEdit();
      }

      this.editingAddressFields = true;

      this.$nextTick(() => {
        this.scrollToTop();
      });
    },

    enableEdit() {
      this.$emit('edit');
    },

    formatAttributeId(attributeId) {
      if (this.addressType === 'emergency_address') {
        return `contact_${attributeId}`;
      }

      return attributeId;
    },

    getAttributeValue(attributeId) {
      return this.ambassador[this.formatAttributeId(attributeId)];
    },

    getItemLabel(listName, attributeName) {
      const items = this.inputSelectionItems[listName];
      const listItem = items.find(item => {
        return item.id === this.ambassador[attributeName];
      });

      return listItem ? listItem.label : '';
    },

    getFieldValue(fieldName) {
      return this.section.formFields[this.formatAttributeId(fieldName)];
    },

    // handles data passed to parent from <input-address-search /> component
    populateSelectedAddress(addressObj) {
      this.editingAddressFields = true;

      const addressObjFormatted = {
        address_line_1: addressObj.Line1,
        address_line_2: addressObj.Line2,
        city: addressObj.City,
        state: addressObj.Province || 'not applicable',
        zip_code: addressObj.PostalCode,
        country: this.getFieldValue('country'),
      };

      const fields = this.section.formFields;

      fields[this.addressType] = addressObjFormatted;
      fields[this.formatAttributeId('address_line_1')] = addressObj.Line1;
      fields[this.formatAttributeId('address_line_2')] = addressObj.Line2;
      fields[this.formatAttributeId('state')] = addressObj.Province || 'not applicable';
      fields[this.formatAttributeId('city')] = addressObj.City;
      fields[this.formatAttributeId('zip_code')] = addressObj.PostalCode;
    },

    resetFields() {
      this.editingAddressFields = false;
    },

    scrollToTop() {
      this.$scrollTo(this.$el);
    },
  },
};
</script>

<style lang="scss" scoped>
.address-section {
  border: 1px solid $lll-color-black;
  margin-top: 5px;
  padding: 5vw;

  @media (min-width: 700px) {
    padding: 25px;
  }

  .grid-3-col-responsive {
    grid-column-gap: 25px !important;
  }
}

.address-search-section {
  display: grid;
  grid-column-gap: 25px;
  grid-template-columns: 1fr;

  @media (min-width: 1200px) {
    grid-template-columns: 1fr 2fr;
  }
}

.address-search-form-field {
  padding-bottom: 10px;

  @media (min-width: 1200px) {
    padding-bottom: 0;
  }
}
</style>
