import axios from 'axios';
import Claim from '@/models/Claim';
import Role from '@/models/Role';
import User from '@/models/User';
import get from '@/utils/config';
import { getAuthToken } from '@/vuex/modules/auth';

// update this list if new roles are added to the permissions service
const ROLE_NAMES_MAP = {
  // roles in custom_permissions table
  admin: 'Admin',
  content_ops: 'Content Ops',
  exec: 'Exec',
  ssc_team: 'SSC Team',
  // store roles not in custom_permissions table
  area_manager: 'Area Manager',
  store_manager: 'Store Manager',
  store_staff: 'Store Staff',
};

const initialState = () => {
  return {
    claims: [],
    currentUser: new User(),
    filterBy: [],
    needsProfile: '',
    needsRole: '',
    paginationDescending: false,
    paginationPage: '',
    paginationRowsPerPage: '',
    roles: [],
    searchInput: '',
    searchResults: [],
    sortBy: '',
    users: [],
  };
};

const actions = {
  /**
   * Fetches all claims in the Ambassador Permissions Service and updates the value of 'claims'
   * in state. The claim details should include id, name, and description.
   *
   * @param {Object} context - First param is reserved for context object
   * @returns {Array of Objects} List of claims with id, name, and description for each
   */
  async getClaims({ commit, state }) {
    // check whether claims have already been saved in state before making API call
    if (state.claims.length) {
      return state.claims;
    }

    const claims = await Claim.query({ sort: 'name' });

    commit('claims', claims);
    return claims;
  },

  /**
   * Fetches all roles in the Ambassador Permissions Service and updates the value of 'roles'
   * in state. The role details should include id, name, label, description, and list of claims
   * associated with the role.
   *
   * @param {Object} context - First param is reserved for context object
   * @returns {Array of Objects} List of roles with id, name, label, description, and claims for each
   */
  async getRoles({ commit, dispatch, state }) {
    // check whether roles have already been saved in state before making API call
    if (state.roles.length) {
      return state.roles;
    }

    // sort list by item name so UI displays consistent order
    const roles = await Role.query({ sort: 'name' });

    // update role.claims so role-specific claim attributes are populated and claims list is sorted
    const claims = await dispatch('getClaims');
    const updatedRoles = roles.map(role => {
      role.claims.forEach(roleClaim => {
        const claimDetails = claims.find(claim => claim.id === roleClaim.id);
        roleClaim.name = claimDetails.name;
        roleClaim.description = claimDetails.description;
      });

      role.claims = role.claims.sort((left, right) => {
        // eslint-disable-next-line prefer-template
        return ('' + left.name).localeCompare(right.name);
      });

      return role;
    });

    commit('roles', updatedRoles);
    return updatedRoles;
  },

  /**
   * Fetches all users with custom permissions (e.g., Admin, Content Ops, Exec, SSC Team) and
   * updates the value of 'users' in state. This list does not include users with a store role
   * (e.g., Store Manager), since they are not saved in the Ambassador Permissions Service.
   *
   * @param {Object} context - First param is reserved for context object
   * @returns {Array of Objects} List of users and their profile details
   */
  async getUsers({ commit, state }) {
    // check whether users have already been saved in state before making API call
    if (state.users.length) {
      return state.users;
    }

    const users = await User.query({ include: 'role' });

    commit('users', users);
    return users;
  },

  /**
   * Searches for user by email and updates the value of 'searchResults' in state.
   *
   * @param {Object} context - First param is reserved for context object
   * @param {String} email - Email address to search
   * @returns No return value
   */
  async searchForUser({ commit }, email) {
    const user = await getEmployeeData(email).catch(error => {
      if (error?.response?.status === 400) {
        commit('needsProfile', email);
      } else {
        throw error;
      }
    });

    // in this case, searchResults are not updated
    if (!user) {
      return;
    }

    if (user.role === undefined) {
      commit('needsRole', email);
    }

    commit('searchResults', [formatUserDetails(user)]);
  },

  /**
   * Fetches the most recent data for a given user and updates the value of
   * 'currentUser' saved in state. This ensures all components have access to the
   * latest data for that user.
   *
   * @param {Object} context - First param is reserved for context object
   * @param {String} id - 'id' attribute from User model
   * @returns No return value
   */
  async updateCurrentUser({ commit }, id) {
    const currentUser = await User.fetch(id, { include: 'role,custom_claims' });
    commit('currentUser', currentUser);
  },
};

const getters = {
  claims: state => state.claims,
  currentUser: state => state.currentUser,
  filterBy: state => state.filterBy,
  needsProfile: state => state.needsProfile,
  needsRole: state => state.needsRole,
  paginationDescending: state => state.paginationDescending,
  paginationPage: state => state.paginationPage,
  paginationRowsPerPage: state => state.paginationRowsPerPage,
  roles: state => state.roles,
  sortBy: state => state.sortBy,
  users: state => state.users,
  searchInput: state => state.searchInput,
  searchResults: state => state.searchResults,
};

const mutations = {
  claims(state, claims) {
    state.claims = claims;
  },
  currentUser(state, currentUser) {
    state.currentUser = currentUser;
  },
  filterBy(state, filterBy) {
    state.filterBy = filterBy;
  },
  needsProfile(state, needsProfile) {
    state.needsProfile = needsProfile;
  },
  needsRole(state, needsRole) {
    state.needsRole = needsRole;
  },
  paginationDescending(state, paginationDescending) {
    state.paginationDescending = paginationDescending;
  },
  paginationPage(state, paginationPage) {
    state.paginationPage = paginationPage;
  },
  paginationRowsPerPage(state, paginationRowsPerPage) {
    state.paginationRowsPerPage = paginationRowsPerPage;
  },
  roles(state, roles) {
    state.roles = roles;
  },
  sortBy(state, sortBy) {
    state.sortBy = sortBy;
  },
  users(state, users) {
    state.users = users;
  },
  updateSearchInput(state, searchInput) {
    state.searchInput = searchInput;
  },
  searchResults(state, searchResults) {
    state.searchResults = searchResults;
  },
  resetState(state) {
    Object.assign(state, initialState);
  },
};

const state = initialState;

// HELPER FUNCTIONS

/**
 * Fetches the MS Graph (Workday) user data by email. If the user has been assigned a role or
 * custom permissions in the Ambassador Permissions Service, the role, store ID (if applicable),
 * and custom_permission_id should be included in the response.
 *
 * @param {String} email - Email address to search
 * @returns {Object} Employee data from MS Graph, plus user permissions if applicable
 */
const getEmployeeData = async email => {
  const host = get('VUE_APP_PERMISSIONS_HOST');
  const url = `${host}/v1/user/${email}`;
  const token = getAuthToken();
  const user = await axios
    .create({ headers: { Authorization: `Bearer ${token}` } })
    .get(url)
    .then(response => response.data);

  return user;
};

// make values for 'role' and 'store' more reader-friendly
const formatUserDetails = user => {
  if (!user || !user.role) {
    return user;
  }

  const roleFormatted = ROLE_NAMES_MAP[user.role];

  if (!roleFormatted) {
    console.error(
      'Cannot format "role" name in user data. Confirm ROLE_NAMES_MAP contains all roles from the service.'
    );
  } else {
    user.role = roleFormatted;
  }

  if (user.store) {
    const storeListConvertedToString = Array.from(user.store).join(', ');
    user.store = storeListConvertedToString;
  }

  return user;
};

export { getEmployeeData };

export default {
  actions,
  getters,
  mutations,
  state,
};
