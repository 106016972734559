<template>
  <div>
    <div class="container-gallery-uploader">
      <div class="gallery-uploader-layout lll-bg-grey">
        <div
          v-if="errorMsg.show"
          class="gallery-uploader-error lll-notification-error row text-xs-center"
        >
          <p class="compact">
            {{ errorMsg.text }}
          </p>
          <p v-if="errorMsg.details !== 'none'" class="font-italic break-word compact">
            {{ errorMsg.details }}
          </p>
        </div>
        <div class="gallery-uploader-body row">
          <button v-if="loading" name="btn-loading" class="btn btn-primary pl-5 pr-5" disabled>
            <v-progress-circular :indeterminate="true" />
          </button>
          <div v-show="!loading" class="btn-group-1-col pt-0">
            <button
              name="file-picker-single"
              class="btn btn-primary file-picker-button"
              :disabled="disableFilePicker"
              @click.prevent="launchFilePickerSingle"
            >
              {{ content.filePickerButtonSingle }}
            </button>
            <input
              v-show="false"
              id="input-add-profile-photo"
              ref="filePickerProfilePhoto"
              type="file"
              accept=".jpg, .jpeg, .png"
              @change="handleProfilePhotoUpload"
            />
            <button
              name="file-picker-multiple"
              class="btn file-picker-button"
              :disabled="disableFilePicker"
              @click.prevent="launchFilePickerMultiple"
            >
              {{ content.filePickerButtonMultiple }}
            </button>
            <input
              v-show="false"
              id="input-add-gallery-photos"
              ref="filePickerGalleryPhotos"
              type="file"
              accept=".jpg, .jpeg, .png"
              multiple
              @change="handleGalleryUploads"
            />
          </div>
          <div class="lll-vr-grey lll-hr-grey divider-responsive" />
          <div>
            <h1 class="h-label mb-3">
              {{ content.photoRequirements.heading }}
            </h1>
            <h2 class="h-primary mb-2">
              {{ content.photoRequirements.fileType }}
            </h2>
            <h2 class="h-primary mb-2">
              {{ content.photoRequirements.maxSize }}
            </h2>
            <h2 class="h-primary mb-2">
              {{ content.photoRequirements.orientation }}
            </h2>
            <h2 class="h-primary mb-2">
              {{ content.photoRequirements.photoLimitLabel }} {{ photoLimit }}
            </h2>
          </div>
        </div>
      </div>
    </div>
    <amb-modal hide-close-button :open-modal.sync="imageCropperIsOpen">
      <photo-gallery-cropper
        ref="imageCropper"
        :ambassador-id="ambassadorId"
        :current-profile-photo="currentProfilePhoto"
        @closeImageCropper="closeImageCropper"
        @photoSaved="resetGallery"
      />
    </amb-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import content from '@/content/staticContentForUI';
import { customMessages } from '@/utils/errorHandlers';
import AMBModal from '@/components/AMBModal';
import PhotoGalleryCropper from '@/components/PhotoGalleryCropper';

export default {
  name: 'PhotoGalleryUploader',

  components: {
    'amb-modal': AMBModal,
    'photo-gallery-cropper': PhotoGalleryCropper,
  },

  props: {
    ambassadorId: {
      type: String,
      default: '',
    },
    currentProfilePhoto: {
      type: Object,
      default: () => {},
    },
    openModal: {
      type: Boolean,
      default: false,
    },
    photosList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      content: content.photoGalleryUploader,
      disableFilePicker: false,
      errorMsg: {
        show: false,
        text: '',
        details: '',
      },
      errorMessageCopy: customMessages.photoGalleryUploader,
      imageCropperIsOpen: false,
      loading: false,
      photoLimit: 15,
    };
  },

  watch: {
    // refreshes error message when uploader opens (value == true) and closes (value == false)
    openModal(value) {
      if (value) {
        this.checkPhotoLimit();
      } else {
        this.resetUploader();
      }
    },
  },

  methods: {
    ...mapActions(['saveNewGalleryPhoto', 'saveNewProfilePhoto']),

    checkPhotoLimit() {
      if (this.photoLimit - this.photosList.length <= 0) {
        this.disableFilePicker = true;
        this.updateGalleryErrorMsg(true, this.errorMessageCopy.galleryFull);
      }
    },

    closeImageCropper() {
      // release object URL that was created when 'cropAndSaveProfilePhoto()' was called
      URL.revokeObjectURL(this.objectUrl);
      this.imageCropperIsOpen = false;
    },

    closeModal() {
      this.$emit('update:openModal', false);
    },

    cropAndSaveProfilePhoto(uploadedFile) {
      const imageUrl = URL.createObjectURL(uploadedFile);
      // Set data prop 'uploadedFileBlob' on cropper component so 'originalImage' src
      // is ready before cropper is setup. This allows the preview to display properly.
      this.$refs.imageCropper.uploadedFileBlob = imageUrl;
      this.$refs.imageCropper.setupImageCropper();
      this.imageCropperIsOpen = true;
    },

    fileSizesAreValid(fileList) {
      const { part1, part2Singular, part2Plural } = this.errorMessageCopy.invalidFileSize;

      const invalidFiles = [];

      for (let idx = 0; idx < fileList.length; idx++) {
        const file = fileList[idx];
        const max = 5 * 1024 * 1024;

        if (file.size > max) {
          invalidFiles.push(file.name);
        }
      }

      if (invalidFiles.length) {
        const invalidFilesList = invalidFiles.map(i => `"${i}"`).join(', ');
        let part2 = part2Singular;

        if (invalidFiles.length > 1) {
          part2 = part2Plural;
        }

        this.updateGalleryErrorMsg(true, `${part1} ${part2}`, invalidFilesList);

        return false;
      }

      return true;
    },

    fileTypesAreValid(fileList) {
      const { part1, part2Singular, part2Plural } = this.errorMessageCopy.invalidFileType;

      const invalidFiles = [];

      for (let idx = 0; idx < fileList.length; idx++) {
        const file = fileList[idx];
        const validFileTypes = ['image/jpeg', 'image/png'];

        if (!validFileTypes.includes(file.type)) {
          invalidFiles.push(file.name);
        }
      }

      if (invalidFiles.length > 0) {
        const invalidFilesList = invalidFiles.map(i => `"${i}"`).join(', ');
        let part2 = part2Singular;

        if (invalidFiles.length > 1) {
          part2 = part2Plural;
        }

        this.updateGalleryErrorMsg(true, `${part1} ${part2}`, invalidFilesList);

        return false;
      }

      return true;
    },

    fileLimitExceeded(fileList) {
      const { part1, part2Singular, part2Plural } = this.errorMessageCopy.fileLimitExceeded;

      const allowedUploadCount = this.photoLimit - this.photosList.length;

      if (fileList.length > allowedUploadCount) {
        let part2 = part2Plural;
        if (allowedUploadCount === 1) {
          part2 = part2Singular;
        }

        this.updateGalleryErrorMsg(true, `${part1} ${allowedUploadCount} ${part2}`);

        return true;
      }

      return false;
    },

    handleProfilePhotoUpload() {
      const file = this.$refs.filePickerProfilePhoto.files[0];

      if (!this.readyToSave(file)) {
        return;
      }

      this.cropAndSaveProfilePhoto(file);

      // TODO: NOTICKET - After UAT is done for photo cropper, remove this if it's not needed
      // this.loading = true;
      // sessionStorage.setItem('photoUploadInProgress', true);

      // let payload = {
      //   ambassadorId: this.ambassadorId,
      //   newProfilePhoto: file,
      //   currentProfilePhoto: this.currentProfilePhoto,
      // };

      // this.saveNewProfilePhoto(payload)
      //   .then(() => {
      //     this.$emit('updatePhotosList');
      //     this.loading = false;
      //     sessionStorage.removeItem('photoUploadInProgress');
      //     this.closeModal();
      //   })
      //   .catch(error => {
      //     this.loading = false;
      //     this.$handleErrorMessage(error);
      //     sessionStorage.removeItem('photoUploadInProgress');
      //   });
    },

    handleGalleryUploads() {
      const fileList = this.$refs.filePickerGalleryPhotos.files;

      if (!this.readyToSave(fileList)) {
        return;
      }

      this.loading = true;
      sessionStorage.setItem('photoUploadInProgress', true);

      this.saveGalleryPhotos(fileList)
        .then(() => {
          this.loading = false;
          sessionStorage.removeItem('photoUploadInProgress');
          this.resetGallery();
        })
        .catch(error => {
          this.loading = false;
          this.$handleErrorMessage(error);
          sessionStorage.removeItem('photoUploadInProgress');
        });
    },

    launchFilePickerSingle() {
      this.resetUploader();
      this.$refs.filePickerProfilePhoto.click();
    },

    launchFilePickerMultiple() {
      this.resetUploader();
      this.$refs.filePickerGalleryPhotos.click();
    },

    readyToSave(fileList) {
      if (fileList.length <= 0) {
        return false;
      }

      if (!this.fileTypesAreValid(fileList)) {
        return false;
      }

      if (!this.fileSizesAreValid(fileList)) {
        return false;
      }

      if (this.fileLimitExceeded(fileList)) {
        return false;
      }

      return true;
    },

    resetGallery() {
      this.$emit('updatePhotosList');
      this.closeModal();
    },

    resetUploader() {
      this.updateGalleryErrorMsg(false);
      this.$refs.filePickerGalleryPhotos.value = '';
      this.$refs.filePickerProfilePhoto.value = '';
      this.disableFilePicker = false;
    },

    async saveGalleryPhotos(fileList) {
      const photos = [];

      for (let idx = 0; idx < fileList.length; idx++) {
        // eslint-disable-next-line no-await-in-loop
        const savedPhoto = await this.saveNewGalleryPhoto({
          ambassadorId: this.ambassadorId,
          photoData: fileList[idx],
        });

        photos.push(savedPhoto);
      }

      return photos;
    },

    updateGalleryErrorMsg(show, text = '', details = '') {
      // Boolean
      this.errorMsg.show = show;
      // String
      this.errorMsg.text = text;
      // String
      this.errorMsg.details = details;
    },
  },
};
</script>

<style lang="scss" scoped>
$breakpoint-sm: 600px;
$breakpoint-med: 1000px;

.container-gallery-uploader {
  padding: 5vw;

  @media (min-width: $breakpoint-med) {
    padding: 50px 7vw;
  }
}

.gallery-uploader-layout {
  display: grid;
  grid-template-columns: 1fr minmax(75%, 90%) 1fr;
  padding: 5vw;
}

.gallery-uploader-body {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 50px;
  grid-row-gap: 3vw;
  padding: 10vmin 0;
  align-items: center;
  justify-items: center;

  @media (min-width: $breakpoint-med) {
    grid-column: 2;
    grid-template-columns: 1fr min-content 1fr;
  }
}

.file-picker-button {
  @media (min-width: $breakpoint-sm) {
    white-space: nowrap;
  }
}

.gallery-uploader-error {
  @media (min-width: $breakpoint-med) {
    grid-column: 2;
  }
}

.divider-responsive {
  @media (max-width: $breakpoint-med) {
    background-color: RGBA(255, 255, 255, 0);
    padding: 0;
    margin-bottom: 15px;
    width: 75%;
  }

  @media (max-width: $breakpoint-sm) {
    width: 100%;
  }
}
</style>
