import AmbassadorsBase from '@/models/v2/AmbassadorsBase';
import AmbassadorStatus from '@/models/v2/AmbassadorStatus';
import AmbassadorType from '@/models/v2/AmbassadorType';
import Experience from '@/models/v2/Experience';
import Photo from '@/models/v2/Photo';
import Locale from '@/models/v2/Locale';
import SubDiscipline from '@/models/v2/SubDiscipline';
import ProductCategory from '@/models/v2/ProductCategory';

class Ambassador extends AmbassadorsBase {
  static _type = 'ambassadors';

  static name = 'ambassador';

  static baseURL = '/ambassadors';

  constructor(args = {}) {
    super(args);
    // UUID
    this.id = args.id;

    // Okta ID
    this.okta_id = args.okta_id;

    // Timestamps
    this.added = args.added;
    this.updated = args.updated;

    // Satus and Contract
    this.termination_reason = args.termination_reason;
    this.start_date = args.start_date;
    this.end_date = args.end_date;
    this.publish_status = args.publish_status;
    this.communication_consent = args.communication_consent;
    this.legal_consent = args.legal_consent;
    this.store_id = args.store_id;
    this.profile_url_slug = args.profile_url_slug;
    this.starting_status = args.starting_status;
    this.mirror = args.mirror;

    // Personal Details
    this.first_name = args.first_name;
    this.last_name = args.last_name;
    this.birth_date = args.birth_date;
    this.biography = args.biography;
    this.fact_1 = args.fact_1;
    this.fact_2 = args.fact_2;
    this.fact_3 = args.fact_3;
    this.fact_4 = args.fact_4;
    this.fact_5 = args.fact_5;
    this.gender = args.gender;
    this.language = args.language;
    this.dietary_restrictions = args.dietary_restrictions;
    this.top_size = args.top_size;
    this.bottom_size = args.bottom_size;
    this.shoe_size = args.shoe_size;
    this.outerwear_size = args.outerwear_size;

    // Ambassador Contact Info
    this.email = args.email;
    this.phone = args.phone;
    this.address_line_1 = args.address_line_1;
    this.address_line_2 = args.address_line_2;
    this.city = args.city;
    this.state = args.state;
    this.country = args.country;
    this.zip_code = args.zip_code;

    // Emergency Contact Info
    this.contact_name = args.contact_name;
    this.contact_email = args.contact_email;
    this.contact_phone = args.contact_phone;
    this.contact_country = args.contact_country;
    this.contact_address_line_1 = args.contact_address_line_1;
    this.contact_address_line_2 = args.contact_address_line_2;
    this.contact_city = args.contact_city;
    this.contact_state = args.contact_state;
    this.contact_zip_code = args.contact_zip_code;

    // Social Media
    this.instagram = args.instagram;
    this.instagram_2 = args.instagram_2;
    this.instagram_followers = args.instagram_followers;
    this.twitter = args.twitter;
    this.facebook = args.facebook;
    this.youtube = args.youtube;
    this.website = args.website;
    this.strava = args.strava;
    this.wechat = args.wechat;
    this.weibo = args.weibo;
    this.tiktok = args.tiktok;

    // Misc
    this.gift_card_number = args.gift_card_number;
    this.egiftcard_sent = args.egiftcard_sent;
    this.studio_business_name = args.studio_business_name;
    this.affiliated_brands = args.affiliated_brands;
    this.campaign_status = args.campaign_status;
    this.public_speaking_experience = args.public_speaking_experience;
    this.experience_modelling_or_casting = args.experience_modelling_or_casting;

    // Relationship Manager Details
    this.relationship_manager_name = args.relationship_manager_name;
    this.relationship_manager_email = args.relationship_manager_email;

    // Related Models
    this.ambassador_type = this.belongsTo(AmbassadorType, args.ambassador_type);
    this.ambassador_status = this.belongsTo(AmbassadorStatus, args.ambassador_status);
    this.sub_discipline = this.belongsTo(SubDiscipline, args.sub_discipline);
    this.profile_photo = this.belongsTo(Photo, args.profile_photo);
    this.profile_locale = this.belongsTo(Locale, args.profile_locale);
    this.experiences = this.hasMany(Experience, args.experiences);
    this.product_categories = this.hasMany(ProductCategory, args.product_categories);
  }
}

export default Ambassador;
