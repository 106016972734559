<template>
  <div>
    <v-form ref="searchForm" role="search" @submit.prevent="search">
      <v-text-field
        id="user-search"
        v-model.trim="email"
        name="users-search"
        browser-autocomplete="off"
        solo
        flat
        clearable
        :placeholder="content.placeholder"
        :rules="validation"
        aria-label="Search users by email address"
        prepend-inner-icon="search"
        @click:prepend-inner="search"
        @click:clear="clear"
      />
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import content from '@/content/staticContentForUI';
import { VALIDATION_RULES } from '@/utils/formHelpers/validation';

export default {
  name: 'UserListSearch',

  data() {
    return {
      content: content.usersListSearch,
      validation: [VALIDATION_RULES.email_lll],
    };
  },

  computed: {
    ...mapGetters(['searchInput']),

    email: {
      get() {
        return this.searchInput;
      },
      set(value) {
        this.$store.commit('updateSearchInput', value);
      },
    },
  },

  methods: {
    clear() {
      this.$store.commit('updateSearchInput', '');
      this.$emit('clear');
    },

    search() {
      const formValid = this.$refs.searchForm.validate();

      if (!formValid) {
        return;
      }

      this.$emit('search', this.email);
    },
  },
};
</script>
