<template>
  <v-app>
    <nav-header />
    <nav-breadcrumbs />
    <div class="container-router-view">
      <router-view />
    </div>
    <admin-panel />
  </v-app>
</template>

<script>
import { mapActions } from 'vuex';
import NavHeader from '@/components/NavHeader';
import NavBreadcrumbs from '@/components/NavBreadcrumbs';
import AdminPanel from '@/components/AdminPanel';

export default {
  components: {
    'nav-header': NavHeader,
    'nav-breadcrumbs': NavBreadcrumbs,
    'admin-panel': AdminPanel,
  },

  created() {
    this.initAuth();
  },

  methods: {
    ...mapActions(['initAuth']),
  },
};
</script>

<style lang="scss">
@import '@/styles/global.scss';

.container-router-view {
  padding: 0 0 125px 0;
}
</style>
