<template>
  <nav v-if="showBreadcrumbs" aria-label="Breadcrumb" class="container-breadcrumbs">
    <ol class="breadcrumbs">
      <li
        v-for="(breadcrumb, idx) in breadcrumbList"
        :key="idx"
        :class="[idx === lastListItem ? '' : 'item-divider']"
      >
        <router-link
          :to="{ name: breadcrumb.link }"
          :class="[!!breadcrumb.link ? 'breadcrumb-link' : 'not-link', 'breadcrumb-item']"
        >
          {{ breadcrumb.name }}
        </router-link>
      </li>
    </ol>
  </nav>
</template>

<script>
export default {
  name: 'NavBreadcrumbs',

  data() {
    return {
      breadcrumbList: [],
      showBreadcrumbs: false,
    };
  },

  computed: {
    lastListItem() {
      return this.breadcrumbList.length - 1;
    },
  },

  watch: {
    $route() {
      this.updateList();
    },

    breadcrumbList(value) {
      if (value) {
        this.showBreadcrumbs = true;
      } else {
        this.showBreadcrumbs = false;
      }
    },
  },

  mounted() {
    this.updateList();
  },

  methods: {
    updateList() {
      this.breadcrumbList = this.$route.meta.breadcrumb;
    },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumbs {
  display: inline-flex;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 0.75rem;
  flex-wrap: wrap;

  @media (min-width: 600px) {
    font-size: 1rem;
  }
}

.breadcrumb-item {
  color: $lll-color-charcoal;
  text-transform: uppercase;
}

.breadcrumb-link {
  text-decoration: none;

  &:hover {
    color: $lll-color-black;
  }

  &:active {
    color: $lll-color-charcoal;
  }
}

.container-breadcrumbs {
  padding: 5vw;

  @media (min-width: 600px) {
    padding: 25px 5vw;
    margin-bottom: 25px;
  }
}

a {
  &.not-link {
    cursor: default;
    text-decoration: none;
  }
}

li.item-divider {
  &::after {
    content: '/';
    color: $lll-color-grey-dark;
    display: inline-block;
    font-weight: 300;
    margin: 0 0.5rem 0 0.25rem;
  }
}
</style>
