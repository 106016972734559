import ProductCategory from '@/models/v2/ProductCategory';

const initialState = () => {
  return {
    productCategories: [],
  };
};

const actions = {
  async getProductCategories({ commit, state }) {
    // check whether productCategories have already been saved in state before making API call
    if (state.productCategories.length) {
      return state.productCategories;
    }

    // sort list by item label so UI displays consistent order
    const productCategories = await ProductCategory.query({ sort: 'label' });

    commit('productCategories', productCategories);
    return productCategories;
  },
};

const getters = {
  productCategories: state => state.productCategories,
};

const mutations = {
  productCategories(state, productCategories) {
    state.productCategories = productCategories;
  },
  resetState(state) {
    Object.assign(state, initialState);
  },
};

const state = initialState;

export default {
  actions,
  getters,
  mutations,
  state,
};
