<template>
  <header class="nav-header-container lll-header flex-space-between">
    <div class="logo">
      <router-link :to="{ path: '/ambassadors' }" class="router-link container-header-svg">
        <svg>
          <title>lululemon Logo</title>
          <use xlink:href="#icon-logo">
            <svg id="icon-logo" viewBox="0 0 35 35">
              <!-- eslint-disable max-len -->
              <path
                d="M17 .5c9.4 0 17 7.6 17 17s-7.6 17-17 17-17-7.6-17-17S7.6.5 17 .5zM28.9 25c-.7.4-1.9.9-3 .9-.5 0-1-.1-1.5-.5-2.8-1.8-1.8-4 0-7.4.8-1.4 1.7-2.9 2-4.5.4-1.6.8-4-.7-5.8-.6-.9-1.7-1.6-3-2-1.4-.5-3.2-.7-5.4-.7h-.6c-2.2 0-4 .2-5.4.7-1.3.4-2.4 1.1-3 2-1.5 1.8-1.1 4.2-.7 5.8.3 1.6 1.2 3.1 2 4.5 1.8 3.4 2.8 5.6 0 7.4-.5.4-1 .5-1.5.5-1.1 0-2.3-.5-3-.9l.2.3c1.3 2.1 3.1 3.2 5 3.2.9 0 1.8-.3 2.7-.7 1-.4 1.8-1.2 2.4-2.2.6-1 .8-2.1.7-3-.2-.9-.7-2.1-1.2-3.4-1.4-3.5-3.2-8.3-1.6-10.6.7-1 1.9-1.5 3.7-1.5 1.8 0 3 .5 3.7 1.5 1.6 2.3-.2 7.1-1.6 10.6-.5 1.3-1 2.5-1.2 3.4-.1.9.1 2 .7 3s1.4 1.8 2.4 2.2c.9.4 1.8.7 2.7.7 1.9 0 3.7-1.1 5-3.2l.2-.3z"
              />
            </svg>
          </use>
        </svg>
      </router-link>
    </div>
    <nav v-if="showNavButtons" class="nav">
      <nav-select-locale />
      <div v-if="showUserManagementPortal" class="lll-vr-grey" />
      <router-link
        v-if="showUserManagementPortal"
        :to="{ name: currentRouteName === 'ambassadors' ? 'users' : 'ambassadors' }"
        class="router-link btn btn-ghost btn-users text-no-wrap"
      >
        {{ currentRouteName === 'ambassadors' ? 'Manage Users' : 'Ambassadors' }}
      </router-link>
      <div class="lll-vr-grey" />
      <div>
        <button type="button" class="btn btn-ghost btn-sign-out text-no-wrap" @click="logout">
          {{ content.logoutButton }}
        </button>
      </div>
      <div v-if="showCreateButton" class="lll-vr-grey" />
      <router-link
        v-if="showCreateButton"
        :to="{ name: routeNameForCreateButton }"
        class="router-link btn-ghost btn-create"
      >
        <v-icon>add</v-icon>
      </router-link>
    </nav>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';
import maintenanceModeIsOn from '@/utils/checkMaintenanceMode';
import content from '@/content/staticContentForUI';
import NavSelectLocale from '@/components/NavSelectLocale';

export default {
  name: 'NavHeader',

  components: {
    'nav-select-locale': NavSelectLocale,
  },

  data() {
    return {
      content: content.navHeader,
    };
  },

  computed: {
    ...mapGetters(['userLoggedIn', 'userPermissions']),

    currentRouteName() {
      return this.$route.name;
    },

    isUserManagementRoute() {
      return ['users', 'userProfile'].includes(this.currentRouteName);
    },

    routeNameForCreateButton() {
      if (this.isUserManagementRoute) {
        return 'userProfileCreate';
      }

      return 'ambassadorProfileCreate';
    },

    showCreateButton() {
      // always show for Admin user
      if (this.userPermissions.canAssumeRoles) {
        return true;
      }

      // if not Admin user, don't show button when app is locked for maintenance
      if (maintenanceModeIsOn()) {
        return false;
      }

      if (this.isUserManagementRoute && this.userPermissions.canManagePermissions) {
        return true;
      }

      // if not a user management route, it's an ambassador route
      if (this.userPermissions.canCreateRecords) {
        return true;
      }

      return false;
    },

    showNavButtons() {
      if (!this.userLoggedIn) {
        return false;
      }

      if (
        ['ambassadorProfileCreate', 'updateStatus', 'login', 'userProfileCreate'].includes(
          this.currentRouteName
        )
      ) {
        return false;
      }

      return true;
    },

    showUserManagementPortal() {
      if (
        this.userPermissions.canManagePermissions &&
        ['ambassadors', 'users'].includes(this.currentRouteName)
      ) {
        return true;
      }

      return false;
    },
  },

  methods: {
    logout() {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/login').catch(error => {
          console.warn('vue-router error:', error);
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-create,
.btn-users,
.btn-sign-out {
  @media (max-width: 499px) {
    padding: 0px;
  }
}

.btn-create {
  @media (min-width: 500px) {
    padding: 6px 14px;
  }
}

.container-header-svg {
  height: 35px;
  width: 35px;
  display: inline-block;
  vertical-align: middle;
}

.nav-header-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 10px 5vw;

  @media (min-width: 500px) {
    flex-direction: row;
  }
}

.logo {
  margin-bottom: 10px;

  @media (min-width: 500px) {
    margin-bottom: 0;
  }

  svg {
    height: 100%;
    width: 100%;
    fill: $lll-color-red;
  }
}

.nav {
  display: inline-flex;
  align-items: center;
}
</style>
