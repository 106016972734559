import StoresBase from '@/models/v2/StoresBase';

class Store extends StoresBase {
  static _type = 'stores';

  static name = 'store';

  static baseURL = '/stores';

  constructor(args = {}) {
    super(args);
    this.type = 'stores';
    this.id = args.id;
    this.name = args.name;
  }

  async populate() {
    const url = `${this.constructBaseURL()}/${this.id}`;
    await this.get('GET', url);
  }
}

export default Store;
