function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  return parts.length === 2
    ? parts
        .pop()
        .split(';')
        .shift()
    : undefined;
}

function setCookie(name, value) {
  document.cookie = `${name}=${value};path=/;`;
}

export { getCookie, setCookie };
