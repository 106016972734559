/**
 * Glossary of current tags. These are used by helper functions to get field details, dynamically
 * add fields to components' data object, and update UI):
 *
 * PROFILE_CREATE_FORM
 *      - Field is included in form data (Object containing each attribute name and corresponding
 *        field value) that's sent with POST request when new profile is created.
 *
 * PROFILE_STATUS_FORM
 *      - Field is included in form data sent with PATCH request when Ambassador Status is updated.
 *
 * PERSONAL_DETAILS_FORM
 *      - Field is included in form data sent with PATCH request when 'Personal' section of the
 *        edit form is saved (found in the ProfileDetailsMain component).
 *
 * STATUS_AND_CONTRACT_FORM
 *     - Field is included in form data sent with PATCH request when 'Status and Contract' section of the
 *      edit form is saved (found in the ProfileDetailsMain component).
 *
 * PUBLIC_DETAILS_FORM
 *      - Field is included in form data sent with PATCH request when 'Public' section of the
 *        edit form is saved (found in the ProfileDetailsMain component).
 *
 * REQUIRED_TO_CREATE
 *      - Field is required to create a new profile.
 *
 * REQUIRED_TO_PUBLISH
 *      - Field is required to publish a profile. This tag helps calculate percent complete.
 *
 * BIO_FIELD
 *      - Field is either 'biography' or one of the fun facts (goals/questions).
 *
 * SOCIAL_HANDLE
 *      - Field is one of the social handle fields that requires its URL to be contstructed. The
 *        user enters their username/handle, and it's rendered as a link on the profile page.
 */

const BIO_FIELD = 'bio';
const STATUS_AND_CONTRACT_FORM = 'statusAndContractForm';
const PERSONAL_DETAILS_FORM = 'personalDetailsForm';
const PUBLIC_DETAILS_FORM = 'publicDetailsForm';
const PROFILE_CREATE_FORM = 'profileCreateForm';
const PROFILE_STATUS_FORM = 'profileUpdateStatusForm';
const REQUIRED_TO_CREATE = 'requiredToCreate';
const REQUIRED_TO_PUBLISH = 'requiredToPublish';
const SOCIAL_HANDLE = 'social';

export {
  BIO_FIELD,
  STATUS_AND_CONTRACT_FORM,
  PERSONAL_DETAILS_FORM,
  PUBLIC_DETAILS_FORM,
  PROFILE_CREATE_FORM,
  PROFILE_STATUS_FORM,
  REQUIRED_TO_CREATE,
  REQUIRED_TO_PUBLISH,
  SOCIAL_HANDLE,
};
