import axios from 'axios';
import Base from '@/models/v2/Base';
import get from '@/utils/config';
import { getAuthToken } from '@/vuex/modules/auth';
import { formatResponseError } from '@/utils/errorHandlers';
import { getCookie } from '@/utils/cookies';

class PermissionsBase extends Base {
  constructor(args) {
    super(args);
  }
}

PermissionsBase.adapter = axios.create({
  baseURL: `${get('VUE_APP_PERMISSIONS_HOST')}/v1`,
  timeout: 60000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

PermissionsBase.adapter.interceptors.request.use(config => {
  const token = getAuthToken();

  if (!token) {
    return config;
  }

  config.headers['Authorization'] = `Bearer ${token}`;

  const adminClaims = getCookie('adminClaims');
  const claims = getCookie('claims');

  if (adminClaims) {
    config.headers['X-Ambassadors-Assume-Claims'] = claims;
  }

  return config;
});

PermissionsBase.adapter.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    /**
     * Error is reformatted and passed to next 'catch'.
     * This allows front-end components to display more accurate error
     * messages and call a function if needed (e.g., notify user that
     * form submission failed due to expired session, and call
     * 'refreshAuthToken' when user interacts with error message).
     */
    throw formatResponseError(error);
  }
);

export default PermissionsBase;
