<template>
  <div
    class="form-field-container lll-form-field-bottom-margin"
    :class="[showFormInput ? 'editing' : '']"
  >
    <div v-if="enableEditButton" role="button" class="btn-edit-form-field" @click="startEditMode">
      <div :class="`tooltip-${fieldId}`" class="lll-tooltip-container">
        <v-tooltip :attach="`.tooltip-${fieldId}`" top open-delay="500" content-class="lll-tooltip">
          <div slot="activator" class="btn-edit-form-field" />
          <span>{{ content.tooltip }}</span>
        </v-tooltip>
      </div>
    </div>
    <slot v-if="customLabel" name="label"></slot>
    <amb-form-field-label
      v-else
      :attribute-id="attributeId"
      :custom-field-id="fieldId"
      :show-asterisk="showAsterisk"
    />
    <div v-if="showFormInput" :class="[section.editing ? '' : 'lll-disabled']">
      <v-autocomplete
        v-if="fieldType === 'autocomplete'"
        :id="fieldId"
        v-model="section.formFields[attributeId]"
        :rules="fieldDetails.validation"
        :validate-on-blur="!validateOnChange"
        :items="items"
        :item-text="itemText"
        :item-value="itemValue"
        :return-object="returnObject"
        solo
        flat
        :placeholder="getPropText('placeholder')"
        :hint="getPropText('hint')"
        persistent-hint
        menu-props="offset-y"
        prepend-inner-icon="search"
        :no-data-text="noDataText"
        browser-autocomplete="off"
        :disabled="!section.editing"
        :clearable="!fieldIsRequiredToPublish"
      />
      <v-select
        v-else-if="fieldType === 'select'"
        :id="fieldId"
        v-model="section.formFields[attributeId]"
        :rules="fieldDetails.validation"
        :validate-on-blur="!validateOnChange"
        :items="items"
        :item-text="itemText"
        :item-value="itemValue"
        :no-data-text="noDataText"
        :return-object="returnObject"
        solo
        flat
        :placeholder="getPropText('placeholder')"
        :hint="getPropText('hint')"
        persistent-hint
        menu-props="offset-y"
        :disabled="!section.editing"
        :clearable="!fieldIsRequiredToPublish"
      />
      <v-text-field
        v-else-if="fieldType === 'text'"
        :id="fieldId"
        v-model.trim="section.formFields[attributeId]"
        :rules="fieldDetails.validation"
        :validate-on-blur="!validateOnChange"
        solo
        flat
        :placeholder="getPropText('placeholder')"
        :hint="getPropText('hint')"
        persistent-hint
        browser-autocomplete="off"
        :disabled="!section.editing"
      />
      <v-textarea
        v-else-if="fieldType === 'textarea'"
        :id="fieldId"
        v-model.trim="section.formFields[attributeId]"
        :rules="fieldDetails.validation"
        :validate-on-blur="!validateOnChange"
        solo
        flat
        :placeholder="getPropText('placeholder')"
        :hint="getPropText('hint')"
        persistent-hint
        browser-autocomplete="off"
        auto-grow
        :rows="rows"
        :disabled="!section.editing"
      />
      <slot v-else name="input"></slot>
    </div>
    <div v-else class="lll-form-field-display-data">
      <div v-if="showDisplayData" :class="attributeId === 'email' ? 'break-word' : ''">
        {{ displayData }}
      </div>
      <slot v-else-if="customDisplayData" name="display-data"></slot>
      <amb-pending-label v-else />
    </div>
  </div>
</template>

<script>
/**
 * This component is only meant to be used as a wrapper for form fields within the parent
 * ProfileDetailsMain component. This allows us to maintain consistent markup for each form field
 * and add/change fields easily (e.g., if the tooltip label or styles change).
 *
 * Many of the props correspond to props expected by Vuetify input components (e.g., <v-select>):
 * https://v15.vuetifyjs.com/en/components/inputs
 */
import { mapGetters } from 'vuex';
import {
  fieldIsEmpty,
  fieldIsRequiredToPublish,
  getFieldDetails,
  convertSnakeToKebab,
} from '@/utils/formHelpers';
import AMBFormFieldLabel from '@/components/AMBFormFieldLabel';
import AMBPendingLabel from '@/components/AMBPendingLabel';
import content from '@/content/staticContentForUI';

export default {
  name: 'ProfileDetailsFormField',

  components: {
    'amb-form-field-label': AMBFormFieldLabel,
    'amb-pending-label': AMBPendingLabel,
  },

  props: {
    attributeId: {
      required: true,
      type: String,
      default: '',
    },
    customLabel: {
      type: Boolean,
      default: false,
    },
    customDisplayData: {
      type: Boolean,
      default: false,
    },
    displayData: {
      type: String,
      default: '',
    },
    editsDisabled: {
      type: Boolean,
      default: false,
    },
    fieldType: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemText: {
      type: String,
      default: 'label',
    },
    itemValue: {
      type: String,
      default: 'id',
    },
    noDataText: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    rows: {
      type: String,
      default: '2',
    },
    section: {
      required: true,
      type: Object,
      default: () => {},
    },
    showFormInput: {
      type: Boolean,
      default: false,
    },
    validateOnChange: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      content: content.profileDetailsFormField,
    };
  },

  computed: {
    ...mapGetters(['currentAmbassador', 'userPermissions']),

    enableEditButton() {
      if (!this.userPermissions.canEditRecords) {
        return false;
      }

      if (this.section.editsDisabled) {
        return false;
      }

      if (this.section.editing) {
        return false;
      }

      if (this.editsDisabled) {
        return false;
      }

      return true;
    },

    fieldDetails() {
      if (this.attributeId) {
        const details = getFieldDetails('attributeId', this.attributeId);

        return details[this.attributeId];
      }

      return {};
    },

    fieldId() {
      // Ensure input 'id' for ProfileDetails page is unique from duplicate fields
      // on ProfileCreate page (e.g., 'first-name', 'email', 'ambassador-type').
      return `update-${convertSnakeToKebab(this.attributeId)}`;
    },

    fieldIsRequiredToPublish() {
      return fieldIsRequiredToPublish(this.attributeId);
    },

    showAsterisk() {
      if (!this.userPermissions.canEditRecords) {
        return false;
      }

      const inputIsEmpty = this.showFormInput && !this.section.formFields[this.attributeId];
      const fieldValueMissing =
        inputIsEmpty || fieldIsEmpty(this.attributeId, this.currentAmbassador);

      return this.fieldIsRequiredToPublish && fieldValueMissing;
    },

    showDisplayData() {
      const emptyValues = [null, '', undefined, false];

      if (emptyValues.includes(this.displayData)) {
        return false;
      }

      return true;
    },
  },

  methods: {
    getPropText(propName) {
      return this[propName] ? this[propName] : this.fieldDetails.fieldText[propName];
    },

    startEditMode() {
      this.$emit('edit');
    },
  },
};
</script>

<style lang="scss" scoped>
.form-field-container {
  position: relative;

  &.editing {
    margin-bottom: 5px;

    &.lll-form-field-bottom-padding {
      padding-bottom: 25px;
    }
  }
}

.btn-edit-form-field {
  position: absolute;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

.lll-tooltip-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.lll-tooltip {
  top: 0 !important;
  left: 0 !important;
  border-radius: 0;
  font-size: $lll-font-size-small;
  padding: 8px 16px;
}
</style>
