import Vue from 'vue';
import VueScrollTo from 'vue-scrollto';
import VueSweetalert2 from 'vue-sweetalert2';
import Vuetify from 'vuetify';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import App from '@/App.vue';
import router from '@/router';
import store from '@/vuex';
import 'vuetify/dist/vuetify.min.css';
import 'vue-tel-input/dist/vue-tel-input.css';
import errorMessage from '@/mixins/errorMessage';
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);

Vue.use(Vuetify, {
  theme: {
    primary: '#000000', // lll-color-black
    secondary: '#686868', // lll-color-charcoal
    accent: '#9a9a9a', // lll-color-grey-dark
    error: '#d22030', // lll-color-red
    grey: '#d8d8d8', // lll-color-grey-light
  },
});

// https://github.com/rigor789/vue-scrollto
Vue.use(VueScrollTo, {
  container: 'body',
  duration: 500,
  easing: 'ease',
  offset: 0,
  force: true,
  cancelable: false,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true,
});

Vue.mixin(errorMessage);

Vue.config.productionTip = false;

Vue.config.errorHandler = (err, vm, info) => {
  console.log('in main.js global errorHandler...');
  console.log(`Error: ${err.toString()}\nInfo: ${info}`);
  console.log('vm: ', vm);
  throw err;
};

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');

if (process.env.VUE_APP_SENTRY_URL) {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_URL,
    environment: process.env.VUE_APP_SENTRY_ENV,
    integrations: [
      new Integrations.Vue({
        Vue,
        attachProps: true,
      }),
    ],
  });
}

if (process.env.VUE_APP_PR_VERSION) {
  console.log(`Current PR env: ${process.env.VUE_APP_PR_VERSION}`);
}
