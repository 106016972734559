import { customMessages } from '@/utils/errorHandlers';
import content from '@/content/staticContentForUI';

const errorText = customMessages.profileFormFields;
const fieldText = content.formFields;

/**
 * Shared validation rules. Functions should follow this syntax in order for rules to apply to
 * Vuetify form fields with 'rules' prop. Per Vuetify docs, 'rules' prop should be an Array
 * containing functions. Each function accepts a single param, which is the value of the input
 * (v-model), and returns either true (Boolean) or a string containing the error message.
 */
const VALIDATION_RULES = {
  required: value => !!value || errorText.required,
  email: value => {
    if (!value) {
      return true;
    }
    return (
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value
      ) || errorText.invalidEmail
    );
  },
  email_lll: value => {
    if (!value) {
      return true;
    }
    return (
      /^[a-zA-Z0-9_.+-]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)?(lululemon)\.com$/.test(value) ||
      errorText.invalidLululemonEmail
    );
  },
  gift_card_number: value => /^[\d]{20}$|^$/.test(value) || errorText.giftCard,
  integer_only: value => {
    if (!value) {
      return true;
    }

    return /^[0-9]*$/.test(value) || errorText.integerOnly;
  },
  instagram: value => /^[A-Za-z\d._-]{1,30}$|^$/.test(value) || errorText.socialHandle,
  facebook: value => /^[A-Za-z\d.]{5,}$|^$/.test(value) || errorText.socialHandle,
  twitter: value => /^[A-Za-z\d_]{1,15}$|^$/.test(value) || errorText.socialHandle,
  youtube: value => /^[A-Za-z\d]{1,30}$|^$/.test(value) || errorText.socialHandle,
  strava: value => /^[A-Za-z\d]{1,30}$|^$/.test(value) || errorText.socialHandle,
  website: value => {
    if (!value) {
      return true;
    }

    try {
      // eslint-disable-next-line no-useless-escape
      const validUrlFormat = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/.test(
        value
      );

      const url = new URL(value);

      if (url.protocol !== 'https:') {
        throw new Error(errorText.website.protocolInvalid);
      }
      if (!url.host) {
        throw new Error(errorText.website.hostMissing);
      }
      if (!url.pathname) {
        throw new Error(errorText.website.pathMissing);
      }
      if (!validUrlFormat) {
        throw new Error('');
      }
    } catch (e) {
      if (e.name !== 'TypeError') {
        return `${e.message} ${errorText.website.invalid}`;
      }
      return errorText.website.invalid;
    }

    return true;
  },
  birth_date: value => {
    if (!value) {
      return true;
    }

    const selectedDate = new Date(value);
    const ageGate = new Date();

    ageGate.setFullYear(ageGate.getFullYear() - 18);
    ageGate.setHours(0);
    ageGate.setMinutes(0);
    ageGate.setSeconds(0);

    if (selectedDate > ageGate) {
      return errorText.birthdate;
    }
    return true;
  },
  biography: value => {
    return validateWordCount(value, fieldText.biography.wordLimit);
  },
  fact_1: value => {
    return validateWordCount(value, fieldText.fact_1.wordLimit);
  },
  fact_2: value => {
    return validateWordCount(value, fieldText.fact_2.wordLimit);
  },
  fact_3: value => {
    return validateWordCount(value, fieldText.fact_3.wordLimit);
  },
  fact_4: value => {
    return validateWordCount(value, fieldText.fact_4.wordLimit);
  },
  fact_5: value => {
    return validateWordCount(value, fieldText.fact_5.wordLimit);
  },
};

/**
 * Counts number of words in a string. This is mainly used for verifying the word count for
 * bio fields (biography, fact_1, etc.). The end of a "word" is detected by checking for a
 * space after a character. The content of each word is not validated. For example, the string
 * "a b c d e" would be counted as 5 words, even though they're not "real" words.
 *
 * @param {String} string - String to be counted.
 * @return {Number} - Total number of words.
 */
function countWordsInString(string = '') {
  if (!string) {
    return 0;
  }

  return string.split(/\s+/).filter(n => {
    return n !== '';
  }).length;
}

/**
 * Checks whether the word count of a string exceeds a given limit. This is mainly used in
 * VALIDATION_RULES to verify the word count for textarea fields that allow "free text" input
 * (biography, fact_1, etc.).
 *
 * @param {String} string - String to be validated.
 * @param {String or Number} limit - Word limit.
 * @return {Boolean or String} - If valid, returns true. If invalid, returns string with
 * error message.
 */
function validateWordCount(string = '', limit) {
  const { part1, part2, part3 } = errorText.wordLimitExceeded;

  const stringLength = countWordsInString(string);
  if (stringLength <= limit) {
    return true;
  }

  return `${part1} ${stringLength} ${part2} ${limit} ${part3}`;
}

export { countWordsInString, validateWordCount, VALIDATION_RULES };
