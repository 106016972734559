import Vue from 'vue';
import VueRouter from 'vue-router';
import AmbassadorsList from '@/components/views/AmbassadorsList.vue';
import ErrorPage from '@/components/views/ErrorPage.vue';
import MaintenancePage from '@/components/views/MaintenancePage.vue';
import LoginForm from '@/components/views/LoginForm.vue';
import ProfileCreate from '@/components/views/ProfileCreate.vue';
import ProfileUpdateStatus from '@/components/views/ProfileUpdateStatus.vue';
import ProfileDetails from '@/components/views/ProfileDetails.vue';
import UsersList from '@/components/views/UsersList.vue';
import UserProfile from '@/components/views/UserProfile.vue';
import UserProfileCreate from '@/components/views/UserProfileCreate.vue';
import content from '@/content/staticContentForUI';
import { getAuthToken, getStoredClaims, userIsAdmin } from '@/vuex/modules/auth';
import maintenanceModeIsOn from '@/utils/checkMaintenanceMode';

// Use these localized labels for breadcrumb names.
const {
  ambassadorsList,
  profileCreate,
  profileDetails,
  profileUpdateStatus,
  usersList,
  userProfile,
  userProfileCreate,
} = content.navBreadcrumbs;

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: () => {
    return { x: 0, y: 0 }; // Always scroll to top of page after transitions.
  },
  routes: [
    {
      path: '/login',
      name: 'login',
      component: LoginForm,
      meta: {
        public: true,
        onlyWhenLoggedOut: true,
      },
    },
    {
      path: '/error',
      name: 'error',
      component: ErrorPage,
    },
    {
      path: '/maintenance',
      name: 'maintenance',
      component: MaintenancePage,
    },
    {
      path: '/ambassadors',
      name: 'ambassadors',
      component: AmbassadorsList,
      meta: {
        lockDuringMaintenance: true,
        version: 'v2',
        requiresAuth: true,
        requiredClaim: 'ambassador_read',
      },
      beforeEnter: (to, from, next) => {
        checkRequiredClaim(to, from, next);
      },
    },
    {
      path: '/ambassadors/profile/create',
      name: 'ambassadorProfileCreate',
      component: ProfileCreate,
      meta: {
        lockDuringMaintenance: true,
        version: 'v2',
        requiresAuth: true,
        requiredClaim: 'ambassador_create',
        breadcrumb: [{ name: ambassadorsList, link: 'ambassadors' }, { name: profileCreate }],
      },
      beforeEnter: (to, from, next) => {
        checkRequiredClaim(to, from, next);
      },
    },
    {
      path: '/ambassadors/profile/:id',
      name: 'ambassadorProfile',
      component: ProfileDetails,
      props: true,
      meta: {
        lockDuringMaintenance: true,
        version: 'v2',
        requiresAuth: true,
        requiredClaim: 'ambassador_read',
        breadcrumb: [{ name: ambassadorsList, link: 'ambassadors' }, { name: profileDetails }],
      },
      beforeEnter: (to, from, next) => {
        checkRequiredClaim(to, from, next);
      },
    },
    {
      path: '/ambassadors/profile/:id/update-status',
      name: 'updateStatus',
      component: ProfileUpdateStatus,
      props: true,
      meta: {
        lockDuringMaintenance: true,
        version: 'v2',
        requiresAuth: true,
        requiredClaim: 'ambassador_update_status',
        breadcrumb: [
          { name: ambassadorsList, link: 'ambassadors' },
          { name: profileDetails, link: 'ambassadorProfile' },
          { name: profileUpdateStatus },
        ],
      },
      beforeEnter: (to, from, next) => {
        checkRequiredClaim(to, from, next);
      },
    },
    {
      path: '/users',
      name: 'users',
      component: UsersList,
      meta: {
        lockDuringMaintenance: true,
        requiresAuth: true,
        requiredClaim: 'manage_permissions',
        breadcrumb: [{ name: ambassadorsList, link: 'ambassadors' }, { name: usersList }],
      },
      beforeEnter: (to, from, next) => {
        checkRequiredClaim(to, from, next);
      },
    },
    {
      path: '/users/profile/create',
      name: 'userProfileCreate',
      component: UserProfileCreate,
      meta: {
        lockDuringMaintenance: true,
        requiresAuth: true,
        requiredClaim: 'manage_permissions',
        breadcrumb: [
          { name: ambassadorsList, link: 'ambassadors' },
          { name: usersList, link: 'users' },
          { name: userProfileCreate },
        ],
      },
      beforeEnter: (to, from, next) => {
        checkRequiredClaim(to, from, next);
      },
    },
    {
      path: '/users/profile/:id',
      name: 'userProfile',
      component: UserProfile,
      props: true,
      meta: {
        lockDuringMaintenance: true,
        requiresAuth: true,
        requiredClaim: 'manage_permissions',
        breadcrumb: [
          { name: ambassadorsList, link: 'ambassadors' },
          { name: usersList, link: 'users' },
          { name: userProfile },
        ],
      },
      beforeEnter: (to, from, next) => {
        checkRequiredClaim(to, from, next);
      },
    },
    // otherwise redirect to home (ambassadors list view)
    { path: '*', redirect: '/ambassadors' },
  ],
});

export default router;

/**
 * Redirect to error page if user doesn't have the required claim.
 */
function checkRequiredClaim(to, from, next) {
  const userClaims = getStoredClaims();

  if (!userClaims[to.meta.requiredClaim]) {
    next(router.replace({ path: '/error', query: to.query }));
  } else {
    next();
  }
}

/**
 * Add query params to the next route, so user sees content from the same PR environment for all pages.
 * This `beforeResolve` is called after all in-component guards and `beforeEach` guards are resolved.
 */
router.beforeResolve((to, from, next) => {
  if (from.query.pr && !to.query.pr) {
    const newQuery = {
      ...to.query,
      ...from.query,
    };

    next({ path: to.path, query: newQuery });
  } else {
    next();
  }
});

/**
 * Redirect to maintence page if app is locked for maintenance and user doesn't have admin claim.
 */
router.beforeEach((to, from, next) => {
  const lockedDuringMaintenance = to.matched.some(record => record.meta.lockDuringMaintenance);
  const appInMaintenanceMode = maintenanceModeIsOn();
  const userHasAdminClaim = userIsAdmin();

  if (lockedDuringMaintenance && appInMaintenanceMode && !userHasAdminClaim) {
    next({
      path: '/maintenance',
      query: to.query,
    });
  } else if (to.name === 'maintenance' && !appInMaintenanceMode) {
    // Prevent user from staying on maintenance page if maintenance mode is off.
    next({
      path: '/ambassadors',
      query: to.query,
    });
  } else {
    next();
  }
});

/**
 * Redirect to Login page if user is not logged in and trying to access a restricted page.
 * Redirect to 'home' page if user is logged in and trying to access Login page.
 */
router.beforeEach((to, from, next) => {
  const isPublic = to.matched.some(record => record.meta.public);
  const onlyWhenLoggedOut = to.matched.some(record => record.meta.onlyWhenLoggedOut);
  const userLoggedIn = getAuthToken();

  if (!isPublic && !userLoggedIn) {
    next({
      path: '/login',
      query: to.query,
    });
  } else if (onlyWhenLoggedOut && userLoggedIn) {
    next({
      path: '/ambassadors',
      query: to.query,
    });
  } else {
    next();
  }
});

/**
 * Use this to handle version control. If route is behind a feature flag (activeFeature is
 * toggled from AdminPanel), this nav guard ensures user is directed to the correct route.
 * This is needed because, as new versioned routes are added, developers may not have updated
 * every '$router' method to use the correct route.
 */
// router.beforeEach((to, from, next) => {
//   const routeHasVersion = to.matched.some(record => record.meta.version);
//   const routeVersion = to.meta.version;
//   const currentVersion = localStorage.getItem('activeFeature') || 'v2';

//   if (routeHasVersion && routeVersion !== currentVersion) {
//     // construct new path with correct version appended
//     const routeItems = to.path.split('/');
//     const lastItemIndex = routeItems.indexOf(routeItems[routeItems.length - 1]);

//     if (lastItemIndex !== -1) {
//       routeItems[lastItemIndex] = currentVersion;
//     }

//     next({
//       path: routeItems.join('/'),
//     });
//   } else {
//     next();
//   }
// });
