import PermissionsBase from '@/models/PermissionsBase';

class Claim extends PermissionsBase {
  static _type = 'claims';

  static name = 'claim';

  static baseURL = '/claims';

  constructor(args = {}) {
    super(args);

    this.id = args.id;
    this.name = args.name;
    this.description = args.description;
  }
}

export default Claim;
